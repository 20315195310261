import React from 'react';
import { AdviceAlertProps } from './types';

import './AdviceAlert.scss';
import { IonContent, IonAlert } from '@ionic/react';

const AdviceAlert: React.FC<AdviceAlertProps> = ({
  isOpen,
  setOpen,
  header,
  subHeader,
  message,
  action,
  onDidDismiss,
}) => {
  return (
    <IonContent>
      <IonAlert
        mode='ios'
        isOpen={isOpen}
        onDidDismiss={() => {
          setOpen(false);
          if (onDidDismiss) {
            onDidDismiss();
          }
        }}
        cssClass="advice-alert"
        header={header}
        subHeader={subHeader}
        message={message}
        buttons={[
          {
            text: 'Continuar',
            handler: () => {
              action();
            },
          },
        ]}
      />
    </IonContent>
  );
};

export default AdviceAlert;
