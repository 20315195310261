import { useState } from 'react';

export const useAddPetForm = () => {
  const [current, setCurrent] = useState<number>(0);
  const nextForm = () => setCurrent(1);
  const prevForm = () => {
    setCurrent(0);
  };
  return {
    prevForm,
    nextForm,
    current,
    setCurrent,
  };
};
