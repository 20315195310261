import { AxiosResponse } from 'axios';
import axiosInstance from '.';
import { PERSONAL_DATA } from './constants';
import { PersonalAndAddressDataReq, PersonalDataReq } from '../interfaces/PersonalData';

export const getPersonalData = async () => {
  try {
    const rta = await axiosInstance.get(PERSONAL_DATA);
    return rta.data;
  } catch (e) {
    if (e?.response?.status === 404) {
      return null;
    } else {
      console.error(e);
      throw e;
    }
  }
};

export const getUser = async (url?: string) => {
  //type deberia ser PersonalAndAddressDataReq
  try {
    const { data: user }: AxiosResponse<any> = await axiosInstance.get(
      url || PERSONAL_DATA
    );
    return user;
  } catch (e) {
    if (e?.response?.status === 404) {
      return null;
    } else {
      console.error(e);
      throw e;
    }
  }
};
