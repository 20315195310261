import React, { useState } from 'react';
import { PasswordRecoverFormProps, PrInputs } from './types';
import {
  IonItem,
  IonInput,
  IonButton,
  IonAlert,
  IonSpinner,
} from '@ionic/react';
import { useForm } from 'react-hook-form';

import { useEnter } from '../../hooks/useEnter';

import './PasswordRecoverForm.scss';
import FormItem from '../FormItem';
import { useAuth } from '../../hooks';

const PasswordRecoverForm: React.FC<PasswordRecoverFormProps> = () => {
  const { recoverPassword } = useAuth();
  const { ref, handleEnter } = useEnter();

  const [loading, setLoading] = useState(false);
  const [isOk, setOk] = useState(false);
  const [showAlert, setAlert] = useState(false);

  const { control, handleSubmit, errors, formState } = useForm<PrInputs>();

  const onSubmit = async ({ email }: PrInputs) => {
    setLoading(true);
    const ok = await recoverPassword(email);
    if (ok) {
      setOk(true);
      setAlert(true);
    } else {
      setAlert(true);
    }
    setLoading(false);
  };

  const errorAlert = (
    <IonAlert
      mode='ios'
      isOpen={showAlert}
      onDidDismiss={() => setAlert(false)}
      header={'Error'}
      message={'Ha ocurrido un error. Intente nuevamente mas tarde.'}
      buttons={['Ok']}
    />
  );

  const succesAlert = (
    <IonAlert
      mode='ios'
      isOpen={showAlert}
      onDidDismiss={() => setAlert(false)}
      header={'¡ Éxito !'}
      message={'En breve recibiras un mail para confirmar la nueva contraseña'}
      buttons={['Ok']}
    />
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="password-recover-form"
      onKeyUp={(e) => (formState.isDirty ? handleEnter(e) : undefined)}
    >
      <FormItem
        errors={errors}
        render={({ onChange }) => (
          <IonItem>
            <IonInput type="email" placeholder="Email" onIonChange={onChange} />
          </IonItem>
        )}
        control={control}
        name="email"
        rules={{
          required: {
            value: true,
            message: 'Por favor, ingrese su email',
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Por favor, ingrese un email válido',
          },
        }}
      />

      <div className='ion-margin-top'>
        <IonButton
          ref={ref}
          disabled={!formState.isDirty || loading}
          className="password-recover-form__button"
          type="submit"
          mode='ios'
        >
          {loading && (
            <IonSpinner
              name="crescent"
              className="form-login__loading-spinner"
              slot="end"
            />
          )}
          Enviar email
        </IonButton>
      </div>


      {isOk ? succesAlert : errorAlert}
    </form>
  );
};

export default PasswordRecoverForm;
