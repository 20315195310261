import React from 'react';
import {
  IonButton,
  IonContent,
  IonPage,
  IonText,
} from '@ionic/react';

import { isDesktop } from '../../hooks/usePlatform';

import './VetCallPage.scss';
import NavigationBar from '../../components/NavigationBar';
import Phone from "../../assets/phone-call.png"

const VetCallPage: React.FC = () => {

  return (
    <IonPage>
      <NavigationBar path='home' />
      <IonContent className='vet-call-page ion-padding'>
        <div className="vet-call-page__content">
          <div className='ion-padding-bottom'>
            <img src={Phone} />
          </div>
          <div className="vet-call-page__text">
            <p>
              La cobertura de FielPet incluye un servicio de{" "}
              <IonText color='primary'><b>consulta por videollamada con un veterinario.</b></IonText> Para utilizarlo,
              solicitá un <b>turno</b> llamando al <span className="vet-call-page__phone-number">0800-888-7994</span>.
              {/* {isDesktop ? <span className="vet-call-page__phone-number">0800-888-7994</span> : null}. */}
            </p>
            <p>
              Tras comunicarte con el operador, podrás ver más detalles en el
              evento de <b>videollamada</b> que aparecerá en tu <b>historial</b>.
            </p>
          </div>
          <div className='ion-padding-top'>
            {isDesktop ? null : (
              <IonButton expand="block" href={'tel:' + "08008887994"} mode='ios'>
                Solicitar turno
              </IonButton>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default VetCallPage;
