import { AxiosResponse } from 'axios';
import axiosInstance from '.';

export const getProvincia = async (url: string, localidadId: string) => {
  try {
    const provinciaResponse: AxiosResponse<any> = await axiosInstance.get(
      `${url}?id=${localidadId}`
    );
    return provinciaResponse.data;
  } catch (error) {
    console.error('Error fetching Provincia:', error);
    throw error; // Re-lanzar el error para que pueda ser manejado por el llamador si es necesario
  }
};
