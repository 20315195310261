import axiosInstance from '.';
import { AxiosResponse } from 'axios';

import {
  FoodBagRes,
  FoodReq,
  FoodRes,
  RemovePetFood,
  UpdateFoodRes,
} from '../interfaces/Food';
import { ADD_FOOD, GET_A_FOOD, GET_FOOD_BAGS, GET_FOOD_PET, REMOVE_FOOD } from './constants';
import { StatusRes } from '../interfaces/register';

export const addFood = async (
  newFood: FoodReq
): Promise<{ status: StatusRes }> => {
  try {
    const res = await axiosInstance.post(ADD_FOOD, newFood);
    return { status: { status: res.status } };
  } catch (error) {
    console.error(error);
    return { status: { status: error.response.status } };
  }
};

export const updateFood = async (food: UpdateFoodRes) => {
  try {
    await axiosInstance.put(ADD_FOOD, food);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const removePetFood = async (food: RemovePetFood) => {
  try {
    await axiosInstance.put(
      `${REMOVE_FOOD}`,
      food
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getFood = async (id: number) => {
  try {
    const { data } = await axiosInstance.get<FoodRes>(GET_A_FOOD + '/' + id);
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getFoodPets = async () => {
  try {
    const { data }: AxiosResponse<FoodRes[]> = await axiosInstance.get(
      ADD_FOOD
    );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getFoodBags = async () => {
  try {
    const {
      data: foodBags,
    }: AxiosResponse<FoodBagRes[]> = await axiosInstance.get(GET_FOOD_BAGS);
    return foodBags;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const deleteFoodBag = async (id: number) => {
  try {
    await axiosInstance.delete(GET_A_FOOD + '/' + id);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getFoodOfPet = async (id: string | undefined) => {
  if (id) {
    try {
      const {
        data: foodBagsOfPet,
      }: AxiosResponse<FoodRes[]> = await axiosInstance.get(
        GET_FOOD_PET + '/' + id
      );
      return foodBagsOfPet;
    } catch (e) {
      console.error(e);
      throw e;
    }
  } else {
    return [];
  }
};
