import { SearchbarOption } from "../components/Searchbar/types";

export const getProvinces = () => {
  return [
    'Capital Federal',
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Cordoba',
    'Corrientes',
    'Entre Rios',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquen',
    'Rio Negro',
    'Salta',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'San Luis',
    'San Juan',
    'Tierra del Fuego',
    'Tucuman',
  ];
};

export const provinces: SearchbarOption[] = getProvinces().map((province) => ({
  value: province,
  label: province,
}));
