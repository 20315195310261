import moment from 'moment';

export type SiniestroAddFormType = {
  fechaEnfermedad?: string;
  denunciaAdministrativa?: string;
  mascotaId? : string;
};

export type SiniestroAddFormToSendType = {
  fechaEnfermedad?: string;
  denunciaAdministrativa?: string;
  mascotaId? : string;
};

const emptySiniestroForm: SiniestroAddFormType = {
  fechaEnfermedad: moment().toISOString(),
  denunciaAdministrativa: undefined,
  mascotaId : undefined,
};

export function getEmptySiniestroForm():SiniestroAddFormType{
    return JSON.parse(JSON.stringify(emptySiniestroForm));
}
