import moment from 'moment';

import { addWeight as axiosAddWeight } from '../axios/addWeight';

export const useWeights = () => {
  const addWeightEntry = async (
    id: string,
    weight: number,
    weightDate: string
  ) => {
    await axiosAddWeight({
      mascotaId: id,
      peso: weight,
      fecha_pesaje: weightDate + moment().toISOString().slice(10),
    });
  };

  return { addWeightEntry };
};
