import React from 'react';
import SvfSummary from '../components/SvfSummary';
import { CirugiaMascota } from '../interfaces/CirugiaMascota';
import { EstudioMascota } from '../interfaces/EstudioMascota';
import { InternacionMascota } from '../interfaces/InternacionMascota';
import { PracticaMascota } from '../interfaces/PracticaMascota';
import { SubVetForm } from '../interfaces/SubVetForm';

export const useDatosConsulta = (indicaciones: SubVetForm[]) => {

  function generateSummary() {
    return indicaciones.map((indicacion)=>{
      switch (indicacion.tipo) {
        case 'practicaMascota':
          const {
            tipoPractica,
            descripcionPractica,
          } = (indicacion as unknown) as PracticaMascota;

          const practica = [
            { Clave: 'Tipo', Valor: tipoPractica },
            { Clave: 'Detalle:', Valor: descripcionPractica },
          ];

          return <SvfSummary key={`${indicacion.tipo}${indicacion.id}`} type="Practicas realizadas:" SVF={practica} />;

        case 'estudioMascota':
          const {
            tipoEstudio,
            descripcionEstudio,
          } = (indicacion as unknown) as EstudioMascota;

          const estudio = [
            { Clave: 'Tipo', Valor: tipoEstudio },
            { Clave: 'Detalle', Valor: descripcionEstudio },
          ];

          return (
            <SvfSummary
              key={`${indicacion.tipo}${indicacion.id}`}
              type="Estudios realizados:"
              SVF={estudio}
              attach={true}
            />
          );

        case 'cirugiaMascota':
          const {
            operacionRealizada,
            descripcionPractica: descripcionCirugia,
          } = (indicacion as unknown) as CirugiaMascota;

          const cirugia = [
            { Clave: 'Tipo', Valor: operacionRealizada },
            { Clave: 'Detalle:', Valor: descripcionCirugia },
          ];

          return <SvfSummary key={`${indicacion.tipo}${indicacion.id}`} type="Cirugías realizadas" SVF={cirugia} />;

        case 'internacionMascota':
          const {
            terapiasRealizadas,
          } = (indicacion as unknown) as InternacionMascota;

          const internacion = [
            { Clave: 'Terapías realizadas:', Valor: terapiasRealizadas },
          ];

          return <SvfSummary key={`${indicacion.tipo}${indicacion.id}`} type="Internación" SVF={internacion} />;

        default:
          console.log('default');
      }});
  }

  return { generateSummary };
};
