import { useState } from 'react';

export const usePetFilterState = () => {

  const [selectedId,setSelectedId] = useState<number | null>(null);

  const updateIdList = (id: number) => {
    if (selectedId === id) {
      setSelectedId(null);
    } else {
      setSelectedId(id);
    }
  };
  const isSelected = (id: number) => {
    return selectedId === id;
  };

  return { selectedId, updateIdList, isSelected };
};