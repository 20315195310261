import React from 'react';
import { CodVendAlertProps } from './types';
import './CodVendAlert.scss';
import { IonContent, IonAlert } from '@ionic/react';

const CodVendAlert: React.FC<CodVendAlertProps> = ({
  isOpen,
  setOpen,
  send,
}) => {
  return (
    <IonContent>
      <IonAlert
        mode='ios'
        isOpen={isOpen}
        onDidDismiss={() => {
          setOpen(false);
        }}
        header="Código"
        message="Ingrese el código de beneficio (opcional)"
        inputs={[
          {
            name: 'codVend',
            type: 'text',
            label: 'Código',
            placeholder: 'Código',
          },
        ]}
        buttons={[
          {
            text: 'Enviar código',
            handler: ({ codVend }) => {
              send(codVend);
            },
          },
          {
            text: 'No tengo uno',
            handler: ({ codVend }) => {
              send(codVend);
            },
          },
        ]}
      />
    </IonContent>
  );
};

export default CodVendAlert;
