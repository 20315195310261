import React from 'react';
import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import './Pet.scss';
import { PetProps } from './types';
import { useMascotas } from '../../context/MascotasContext';
import { useIonRouter } from '@ionic/react';

const Pet: React.FC<PetProps> = ({ pet }) => {
  const { push } = useIonRouter();
  const { mascotas: { pets } } = useMascotas();
  const { animal, img_link, nombre, id } = pet;

  const placeholder = animal === 'Perro' ? DogPlaceholder : CatPlaceholder;

  const image = img_link ? (
    <img src={img_link} alt={nombre} />
  ) : (
    <img src={placeholder} alt={nombre} />
  );

  return (
    <div className="pet">
      {pet && (
        <>
          <div
            className="pet__avatar"
            onClick={() => push(`/tabs/pet-details/${id}`)}
          >
            {image}
          </div>
          <div className={pets && pets.length >= 3 ? "pet__name ion-margin-bottom ion-padding-horizontal pet__aux-margin" : "pet__name ion-margin-bottom ion-padding-horizontal"}>{nombre}</div>
        </>
      )}
    </div>
  );
};

export default Pet;
