import React from 'react';
import { IonPage } from '@ionic/react';

import NotFound from '../../components/NotFound';

import './NotFoundPage.scss';

const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <NotFound></NotFound>
    </IonPage>
  );
};

export default NotFoundPage;
