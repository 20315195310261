import axiosInstance from '.';
import { GET_EVENTS } from './constants';
import { AxiosResponse } from 'axios';
import { Eventos } from '../interfaces/Evento';

export const getEvents = async () => {
  try {
    const { data: eventos }: AxiosResponse<Eventos> = await axiosInstance.get(
      GET_EVENTS
    );
    return eventos;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
