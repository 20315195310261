import React from 'react';

import { IonSpinner } from '@ionic/react';

import { useGetVeterinariosRecomendados } from '../../hooks/useVeterinarios';
import Veterinario from '../Veterinario';
import './Veterinarios.scss';
import { VeterinariosProps } from './types';

const Veterinarios: React.FC<VeterinariosProps> = ({
  indicacion,
  indicacionesSeleccionadas,
  setIndicacionesSeleccionadas,
  setOkAlertOpen,
  setFailAlertOpen,
  checked
}) => {
  const { veterinariosRecomendados } = useGetVeterinariosRecomendados(
    indicacion.id,
    indicacion.tipo
  );

  // if (isValidating)
  //   return (
  //     <div className="veterinarios__loading-state">
  //       <IonSpinner color="light" />
  //     </div>
  //   );
  // else if (error) {
  //   return <div>veterinarios error</div>;
  // }

  return (
    <div className="veterinarios">
      <div className="veterinarios__title ion-margin-bottom">
        Veterinarios recomendados en la consulta:
      </div>
      {veterinariosRecomendados ? (
        veterinariosRecomendados.map((veterinario, index) => (
          <Veterinario
            indicacion={indicacion}
            key={index}
            veterinario={veterinario}
            indicacionesSeleccionadas={indicacionesSeleccionadas}
            setIndicacionesSeleccionadas={setIndicacionesSeleccionadas}
            setOkAlertOpen={setOkAlertOpen}
            setFailAlertOpen={setFailAlertOpen}
            checked={checked}
          />
        ))
      ) : (
        <div className="veterinarios__loading-state">
          <IonSpinner color="light" />
        </div>
      )}
    </div>
  );
};

export default Veterinarios;
