import { AxiosResponse } from 'axios';

import axiosInstance from '.';
import { Raza } from '../interfaces/Raza';
import { GET_RAZAS } from './constants';

export const getRazas = async () => {
  try {
    const { data: razas }: AxiosResponse<Raza[]> = await axiosInstance.get(
      GET_RAZAS
    );
    return razas;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
