import React from 'react';

import { Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import AddPetButton from '../AddPetButton';
import Pet from '../Pet';
import './Pets.scss';
import { PetsProps } from './types';

const Pets: React.FC<PetsProps> = ({ pets }) => {
  return (
    <div className="pets ion-padding-horizontal">
      <Swiper
        modules={[Pagination, Scrollbar, A11y]}
        slidesPerView={3} spaceBetween={15} pagination={{ clickable: true }}>
        <SwiperSlide>
          <AddPetButton />
        </SwiperSlide>
        {pets &&
          pets.map((pet, index) => (
            <SwiperSlide key={index}>
              <Pet pet={pet} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default Pets;
