import 'moment/locale/es';

import CELO from '../assets/timeline-icons/evento_celo_circle.min.svg';
import CONSULTA from '../assets/timeline-icons/evento_consulta_circle.min.svg';
import CRIA from '../assets/timeline-icons/evento_cria_circle.min.svg';
import CUMPLEANIOS from '../assets/timeline-icons/evento_cumpleanios_circle.min.svg';
import VACUNA from '../assets/timeline-icons/evento_vacuna_circle.min.svg';
import DESPINTERNA from '../assets/timeline-icons/evento_despinterna_circle.min.svg';
import DESPEXTERNA from '../assets/timeline-icons/evento_despexterna_circle.min.svg';
import VIDEOLLAMADA from '../assets/timeline-icons/evento_videollamada_circle.min.svg';

import IMAGEDOG from '../assets/timeline-icons/Perroicon.svg';
import IMAGECAT from '../assets/timeline-icons/Gatoicon.svg';
import { Animal } from '../interfaces/Pet';

export const getEventIcon = (ev: string) => {
  switch (ev) {
    case 'Vacuna':
      return VACUNA;
    case 'General':
      return CONSULTA;
    case 'DespExterna':
      return DESPEXTERNA;
    case 'DespInterna':
      return DESPINTERNA;
    case 'Celo':
      return CELO;
    case 'Cria':
      return CRIA;
    case 'Cumpleaños':
      return CUMPLEANIOS;
    case 'Videollamada':
      return VIDEOLLAMADA;
    default:
      return CONSULTA;
  }
};

export const getClassColor = (id: number) => {
  const num = (id * 10) % 12;
  return 'color' + num.toString();
};

export const getAnimalImage = (animal: Animal) => {
  return animal === 'Perro' ? IMAGEDOG : IMAGECAT;
};
