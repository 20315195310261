import React from 'react';

import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import * as icons from 'ionicons/icons';
import moment from 'moment';

import './Movimiento.scss';
import { MovimientoProps } from './types';

const Movimiento: React.FC<MovimientoProps> = ({ movimiento }) => {
  const { fecha_movimiento, razon, icono, color } = movimiento;
  const date = moment(fecha_movimiento).format('DD/MM/YYYY');

  function processString(input : string) {
    const forbiddenWords = ['PENDIENTE: ', 'APROBADO: ', 'COMPLETADO: ']; // Agrega las palabras prohibidas aquí
  
    const wordsToRemove = forbiddenWords.filter(word => input.includes(word));
    let processedInput = input;
  
    wordsToRemove.forEach(word => {
      processedInput = processedInput.replace(new RegExp(`\\b${word}\\b`, 'ig'), '');
    });
  
    const formattedString = formatString(processedInput);
    return formattedString;
  }
  
  function formatString(input : string) {
    const lowerCaseInput = input.toLowerCase();
    const formattedString = lowerCaseInput.charAt(0).toUpperCase() + lowerCaseInput.slice(1);
    return formattedString;
  }

  return (
    <div className="movimiento">
      <IonGrid className='ion-no-padding ion-margin-bottom'>
        <IonRow>
          <IonCol size="3" className={'movimiento__icon-box ' + `${color}`}>
            <IonIcon icon={icons[icono as keyof typeof icons]} color="light" />
          </IonCol>
          <IonCol className="movimiento__title-box">
            <div className="movimiento__title">
              {processString(razon)}
            </div>
          </IonCol>
        </IonRow>
        <div className="movimiento__date">{date}</div>
      </IonGrid>
    </div>
  );
};

export default Movimiento;
