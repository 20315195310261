import React from 'react';
import { useForm } from 'react-hook-form';

import {
  IonPage,
  IonContent,
  IonInput,
  IonItem,
  IonButton,
  IonSpinner,
  IonToast,
  useIonRouter,
} from '@ionic/react';

import FormItem from '../../components/FormItem';
import NavigationBar from '../../components/NavigationBar';
import { useSyncPets } from '../../hooks';
import { useEnter } from '../../hooks/useEnter';
import './SyncPetsPage.scss';
import { ValidationMail } from '../../assets/validations/validationRegex';

type SyncPetInput = {
  email: string;
};

const SyncPetsPage: React.FC = () => {
  const { ref, handleEnter } = useEnter();
  const { control, errors, handleSubmit } = useForm<SyncPetInput>({
    defaultValues: { email: undefined },
  });
  const history = useIonRouter();
  const { okMessage, error, isLoading, assocPets } = useSyncPets();

  const onSubmit = async ({ email }: SyncPetInput) => {
    await assocPets(email.trim());
  };

  return (
    <IonPage>
      <NavigationBar goBack={false} path='ajustes' />
      <IonContent className="ion-padding">
        <div className='ion-padding-horizontal'>
          <div className="sync-pets-page__info-container">
            <p>
              Con este asistente usted podrá traer a la aplicación las{' '}
              <b>mascotas cuyo seguro haya sido contratado por otro medio</b>.
            </p>
            <p>
              Ingrese por favor el <span>email que utilizó al contratar</span>:
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} onKeyUp={handleEnter}>
            <FormItem
              errors={errors}
              render={({ onChange }) => (
                <IonItem className="ion-no-padding">
                  <IonInput
                    type="email"
                    placeholder="correo.electronico@fielpet.com.ar"
                    onIonChange={onChange}
                  />{' '}
                </IonItem>
              )}
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, ingrese su email',
                },
                pattern: ValidationMail,
              }}
            />
            <div className="sync-pets-page__info-container">
              <p>
                A continuación, <span>le enviaremos un correo electrónico</span> a
                la casilla de email ingresada con las instrucciones para continuar
                el proceso.
              </p>
            </div>
            <div className="ion-padding-top">
              <IonButton type="submit" ref={ref} mode="ios" expand="block">
                {isLoading && (
                  <IonSpinner
                    name="crescent"
                    className="button-spinner"
                    slot="end"
                  />
                )}
                Comenzar proceso
              </IonButton>
            </div>
          </form>

          <IonToast
            position="bottom"
            isOpen={okMessage !== undefined}
            onDidDismiss={() => history.push('/tabs/home', 'forward', 'push')}
            message={'Email enviado con éxito'}
            duration={3000}
          />

          <IonToast
            position="bottom"
            isOpen={error !== undefined}
            message={'Ocurrió un problema, intente más tarde.'}
            duration={3000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SyncPetsPage;
