import React, { useEffect, useState } from 'react';

import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';

import Cat from '../../assets/home-cat-transparent.png';
import Dog from '../../assets/home-dog-transparent.png';
import Girl from '../../assets/asistmovilGirl.png';
import './HomeCards.scss';
import { HomeCardsProps } from './types';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { A11y, Autoplay, Pagination, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const HomeCards: React.FC<HomeCardsProps> = () => {
  const [petImgIndex, setPetImgIndex] = useState<number>();
  const pets = new Array(Cat, Dog);

  useEffect(() => {
    setPetImgIndex(Math.floor(Math.random() * pets.length));
  }, []);

  return (
    <div className="home-cards ion-margin">
      <Swiper
        modules={[Pagination, Scrollbar, A11y, Autoplay]}
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 4000,
        }}
      >
        <SwiperSlide>
          <IonGrid className='card ion-no-padding ion-margin-bottom'>
            <IonRow>
              <IonCol>
                <img src={pets[petImgIndex!]} />
              </IonCol>
              <IonCol>
                <div className="home-cards__title">Fielpet</div>
                <div className="home-cards__subtitle ion-margin-vertical">
                  Tu mascota se <br />
                  merece lo mejor
                </div>
                <IonButton href="https://fielpet.com.ar">Conocer más</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid className='card-2 ion-no-padding'>
            <IonRow>
              <IonCol>
                <img src={Girl} />
              </IonCol>
              <IonCol>
                <div className="title">Asistmóvil</div>
                <div className="home-cards__subtitle ion-margin-vertical">
                  Asegura con los <br />
                  mejores precios
                </div>
                <IonButton href="https://www.asistmovil.com">Conocer más</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HomeCards;
