import React, { useState } from 'react';

import {
  IonAlert,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import 'animate.css';
import { chevronForward } from 'ionicons/icons';

import Documentation from '../../assets/documentation.png';
import MedicalRecord from '../../assets/medical-history.png';
import Reintegro from '../../assets/refund.png';
import Schedule from '../../assets/schedule.png';
import { useSiniestro } from '../../context/SiniestroContext';
import { SubVetForm } from '../../interfaces/SubVetForm';
import AddVetContact from '../AddVetContact';
import ToastAlert from '../ToastAlert';
import './SiniestroMenu.scss';
import { SiniestroMenuProps } from './types';

const SiniestroMenu: React.FC<SiniestroMenuProps> = ({ setStep, nombre }) => {
  const {
    siniestro: { data: siniestro },
    documentacion: { data: documentacion },
    indicaciones: { data: indicaciones },
  } = useSiniestro();

  const [indicacionesSeleccionadas, setIndicacionesSeleccionadas] = useState<
    SubVetForm[]
  >([]);

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [isOkAlertOpen, setOkAlertOpen] = useState<boolean>(false);
  const [isFailAlertOpen, setFailAlertOpen] = useState<boolean>(false);

  const { cbu, terceroCbu, constancia, ...checkDocumentacion } =
    documentacion || {};
  const documentacionRestante = documentacion
    ? Object.values(checkDocumentacion).filter((v) => v === null).length +
      (cbu || (constancia && terceroCbu) ? 0 : 1)
    : null;
  const pendientes = indicaciones
    ? indicaciones.filter((i) => i.estado === 'PENDIENTE').length
    : 0;

  //   <i>
  //   Enviaremos un formulario de consulta a su veterinario para
  //   completar la historia clínica de {nombre}.
  // </i>

  return !siniestro ? (
    //TODO Spinner
    <IonSpinner color="light" />
  ) : (
    <div className="siniestro-menu ion-margin-top">
      <div className="siniestro-menu__heading ion-padding-horizontal">
        {/* <h1>Menu</h1> */}
        <p>
          Para gestionar su reintegro complete los datos del veterinario que
          atendio a {nombre} en la sección
          <span className="bold"> Nuevo reintegro</span>, adjunte comprobante de
          gastos en <span className="bold"> Reintegros</span> y cumplimente la
          sección <span className="bold">Documentación</span>.
        </p>
      </div>

      <div className="ion-padding ion-margin-top">
        {/* <div className="siniestro-menu__img-box-reintegroContainer">
          <IonGrid
            className="ion-no-padding ion-margin-bottom"
            onClick={(e: any) => {
              e.persist();
              setShowAlert(true);
            }}
          >
            <IonRow>
              <IonCol size="3" className="siniestro-menu__img-box-reintegro">
                <img src={Reintegro} />
              </IonCol>
              <IonCol offsetSm="0.5" sizeXs="7.5" sizeSm="8">
                <div className="siniestro-menu__title-box">Nuevo reintegro</div>
              </IonCol>
              <IonCol>
                <IonIcon icon={chevronForward} color="primary" />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div> */}

        <IonGrid
          className="ion-no-padding ion-margin-bottom"
          onClick={(e: any) => {
            e.persist();
            setShowAlert(true);
          }}
        >
          <IonRow>
            <IonCol size="3" className="siniestro-menu__img-box">
              <img src={Reintegro} />
              {/* <IonIcon icon={reader} color="primary" /> */}
            </IonCol>
            <IonCol offsetSm="0.5" sizeXs="7.5" sizeSm="8">
              <div className="siniestro-menu__title-box">Nuevo reintegro</div>
            </IonCol>
            <IonCol>
              <IonIcon icon={chevronForward} color="primary" />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid
          className="ion-no-padding ion-margin-bottom"
          onClick={() => setStep(1)}
        >
          <IonRow>
            <IonCol size="3" className="siniestro-menu__img-box">
              {pendientes >= 1 && (
                <div className="siniestro-menu__badge animate__animated animate__bounce">
                  {pendientes}
                </div>
              )}
              <img src={MedicalRecord} />
              {/* <IonIcon icon={fileTray} color="primary" /> */}
            </IonCol>
            <IonCol offsetSm="0.5" sizeXs="7.5" sizeSm="8">
              <div className="siniestro-menu__title-box">Reintegros</div>
            </IonCol>
            <IonCol>
              <IonIcon icon={chevronForward} color="primary" />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid
          className="ion-no-padding ion-margin-bottom"
          onClick={() => setStep(2)}
        >
          <IonRow>
            <IonCol size="3" className="siniestro-menu__img-box">
              {documentacionRestante && (
                <div className="siniestro-menu__badge animate__animated animate__bounce">
                  {documentacionRestante}
                </div>
              )}
              <img src={Documentation} />
              {/* <IonIcon icon={wallet} color="primary" /> */}
            </IonCol>
            <IonCol offsetSm="0.5" sizeXs="7.5" sizeSm="8">
              <div className="siniestro-menu__title-box">Documentación</div>
            </IonCol>
            <IonCol>
              <IonIcon icon={chevronForward} color="primary" />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid
          className="ion-no-padding ion-margin-bottom"
          onClick={() => setStep(3)}
        >
          <IonRow>
            <IonCol size="3" className="siniestro-menu__img-box">
              <img src={Schedule} />
              {/* <IonIcon icon={reader} color="primary" /> */}
            </IonCol>
            <IonCol offsetSm="0.5" sizeXs="7.5" sizeSm="8">
              <div className="siniestro-menu__title-box">Agenda</div>
            </IonCol>
            <IonCol>
              <IonIcon icon={chevronForward} color="primary" />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>

      <IonAlert
        mode="ios"
        isOpen={showAlert}
        onWillDismiss={() =>
          setShowPopover({ showPopover: true, event: undefined })
        }
        onDidDismiss={() => setShowAlert(false)}
        header="Aviso importante"
        // subHeader="Mensaje importante"
        message="Para que el trámite continúe su curso, es necesario que el veterinario reciba y complete el formulario que le será enviado, de lo contrario el reintegro no será procesado."
        buttons={['Entendido']}
      />

      <AddVetContact
        indicacion={undefined}
        indicacionesSeleccionadas={indicacionesSeleccionadas}
        setIndicacionesSeleccionadas={setIndicacionesSeleccionadas}
        popoverState={popoverState}
        setShowPopover={setShowPopover}
        setOkAlertOpen={setOkAlertOpen}
        setFailAlertOpen={setFailAlertOpen}
        nombre={nombre}
      />

      <ToastAlert
        isOpen={isOkAlertOpen}
        setShowToast={setOkAlertOpen}
        message="Formulario enviado correctamente!"
        color="success"
        duration={3500}
      />

      <ToastAlert
        isOpen={isFailAlertOpen}
        setShowToast={setFailAlertOpen}
        message="El formulario no se pudo enviar. Por favor intentelo nuevamente más tarde."
        color="danger"
        duration={3500}
      />
    </div>
  );
};

export default SiniestroMenu;
