import React from 'react';

import { IonPopover } from '@ionic/react';

import './SiniestroPopover.scss';
import { SiniestroPopoverProps } from './types';

const SiniestroPopover: React.FC<SiniestroPopoverProps> = ({
  popoverState,
  setShowPopover,
  denunciaAdministrativa,
}) => {
  return (
    <div className="siniestro-popover">
      <IonPopover
        cssClass="siniestro-popover__popover"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <span className="siniestro-popover__title">Evento</span>
        <div className="siniestro-popover__denuncia">
          <span>{denunciaAdministrativa}</span>
        </div>
      </IonPopover>
    </div>
  );
};

export default SiniestroPopover;
