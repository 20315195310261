import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonItem,
  IonInput,
  IonDatetime,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonButton,
} from '@ionic/react';
import moment from 'moment';

import { useEnter } from '../../hooks/useEnter';
import {
  PetAddForm1Type,
  PetDataModificationRequest,
  getEmptyPetDataModificationRequest,
} from '../../interfaces/forms/PetForm1';
import { maxDateNacPet, minDateNacPet } from '../../utils/DateUtilities';
import {
  buildModification,
  formatDateValue,
  getNonNullFields,
  isReqValid,
} from '../../utils/Form';
import {
  capitalizeEveryWord,
  capitalizeFirstLetter,
} from '../../utils/General';
import FormItem from '../FormItem';
import Searchbar from '../Searchbar';
import ToastAlert from '../ToastAlert';
import './FormAddPet_1.scss';
import { FormAddPet_1Props } from './types';

const FormAddPet_1: React.FC<FormAddPet_1Props> = ({
  nextForm,
  setAddPetForm1,
  form1,
  razasPerro,
  razasGato,
  pet,
  setNonNullFields,
  setShowPopover,
}) => {
  const { ref, handleEnter } = useEnter();
  const [showToast, setShowToast] = useState<boolean>(false);

  const editingPet: PetAddForm1Type = {
    nombre: pet?.nombre,
    color: pet?.color,
    peso: undefined,
    sexo: pet?.sexo,
    fecha_nacimiento: pet?.fecha_nacimiento,
    animal: pet?.animal,
    raza: {
      value: String(pet?.raza),
      label: String(pet?.raza),
    },
  };

  const handleDefault = pet ? editingPet : form1;

  const { control, handleSubmit, errors, watch } = useForm<PetAddForm1Type>({
    defaultValues: handleDefault,
  });

  const tipoWatcher = watch('animal');

  const onSubmit = (values) => {
    if (pet) {
      setShowToast(false);
      const emptyPetDataModificationRequest: PetDataModificationRequest = getEmptyPetDataModificationRequest();
      values.fecha_nacimiento = formatDateValue(values.fecha_nacimiento);
      values.raza = capitalizeEveryWord(values.raza?.value);
      pet.fecha_nacimiento = formatDateValue(pet.fecha_nacimiento);
      const req: PetDataModificationRequest = buildModification(
        emptyPetDataModificationRequest,
        [values, pet]
      );
      const isValid: boolean = isReqValid(req);
      if (isValid) {
        //Popover maneja el submit final de solicitud de cambio
        setNonNullFields(getNonNullFields(req));
        setShowPopover({ showPopover: true, event: undefined });
      } else {
        //Al menos un campo debe ser modificado
        setShowToast(true);
      }
    } else {
      setAddPetForm1(values);
      nextForm && nextForm();
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form-add-pet-1 ion-padding"
      onKeyUp={handleEnter}
    >
      {/* Nombre */}
      <FormItem
        render={({ onChange, value, name }) => (
          <IonItem lines="inset" className="ion-no-padding">
            <IonLabel className="form-add-pet-1__grey" position="floating">
              Nombre
            </IonLabel>
            <IonInput
              value={capitalizeEveryWord(value)}
              type="text"
              name={name}
              onIonChange={onChange}
            />
          </IonItem>
        )}
        control={control}
        name="nombre"
        errors={errors}
        rules={{
          required: {
            value: true,
            message: 'Por favor, ingrese nombre',
          },
        }}
      />

      {/* Color */}
      <FormItem
        render={({ onChange, name, value }) => (
          <IonItem lines="inset" className="ion-no-padding">
            <IonLabel className="form-add-pet-1__grey" position="floating">
              Color
            </IonLabel>
            <IonInput
              value={capitalizeFirstLetter(value)}
              type="text"
              name={name}
              onIonChange={onChange}
            />
          </IonItem>
        )}
        control={control}
        name="color"
        errors={errors}
        rules={{
          required: {
            value: true,
            message: 'Por favor, ingrese color',
          },
        }}
      />

      <div hidden={pet !== undefined}>
        {/* Peso */}
        <FormItem
          render={({ onChange, name, value }) => (
            <IonItem lines="inset" className="ion-no-padding">
              <IonLabel className="form-add-pet-1__green" position="floating">
                Peso (kg)
              </IonLabel>
              <IonInput
                className="form-add-pet-1__input"
                type="number"
                name={name}
                value={value}
                placeholder="(opcional)"
                onIonChange={onChange}
              />
            </IonItem>
          )}
          control={control}
          errors={errors}
          name="peso"
          rules={{
            max: {
              value: 200,
              message: 'Excede el máximo',
            },
            min: {
              value: 1,
              message: 'No puede ser 0',
            },
          }}
        />
      </div>

      {/* Sexo */}
      <FormItem
        render={({ onChange, name, value }) => (
          <IonItem lines="inset" className="ion-no-padding">
            <IonRadioGroup
              name={name}
              value={value}
              onIonChange={(e) => onChange(e.detail.value)}
            >
              <div className="form-add-pet-1__radio">
                <IonLabel>Sexo</IonLabel>

                <IonItem lines="none">
                  <IonLabel>Hembra</IonLabel>
                  <IonRadio
                    slot="start"
                    mode="md"
                    value="H"
                    className="ion-no-margin"
                  />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Macho</IonLabel>
                  <IonRadio
                    slot="start"
                    mode="md"
                    value="M"
                    className="ion-no-margin"
                  />
                </IonItem>
              </div>
            </IonRadioGroup>
          </IonItem>
        )}
        rules={{
          required: {
            value: true,
            message: 'Por favor, ingrese sexo',
          },
        }}
        control={control}
        name="sexo"
        errors={errors}
      />

      {/* Nacimiento */}
      <FormItem
        render={({ onChange, name, value }) => (
          <IonItem lines="inset" className="ion-no-padding">
            <IonLabel position="floating" className="form-add-pet-1__grey">
              Nacimiento
            </IonLabel>
            <IonDatetime
              name={name}
              value={value}
              displayFormat="DD/MM/YYYY"
              cancelText="Cancelar"
              doneText="Ok"
              onIonChange={onChange}
              max={maxDateNacPet}
              min={minDateNacPet}
            ></IonDatetime>
          </IonItem>
        )}
        control={control}
        errors={errors}
        name="fecha_nacimiento"
        rules={{
          required: {
            value: true,
            message: 'Por favor, ingrese fecha de nacimiento',
          },
        }}
      />

      {/* Tipo */}
      <FormItem
        render={({ onChange, name, value }) => (
          <IonItem lines="inset" className="ion-no-padding">
            <IonRadioGroup
              name={name}
              value={value}
              onIonChange={(e) => {
                onChange(e.detail.value);
                control.setValue('raza', undefined);
              }}
            >
              <div className="form-add-pet-1__radio">
                <IonLabel>Especie</IonLabel>

                <IonItem lines="none">
                  <IonLabel>Perro</IonLabel>
                  <IonRadio slot="start" mode="md" value="Perro" />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Gato</IonLabel>
                  <IonRadio slot="start" mode="md" value="Gato" />
                </IonItem>
              </div>
            </IonRadioGroup>
          </IonItem>
        )}
        rules={{
          required: {
            value: true,
            message: 'Por favor, indique tipo de animal',
          },
        }}
        control={control}
        name="animal"
        errors={errors}
      />

      {/* Raza */}
      <div className="ion-margin-vertical">
        <FormItem
          className="form-add-pet-1__raza"
          render={({ value, onChange }) => (
            <Searchbar
              value={value}
              placeholder="Seleccione una raza"
              options={
                tipoWatcher
                  ? tipoWatcher === 'Perro'
                    ? razasPerro
                    : razasGato
                  : []
              }
              onChange={onChange}
            />
          )}
          control={control}
          name="raza"
          errors={errors}
          rules={{
            required: {
              value: true,
              message: 'Por favor, ingrese raza',
            },
          }}
        />
      </div>

      <div className="ion-margin-top">
        <IonButton ref={ref} type="submit" mode="ios" expand="block">
          {!pet ? 'Siguiente' : 'Confirmar'}
        </IonButton>
      </div>

      <ToastAlert
        isOpen={showToast}
        setShowToast={setShowToast}
        message="Ups... Debe modificar al menos uno de los datos de su mascota para continuar."
        color="warning"
        duration={3500}
      />
    </form>
  );
};

export default FormAddPet_1;
