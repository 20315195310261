import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonIcon,
  IonContent,
} from '@ionic/react';
import {
  calendarOutline,
  cardOutline,
  contractOutline,
  globeOutline,
  personCircleOutline,
} from 'ionicons/icons';
import moment from 'moment';

import FielPetLogo from '../../assets/FielPetLogo.png';
import { usePayments } from '../../hooks';
import { usePostDatosPago } from '../../hooks/useDatosPago';
import { AddPaymentFormType } from '../../interfaces/forms/AddPaymentForm';
import {
  maxDateCreditCard,
  minDateCreditCard,
} from '../../utils/DateUtilities';
import { cbuValidation } from '../../utils/cbuValidation';
import { luhnValidation } from '../../utils/luhnValidation';
import AdviceAlert from '../AdviceAlert';
import FormItem from '../FormItem';
import './AddPayment.scss';
import { AddPaymentProps } from './types';
import { formatCreditCardDate, formatCreditCardNumber } from '../../utils/General';

const AddPayment: React.FC<AddPaymentProps> = ({ setNewMedioPago }) => {
  const { control, handleSubmit, errors, register, setValue } = useForm({
    defaultValues: {
      vencimiento: '',
      numero: '',
      marca: null,
      nombre: ''
    }
  });
  const payments = usePayments();
  const { isLoading, addDatosPago } = usePostDatosPago();
  const [isFailAlertOpen, setFailAlertOpen] = useState<boolean>(false);
  const [isOkAlertOpen, setOkAlertOpen] = useState<boolean>(false);
  const [medioPago, setMedioPago] = useState<string>('');
  const [hasCompany, setHasCompany] = useState<boolean>(false);

  const bottom = useRef<any>(null);

  const scrollToBottom = () => {
    bottom.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const buildExpirationDate = (date: string) => {
    const [mes, año] = date.split('/');
    const añoCompleto = `20${año}`;
    const transformedDate = `${añoCompleto}-${mes}-${año}T00:00:00-03:00`;

    return transformedDate;
  };

  const validateExpirationDate = (date: string) => {
    const [mes, año] = date.split('/');
    const min = moment(minDateCreditCard).format('YYYY/MM/DD');
    const max = moment(maxDateCreditCard).format('YYYY/MM/DD');
    const expireDate = `20${año}/${mes}/01`;

    if (moment(expireDate).isBetween(min, max)) {
      return true;
    } else {
      return false;
    }
  };

  const isValidCreditCard = (value: string) => {
    const idChosen = control.getValues('marca');
    if (value && idChosen !== undefined) {
      const [medioPago] = payments.filter((p) => p.id === idChosen);
      const v1 =
        medioPago.verifica === 'luhn'
          ? luhnValidation(value)
          : true;
      const v2 = medioPago.size.includes(value.length);
      return v1 && v2;
    } else {
      return false;
    }
  };

  const isValidCBU = (cbu: string) => {
    return cbu.length === 22;
  };

  function deleteCardNumberSpaces(n: string) {
    return n.replace(/\s/g, '');
  }

  const onSubmit = async (values: AddPaymentFormType) => {
    console.log("medioPagoValues:", values)
    const marca = medioPago === "cbuAhorro" ? 30 : 31;

    const ok = await addDatosPago(
      medioPago !== "creditCard" ? values.numero : deleteCardNumberSpaces(values.numero!),
      medioPago !== "creditCard" ? values.vencimiento : buildExpirationDate(values.vencimiento!),
      medioPago !== "creditCard" ? marca : values.marca
    );

    if (ok) {
      setOkAlertOpen(true);
      setMedioPago("");
      setHasCompany(false);
    } else {
      setFailAlertOpen(true);
    }
  };

  const submitButton = (
    <div className='ion-margin-top'>
      <IonButton
        type="submit"
        mode="ios"
        expand="block"
        className="add-payment-form__submit-btn"
      >
        {isLoading && <IonSpinner name="crescent" slot="end" />}
        Confirmar
      </IonButton>
    </div>
  );

  const failAdviceAlert = (
    <AdviceAlert
      isOpen={isFailAlertOpen}
      setOpen={setFailAlertOpen}
      header="¡ Ups !"
      subHeader=""
      message="No se pudo cargar el medio de pago, intente más tarde."
      action={() => setFailAlertOpen}
    />
  );

  const okAdviceAlert = (
    <AdviceAlert
      isOpen={isOkAlertOpen}
      setOpen={setOkAlertOpen}
      onDidDismiss={() => setNewMedioPago(false)}
      header="¡ Éxito !"
      subHeader=""
      message="El medio de pago se cargó correctamente"
      action={() => setNewMedioPago(true)}
    />
  );

  return (
    <div className="add-payment-form">
      <div className="ion-margin-bottom">
        <IonRadioGroup
          value={medioPago}
          onIonChange={(e) => setMedioPago(e.detail.value)}
        >
          <IonListHeader>
            <IonLabel><h1>Nuevo medio de pago</h1></IonLabel>
          </IonListHeader>

          <IonItem lines="none">
            <IonLabel>Tarjeta de credito</IonLabel>
            <IonRadio slot="start" mode="md" value="creditCard" />
          </IonItem>

          <IonItem lines="none">
            <IonLabel>CBU caja de ahorro</IonLabel>
            <IonRadio slot="start" mode="md" value="cbuAhorro" />
          </IonItem>

          <IonItem lines="none">
            <IonLabel>CBU cuenta corriente</IonLabel>
            <IonRadio slot="start" mode="md" value="cbuCorriente" />
          </IonItem>
        </IonRadioGroup>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='ion-margin-top'>
          {medioPago === 'creditCard' && (
            <>
              <div className={hasCompany ? "add-payment-form__creditCard-container ion-padding" : undefined}>
                <div className={!hasCompany ? "ion-padding-horizontal ion-margin-bottom" : ""}>
                  <FormItem
                    render={({ onChange, name }) => (
                      <IonItem lines="inset" className="ion-no-padding">
                        <IonIcon icon={globeOutline} color="primary" />
                        <IonLabel className="add-payment-form__company-select">
                          Compañia
                        </IonLabel>
                        <IonSelect
                          onIonChange={(e) => {
                            onChange(e);
                            setHasCompany(true);
                            scrollToBottom();
                          }}
                          name={name}
                          ref={register}
                          okText="Confirmar"
                          cancelText="Cancelar"
                        >
                          {payments.map((p) => (
                            <IonSelectOption key={p.id} value={p.id}>
                              {p.tipo}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    )}
                    control={control}
                    errors={errors}
                    name="marca"
                    rules={{
                      required: {
                        value: true,
                        message: 'Por favor, indique su compañia',
                      },
                    }}
                  />
                </div>

                <div hidden={!hasCompany} className='credit-card'>
                  <FormItem
                    render={({ value, name, onChange }) => (
                      <IonItem lines="inset" className="ion-no-padding">
                        <IonIcon icon={cardOutline} color="primary" />
                        <IonInput
                          placeholder="0000 0000 0000 0000"
                          value={value}
                          name={name}
                          ref={register}
                          inputMode='numeric'
                          maxlength={19}
                          onIonChange={(e) =>
                            onChange(formatCreditCardNumber(e))
                          }
                        />
                      </IonItem>
                    )}
                    control={control}
                    name="numero"
                    errors={errors}
                    rules={{
                      required: {
                        value: true,
                        message: 'Por favor, ingrese su nº de tarjeta',
                      },
                      validate: (value: any) => {
                        if (isValidCreditCard(deleteCardNumberSpaces(value))) {
                          return true;
                        } else {
                          return 'Número inválido';
                        }
                      },
                    }}
                  />

                  <FormItem
                    render={({ value, name, onChange }) => (
                      <IonItem lines="inset" className="ion-no-padding">
                        <IonIcon icon={personCircleOutline} color={'primary'} />
                        <IonInput
                          placeholder="Nombre titular (Opcional)"
                          type="text"
                          value={value}
                          name={name}
                          ref={register}
                          onIonChange={onChange}
                        />
                      </IonItem>
                    )}
                    control={control}
                    name="nombre"
                    errors={errors}
                    rules={{
                      required: {
                        value: false,
                      },
                    }}
                  />

                  <FormItem
                    render={({ value, name, onChange }) => (
                      <IonItem lines="inset" className="ion-no-padding">
                        <IonIcon icon={calendarOutline} color={'primary'} />
                        <IonInput
                          placeholder="MM/YY"
                          value={value}
                          name={name}
                          ref={register}
                          inputMode='numeric'
                          maxlength={5}
                          onIonChange={(e) =>
                            onChange(formatCreditCardDate(e))
                          }
                        />
                      </IonItem>
                    )}
                    control={control}
                    name="vencimiento"
                    errors={errors}
                    rules={{
                      required: {
                        value: true,
                        message: 'Por favor, indique el vencimiento de su tarjeta',
                      },
                      validate: (value: any) => {
                        if (validateExpirationDate(value)) {
                          return true;
                        } else {
                          return 'Fecha inválida';
                        }
                      },
                    }}
                  />

                  {submitButton}
                  <IonButton mode='ios' expand='block' fill='outline' color='dark' className='aux-mt' onClick={() => { setNewMedioPago(false); setMedioPago(""); setHasCompany(false) }}>Cancelar</IonButton>
                </div>
              </div>
            </>
          )}



          {medioPago === 'cbuAhorro' && (
            <div className="ion-padding-horizontal">
              <FormItem
                render={({ onChange, name }) => (
                  <IonItem lines="inset" className="ion-no-padding">
                    <IonLabel position="floating">
                      Número de caja de ahorro
                    </IonLabel>
                    <IonInput
                      type="number"
                      name={name}
                      ref={register}
                      onIonChange={(e) => {
                        onChange(e);
                      }}
                    />
                  </IonItem>
                )}
                control={control}
                name="numero"
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: 'Por favor, ingrese su número de caja de ahorro',
                  },
                  pattern: {
                    value: /^[0-9]{22}$/,
                    message: 'Por favor, ingrese 22 dígitos.',
                  },
                  validate: (value: string) => {
                    if (isValidCBU(value)) {
                      return true;
                    } else {
                      return 'Número de CBU inválido';
                    }
                  },
                }}
              />

              {submitButton}
            </div>
          )}

          {medioPago === 'cbuCorriente' && (
            <div className="ion-padding-horizontal">
              <FormItem
                render={({ onChange, name }) => (
                  <IonItem lines="inset" className="ion-no-padding">
                    <IonLabel position="floating">
                      Número de cuenta corriente
                    </IonLabel>
                    <IonInput
                      type="number"
                      name={name}
                      ref={register}
                      onIonChange={(e) => onChange(e)}
                    />
                  </IonItem>
                )}
                control={control}
                name="numero"
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: 'Por favor, ingrese su número de cuenta corriente',
                  },
                  pattern: {
                    value: /^[0-9]{22}$/,
                    message: 'Por favor, ingrese 22 dígitos.',
                  },
                }}
              />

              {submitButton}
            </div>
          )}
        </div>
      </form>
      <div ref={bottom} />
      {isFailAlertOpen ? failAdviceAlert : null}
      {isOkAlertOpen ? okAdviceAlert : null}
      <div className={medioPago === "creditCard" ? "ion-padding-horizontal ion-margin-top aux-mt" : "ion-padding-horizontal aux-mt"}>
          <IonButton mode='ios' expand='block' fill='outline' color="dark" hidden={medioPago === "creditCard" && hasCompany} onClick={() => { setNewMedioPago(false); setMedioPago(""); setHasCompany(false) }}>Cancelar</IonButton>
      </div>
    </div>
  );
};

export default AddPayment;
