import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonLabel,
  IonItem,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonToggle,
} from '@ionic/react';

import { CrearEventoFormContext } from '../../../context/CrearEventoContext';
import { useGetVacunas } from '../../../hooks/useGetVacunas';
import { CrearEventoConsultaFormInterface } from '../../../interfaces/CrearEvento';
import {
  maxDateEvent,
  minDateEvent,
  today,
  sortDates,
} from '../../../utils/DateUtilities';
import FormItem from '../../FormItem';
import TextBox from '../../TextBox';
import CrearEventoFooter from '../CrearEventoFooter';
import './CrearEventoVacunaForm.scss';
import { CrearEventoVacunaFormProps } from './types';

const CrearEventoVacunaForm: React.FC<CrearEventoVacunaFormProps> = ({
  onSubmit,
  defaultForm,
  onEditMode = false,
  changeReset,
  doReset,
  idPet,
  dateEvent,
}) => {
  const { control, errors, handleSubmit, reset } = useForm<
    CrearEventoConsultaFormInterface
  >({ defaultValues: defaultForm });

  const [realizado, setRealizado] = useState(
    defaultForm ? defaultForm.done : true
  );

  const [minDateRefuerzo, setMinDateRefuerzo] = useState(
    dateEvent ? dateEvent : onEditMode ? defaultForm?.fecha : minDateEvent(true)
  );

  useEffect(() => {
    reset();
    !changeReset || changeReset(false);
  }, [doReset]);

  const { crearEventoFormValues } = useContext(CrearEventoFormContext);

  const { vacunasFiltered } = useGetVacunas(
    idPet ?? crearEventoFormValues?.mascota?.toString() ?? ''
  );

  useEffect(() => {
    control.setValue('VacunaId', -1);
    setTimeout(() => control.setValue('VacunaId', defaultForm?.VacunaId));
  }, [vacunasFiltered]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="crear-evento-vacuna-form ion-padding">
        {onEditMode ? (
          <>
            <FormItem
              render={({ onChange, value }) => (
                <div className="crear-evento-vacuna-form__row">
                  <IonLabel>Fecha</IonLabel>
                  <IonItem style={{ flexGrow: 1 }} className="ion-no-padding">
                    <IonDatetime
                      value={value}
                      className="ion-no-padding"
                      displayFormat="DD/MM/YYYY"
                      cancelText="Cancelar"
                      doneText="Ok"
                      onIonChange={(e) => {
                        onChange(e.detail.value);
                        setMinDateRefuerzo(control.getValues('fecha'));
                        if (e.detail.value) {
                          if (
                            sortDates(
                              e.detail.value,
                              control.getValues('proxima_aplicacion')
                            )
                          ) {
                            control.setValue('proxima_aplicacion', undefined);
                          }
                        }
                      }}
                      max={maxDateEvent(realizado ?? false)}
                      min={minDateEvent(realizado ?? false)}
                    ></IonDatetime>
                  </IonItem>
                </div>
              )}
              control={control}
              name="fecha"
              errors={errors}
            />

            <FormItem
              render={({ onChange, value }) => (
                <div className="crear-evento-vacuna-form__row">
                  <IonLabel>Hora</IonLabel>
                  <IonItem style={{ flexGrow: 1 }} className="ion-no-padding">
                    <IonDatetime
                      value={value}
                      className="ion-no-padding"
                      minuteValues="0,15,30,45"
                      displayFormat="HH:mm"
                      pickerFormat="HH:mm"
                      cancelText="Cancelar"
                      doneText="Ok"
                      onIonChange={onChange}
                    ></IonDatetime>
                  </IonItem>
                </div>
              )}
              control={control}
              name="hora"
              errors={errors}
            />

            <FormItem
              render={() => (
                <IonItem
                  lines="none"
                  hidden={defaultForm ? defaultForm.done : true}
                  className='ion-no-padding'
                >
                  <IonLabel color='primary'>¿Realizado?</IonLabel>
                  <IonToggle
                    mode='ios'
                    checked={realizado}
                    onIonChange={(e) => {
                      setRealizado(e.detail.checked);
                      control.setValue('done', e.detail.checked);
                      control.setValue('fecha', today);
                      control.setValue('hora', today);
                    }}
                  />
                </IonItem>
              )}
              name="done"
              control={control}
            ></FormItem>
          </>
        ) : null}

        <div className="ion-margin-bottom">
          <FormItem
            name="VacunaId"
            control={control}
            errors={errors}
            render={({ onChange, value, name }) => (
              <div className="crear-evento-vacuna-form__row">
                <IonLabel>Vacuna</IonLabel>
                <IonSelect
                  name={name}
                  value={value}
                  // style={{ textAlign: 'right' }}
                  onIonChange={onChange}
                  placeholder="Seleccione la vacuna"
                  interface="popover"
                  className="ion-no-padding ion-padding-top"
                >
                  {vacunasFiltered?.map((event) => (
                    <IonSelectOption key={event.id} value={event.id}>
                      {event.nombre}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: 'Por favor, complete este campo.',
              },
            }}
          />
        </div>

        {onEditMode && !defaultForm?.proxima_aplicacion ? null : (
          <div className="ion-margin-bottom">
            <FormItem
              name="proxima_aplicacion"
              control={control}
              errors={errors}
              render={({ onChange, value, name }) => (
                <div className="crear-evento-vacuna-form__row">
                  <IonLabel>Fecha</IonLabel>
                  <IonItem style={{ flexGrow: 1 }} className="ion-no-padding">
                    <IonDatetime
                      doneText="Ok"
                      cancelText="Cancelar"
                      value={value}
                      name={name}
                      className="ion-no-padding"
                      picker-format="DD MM YYYY"
                      displayFormat="DD/MM/YYYY"
                      placeholder="Proxima aplicación"
                      onIonChange={onChange}
                      min={minDateRefuerzo}
                      max={maxDateEvent(false)}
                    />
                  </IonItem>
                </div>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, complete este campo.',
                },
              }}
            />
          </div>
        )}

        <FormItem
          name="description"
          control={control}
          errors={errors}
          render={({ onChange, value, name }) => (
            <>
              <IonLabel>Descripción</IonLabel>
              <TextBox onIonChange={onChange} value={value} name={name} />
            </>
          )}
        />
      </div>
      {onEditMode ? <CrearEventoFooter onEdit={true} /> : <CrearEventoFooter />}
    </form>
  );
};

export default CrearEventoVacunaForm;
