import useSWR from 'swr';

import { GET_PLANES_PORTAL } from '../axios/constants';
import { getPlanesPortal } from '../axios/getPlanesPortal';

export const useGetPlanesPortal = (email: string) => {
  const { data, isValidating, error, mutate } = useSWR([GET_PLANES_PORTAL, email], (url) =>
    getPlanesPortal(email),{
      revalidateOnFocus:false,
      dedupingInterval:2000,
    }
  );

  return { planes: data, isValidating, error, mutate };
};
