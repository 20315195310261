import { AxiosResponse } from 'axios';

import axios from '.';
import { AddSiniestroReq } from '../interfaces/AddSiniestro';
import { Siniestro } from '../interfaces/Siniestro';
import { SINIESTROS } from './constants';

export const getSiniestros = async (url: string, id: string) => {
  try {
    const { data: siniestros }: AxiosResponse<Siniestro[]> = await axios.get(
      url,
      {
        params: {
          id,
        },
      }
    );
    return siniestros;
  } catch (error) {
    console.error(error);
  }
};

export const getSiniestro = async (url: string, siniestroId: string) => {
  try {
    const { data: siniestro }: AxiosResponse<Siniestro> = await axios.get(url, {
      params: {
        siniestroId,
      },
    });
    return siniestro;
  } catch (error) {
    console.error(error);
  }
};

export const postSiniestro = async ({
  datos_siniestro,
}: AddSiniestroReq): Promise<any> => {
  try {
    return await axios.post(SINIESTROS, datos_siniestro);
  } catch (e) {
    console.error(e.response);
    return e.response;
  }
};
