import React from 'react';
import Collapsible from 'react-collapsible';

import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { arrowForwardCircle } from 'ionicons/icons';
import moment from 'moment';

import { Veterinario } from '../../interfaces/Veterinario';
import '../../styles/ReintegrosIndicaciones.scss';
import './../../styles/ReintegrosIndicaciones.scss';
import './IndicacionEnviada.scss';
import { IndicacionEnviadaProps } from './types';
import VetFormComprobanteInput from '../VetFormComprobanteInput';

const IndicacionEnviada: React.FC<IndicacionEnviadaProps> = ({
  indicacion,
}) => {
  const { tipo, created_at, descripcionInicial } = indicacion;

  const { vet } = indicacion ?? {};

  function vetData() {
    const veterinario = vet as Veterinario;
    const { nombre } = veterinario;
    return `${nombre}`;
  }

  function typeHandler() {
    switch (tipo) {
      case 'consulta':
        return 'Consulta enviada';

      case 'estudioMascota':
        return 'Estudio enviado';

      case 'internacionMascota':
        return 'Internación enviada ';

      case 'cirugiaMascota':
        return 'Cirugía enviada';

      case 'practicaMascota':
        return 'Practica enviada';

      default:
        console.log(`error`);
    }
  }

  const date = moment(created_at).format('DD/MM/YYYY');

  function handleDescription() {
    if (descripcionInicial) {
      const lowercasedDescription = descripcionInicial.toLowerCase();
      const capitalizedDescription =
        lowercasedDescription.charAt(0).toUpperCase() +
        lowercasedDescription.slice(1);
      return capitalizedDescription;
    } else {
      // return `Su ${tipo} se envio correctamente!`;
    }
  }

  return (
    <div className="indicacion-enviada ion-margin-top">
      <Collapsible
        transitionTime={200}
        trigger={
          <IonGrid>
            <IonRow>
              <IonCol size="3" className="indicacion-enviada__state-icon">
                <IonIcon icon={arrowForwardCircle} />
              </IonCol>
              <IonCol>
                <>
                  <div className="indicacion-enviada__type">
                    {typeHandler()}
                  </div>
                  <div className="indicacion-enviada__date">{date}</div>
                  <div className="indicacion-enviada__description">
                    {handleDescription()}
                  </div>
                  <VetFormComprobanteInput vetForm={indicacion.vetForm} />
                </>
              </IonCol>
            </IonRow>
          </IonGrid>
        }
      >
        <div className="indicacion-enviada__bottom-container ion-padding-horizontal ion-padding-vertical">
          El{' '}
          <span className="indicacion-enviada__bottom-container_veterinario">
            Dr. {vetData()}
          </span>{' '}
          todavia no ha cumplimentado la información solicitada. Estamos
          aguardando a que se expida para continuar con la gestión.
        </div>
      </Collapsible>
    </div>
  );
};

export default IndicacionEnviada;
