import React, { useState } from 'react';

import { IonButton, IonContent, IonPage, useIonRouter } from '@ionic/react';
import { useMascotas } from '../../context/MascotasContext';
import { getAnimalImage } from '../../utils/timelineUtils';
import SiniestroCard from '../../components/SiniestroCard';
import { Siniestro } from '../../interfaces/Siniestro';
import NavigationBar from '../../components/NavigationBar';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { A11y, Pagination, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import './AllSiniestrosPage.scss';
const AllSiniestrosPage: React.FC = () => {
  const { mascotas: { pets, isValidating } } = useMascotas();
  const history = useIonRouter();
  const [selectedPetIds, setSelectedPetIds] = useState<number[]>([]);
  const siniestros: Siniestro[] = pets?.reduce((acc: Siniestro[], p) => { return acc.concat(p.siniestros) }, []) || [];

  return (
    <IonPage className='all-siniestros-page'>
      <NavigationBar goBack={false} path='home' />
      <IonContent fullscreen>
        <div className="filter ion-margin-vertical ion-padding">
          <Swiper
            modules={[Pagination, Scrollbar, A11y]}
            slidesPerView={3}
            spaceBetween={15}
            pagination={{ clickable: true }}
          >
            {pets && !isValidating && pets.map((pet) => (
              <SwiperSlide>
                <div
                  key={pet.id}
                  onClick={
                    () => {
                      const index = selectedPetIds.indexOf(pet.id);
                      if (index !== -1) {
                        setSelectedPetIds([...selectedPetIds.slice(0, index), ...selectedPetIds.slice(index + 1)]);
                      } else { setSelectedPetIds([...selectedPetIds, pet.id]); }
                    }
                  }
                  className={`${!pet.img_link && "filter__pet-no-img-link"} ${selectedPetIds.indexOf(pet.id) !== -1
                    && 'filter__selected-pet animate__animated animate__pulse animate__faster'}`}
                >
                  <img
                    src={pet.img_link ? pet.img_link : getAnimalImage(pet.animal)}
                  />
                  <p>{pet.nombre}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="siniestros">
          {siniestros &&
            siniestros
              .filter((s) => selectedPetIds.length === 0 || selectedPetIds.indexOf(Number(s.mascotaId)) !== -1)
              .map((siniestro: Siniestro, index: number) => (
                <SiniestroCard key={index} siniestro={siniestro} pets={pets} allSiniestros={true} sexo={pets?.find((p) => p.id.toString() === siniestro.mascotaId)?.sexo ?? 'M'} />
              ))}
        </div>
        <div className='ion-padding'>
          <IonButton mode='ios' expand="block" onClick={() => history.push("/tabs/siniestros/pets")}>Nuevo accidente o enfermedad</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AllSiniestrosPage;