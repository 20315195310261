import { useRef, useState } from 'react';

import {
  addCbu,
  addDni,
  addLibretaVacunacion,
  addExtra,
} from '../axios/addDocumentacion';
import { useCamera } from './useCamera';

interface InternalValues {
  file: any;
}

export const useFile = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { takePicture } = useCamera();

  const values = useRef<InternalValues>({
    file: false,
  });

  const onFileChange = async (
    siniestroId: string,
    type: string,
    cameraSource: boolean
  ) => {
    function dataURLtoBlob(dataUrl: string) {
      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)![1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
    values.current.file = await takePicture(cameraSource);
    values.current.file = dataURLtoBlob(values.current.file);
    // setFileName(values.current.file.name);

    function typeHandler() {
      return type === 'dni_frente'
        ? 'dni'
        : type === 'dni_dorso'
        ? 'dni'
        : type === 'tercero_cbu'
        ? 'cbu'
        : type === 'constancia'
        ? 'cbu'
        : 'file';
    }

    let formData = new FormData();
    formData.append('siniestroId', siniestroId);
    formData.append('label', type);
    formData.append(typeHandler(), values.current.file);
    console.log('TH', typeHandler());

    function handler() {
      console.log(formData);
      switch (type) {
        case 'dni_frente':
          formData.append('lado', 'frente');
          return addDni(formData);
        case 'dni_dorso':
          formData.append('lado', 'dorso');
          return addDni(formData);
        case 'libreta_vacunacion':
          return addLibretaVacunacion(formData);
        case 'cbu':
          formData.append('tipo', 'cbu');
          return addCbu(formData);
        case 'tercero_cbu':
          formData.append('tipo', 'tercero_cbu');
          return addCbu(formData);
        case 'constancia':
          formData.append('tipo', 'constancia');
          return addCbu(formData);
        default:
          return addExtra(formData);
      }
    }

    try {
      setIsLoading(true);
      const res = await handler();
      setIsLoading(false);
      setIsUpdating(true);
    } catch (error) {
      console.log(error);
    }
  };
  return { onFileChange, isUpdating, isLoading };
};
