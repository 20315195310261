import React from 'react';
import { IonTextarea } from '@ionic/react';

import { TextBoxProps } from './types';

import './TextBox.scss';

const TextBox: React.FC<TextBoxProps> = ({ height = '150px', ...props }) => {
  return (
    <IonTextarea
      {...props}
      style={{ '--height': height }}
      className="text-box"
    />
  );
};

export default TextBox;
