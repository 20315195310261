import React, { useEffect } from 'react';
import 'animate.css';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { A11y, Pagination, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { usePetFilterState } from '../../hooks/usePetFilterState';
import { Eventos } from '../../interfaces/Evento';
import { getAnimalImage } from '../../utils/timelineUtils';
import './Filter.scss';
import { FilterProps } from './types';

const Filter: React.FC<FilterProps> = ({
  events,
  pets,
  onFilter,
  isLoading,
}) => {
  const { selectedId, updateIdList, isSelected } = usePetFilterState();

  useEffect(() => {
    if (selectedId) {
      const newEventos: Eventos = {
        hechos: events
          ? events.hechos.filter((ev) => selectedId === ev.mascota)
          : [],
        atrasados: events
          ? events.atrasados.filter((ev) => selectedId === ev.mascota)
          : [],
        proximos: events
          ? events.proximos.filter((ev) => selectedId === ev.mascota)
          : [],
      };

      onFilter(newEventos);
    } else {
      onFilter(events);
    }
  }, [selectedId, onFilter, events]);

  return (
    <div className="filter ion-margin-vertical ion-padding">
      <Swiper
        modules={[Pagination, Scrollbar, A11y]}
        slidesPerView={3}
        spaceBetween={15}
        pagination={{ clickable: true }}
      >
        {pets && !isLoading && pets.map((pet) => (
          <SwiperSlide>
            <div
              key={pet.id}
              onClick={() => updateIdList(pet.id)}
              className={`${!pet.img_link && "filter__pet-no-img-link"} ${isSelected(pet.id)
                && 'filter__selected-pet animate__animated animate__pulse animate__faster'}`}
            >
              <img
                src={pet.img_link ? pet.img_link : getAnimalImage(pet.animal)}
              />
              <p>{pet.nombre}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Filter;
