import { useState } from 'react';
import axiosInstance from '../axios';
import { ASSOC_PETS } from '../axios/constants';

export const useSyncPets = () => {
  const [isLoading, setLoading] = useState(false);
  const [okMessage, setOk] = useState<string>();
  const [error, setError] = useState<string>();

  const assocPets = async (email: string) => {
    setLoading(true);
    try {
      await axiosInstance.post(ASSOC_PETS, { email });
      setLoading(false);
      setOk('¡Mail enviado con éxito!');
      setError(undefined);
      return true;
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(
        '¡Ups! Algo salió mal. Por favor vuelva a intentar en un momento.'
      );
      setOk(undefined);
    }
  };

  return { okMessage, error, isLoading, assocPets };
};
