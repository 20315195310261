import axiosInstance from '.';
import { DATOS_PAGO } from './constants';
import { AxiosResponse } from 'axios';
import { DatosPago } from '../interfaces/DatosPago';
import { PaymentReq } from '../interfaces/Payment';

export const getDatosPago = async () => {
  try {
    const { data: dp }: AxiosResponse<DatosPago[]> = await axiosInstance.get(
      DATOS_PAGO
    );
    return dp;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postDatosPago = async (body: PaymentReq) => {
  try {
    await axiosInstance.post(DATOS_PAGO, body);
    return true;
  } catch (e) {
    console.error(e.response);
    return false;
  }
};
