import { useEffect } from 'react';
import useSWR from 'swr';
import { EVENTO } from '../axios/constants';
import { getOneEvent as axiosGetOne } from '../axios/events';

export const useGetEvent = (id: string) => {
  const { data, error, mutate } = useSWR([EVENTO, id], () => axiosGetOne(id), {
    shouldRetryOnError: false,
  });

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
  }, [data, mutate, error]);

  return { data, error, mutate };
};
