import React, { useState, useEffect } from 'react';
import {
  IonIcon,
  IonSpinner,
  IonAlert,
  IonCol,
  IonGrid,
  IonRow,
  IonInput
} from '@ionic/react';
import { cloudUpload, checkmarkOutline } from 'ionicons/icons';
import { useFile } from '../../hooks/useDocumentacionFile'; //@TODO capaz haya que hacer un hook para doc extra 
import { useSiniestro } from '../../context/SiniestroContext';
import '../FormDocObligatoriaInput/FormDocObligatoriaInput.scss';

const DocExtraInput: React.FC<any> = ({ doc }) => {
  const [showCameraAlert, setShowCameraAlert] = useState<boolean>(false);
  const [cameraSource, setCameraSource] = useState<boolean | undefined>(
    undefined
  );
  const [label, setLabel] = useState<string>('');

  const { siniestroId, documentacion } = useSiniestro();

  const { onFileChange, isUpdating, isLoading } = useFile(); //@TODO capaz haya que hacer otro especifico

  useEffect(() => {
    if (cameraSource !== undefined) {
      const updateData = async () => {
        await onFileChange(siniestroId, label, cameraSource);
        setCameraSource(undefined);
        documentacion.mutate(undefined, true); //@TODO puede que haya que checkear alguna condicion para mutar
      };
      updateData();
    }
  }, [cameraSource]);

  return (<div className="form-doc-obligatoria-input">
    <IonGrid className="ion-no-padding ion-padding-vertical">
      <IonRow>
        <IonCol size="4" className='form-doc-obligatoria-input__label'>
          {doc ? doc.label : "Nuevo archivo"}
        </IonCol>
        {!doc ? (
          <IonCol size="2" onClick={() => !label || label.length === 0 ?
            alert('Por favor indique a qué concepto corresponde el archivo.')
            : setShowCameraAlert(true)}>
            <IonIcon icon={cloudUpload} color="primary" />
          </IonCol>) : <IonCol size="2">          <IonIcon icon={checkmarkOutline} color="primary" /> </IonCol>}
        {!doc ? (
          <IonCol>
            <IonInput
              value={label}
              onIonChange={(e) => {
                if (e && e.detail) {
                  setLabel(e.detail.value!);
                }
                return;
              }}
              placeholder="Informe, recibo, foto, etc."
              className='form-doc-obligatoria-input__placeholder ion-no-padding'
            />
          </IonCol>
        ) : (
          <IonCol className="form-doc-obligatoria-input__listo">
            Documento cargado
          </IonCol>
        )}
      </IonRow>
    </IonGrid>

    {isLoading && (
      <div className="form-doc-obligatoria-input__extra-image-updating">
        <IonSpinner color="light" />
      </div>
    )}
    <IonAlert
      mode='ios'
      isOpen={showCameraAlert}
      onDidDismiss={() => setShowCameraAlert(false)}
      header="Por favor seleccione"
      message="Indique desde donde subir la imagen"
      buttons={[
        {
          text: 'Camara',
          handler: () => {
            setCameraSource(true);
          },
        },
        {
          text: 'Galería',
          handler: () => {
            setCameraSource(false);
          },
        },
      ]}
    />
  </div>);
};

export default DocExtraInput;
