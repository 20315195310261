export interface ValidationObject {
  value: RegExp;
  message: string;
}

export const validationData: ValidationObject = {
  value: /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ\s,.\/-]+$/,
  message:
    'Este campo solo acepta letras, números y algunos caracteres especiales (como la coma, el punto y la barra "/").',
};

export const validationName: ValidationObject = {
  value: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+$/,
  message: 'Este campo solo acepta letras y espacios.',
};
export const validationPhone: ValidationObject = {
  value: /^(\+?\d{1,3}\s?)?(\d{3}[\s.-]?\d{7}|\d{10})$/,
  message:
    'Introduce un número de teléfono válido (ej. 341-4567890 o 3414567890).',
};
export const ValidationMail: ValidationObject = {
  value: /^\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})\s*$/i,
  message: 'Introduce un correo electrónico válido.',
};
export const validationNumber: ValidationObject = {
  value: /^[0-9]*$/,
  message: 'Este campo solo acepta números.',
};
export const validationMoney: ValidationObject = {
  value: /^[0-9\,/]+$/,
  message: 'Ingrese un monto válido de dinero',
};
