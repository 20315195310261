import React, { useCallback, useEffect, useState } from 'react';
import { App } from '@capacitor/app';
import { IonAlert } from '@ionic/react';
import { useHistory } from 'react-router';
import { HandleBackButtonProps } from './types';

import './HandleBackButton.scss';

type AlertConfig = {
  message: string;
  header: string;
  handler: () => void;
};

const HandleBackButton: React.FC<HandleBackButtonProps> = () => {

  const history = useHistory();

  const [alertConfig, setAlertConfig] = useState<AlertConfig>();
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const unhandledPaths: RegExp[] = [];//[/\/tabs\/siniestros\/menu-siniestro\/.+/]; //ignorar en estos casos, se hace cargo el componente

  useEffect(() => {
    document.addEventListener('ionBackButton', (ev: any) => {
      ev.detail.register(9, handleBackButton);
    });
  }, []);

  const handleBackButton = useCallback(() => {
    if (history) {
      if (unhandledPaths
        .map((pathregex) => {
          console.log('___ testing regex', pathregex, pathregex.test(history.location.pathname));
          return pathregex.test(history.location.pathname)
        })
        .reduce((acc, v) => acc || v, false)) {
        console.log('___ NOT HANDLING');
        return;
      }
      switch (history.location.pathname) {
        case '/login':
          setAlertConfig({
            message: 'Está seguro que desea salir de la aplicación?',
            header: 'Espere',
            handler: () => App.exitApp(),
          });
          setOpenAlert(true);
          break;
        case '/initial-slides':
          history.push('/login');
          break;
        case '/crear-evento/1':
          setAlertConfig({
            message: 'Se perderán los cambios. Está seguro que desea salir?',
            header: '¡Espere!',
            handler: () => history.goBack(),
          });
          setOpenAlert(true);
          break;
        case '/add-pet':
          setAlertConfig({
            message: 'Se perderán los cambios. Está seguro que desea salir?',
            header: '¡Espere!',
            handler: () => history.goBack(),
          });
          setOpenAlert(true);
          break;
        case '/datos-personales':
          setAlertConfig({
            message: 'Se perderán los cambios. Está seguro que desea salir?',
            header: '¡Espere!',
            handler: () => history.goBack(),
          });
          setOpenAlert(true);
          break;
        case '/tabs/home':
          setAlertConfig({
            message: 'Está seguro que desea salir de la aplicación?',
            header: 'Espere',
            handler: () => App.exitApp(),
          });
          setOpenAlert(true);
          break;
        default:
          history.goBack();
          break;
      }
    }
  }, [history]);

  return (
    <IonAlert
      mode='ios'
      cssClass="new-generic-alert"

      isOpen={openAlert}
      onDidDismiss={() => {
        setOpenAlert(false);
      }}

      header={alertConfig?.header}
      message={alertConfig?.message}
      buttons={[
        {
          text: 'Cancelar',
        },
        {
          text: 'Salir',
          handler: alertConfig?.handler,
        },
      ]}
    />
  );
};

export default HandleBackButton;
