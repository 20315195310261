import React, { useState, useEffect, useRef } from 'react';

import { IonButton, IonSpinner } from '@ionic/react';

import { useSiniestro } from '../../context/SiniestroContext';
import { IndicacionesPendientesToSendType } from '../../interfaces/IndicacionesPendientes';
import { SubVetForm } from '../../interfaces/SubVetForm';
import IndicacionEnviada from '../IndicacionEnviada';
import IndicacionPendiente from '../IndicacionPendiente';
import ToastAlert from '../ToastAlert';
import VetFormCompletado from '../VetFormCompletado';
import './Indicaciones.scss';
import { IndicacionesProps } from './types';

const Indicaciones: React.FC<IndicacionesProps> = ({
  indicacionesSeleccionadas,
  setIndicacionesSeleccionadas,
  setOkAlertOpen,
  setFailAlertOpen,
  nombre,
  setShowPopover,
}) => {
  const [pendientesOpen, setPendientesOpen] = useState<boolean>(false);

  const {
    indicaciones: { data: indicaciones },
    siniestro : {data: siniestro}
  } = useSiniestro();

  const ref = useRef<any>();

  function handleEnviadas() {
    const enviadas = indicaciones?.filter((i) => i.estado === 'ENVIADO');
    return enviadas;
  }

  function handlePendientes() {
    const pendientes = indicaciones?.filter((i) => i.estado === 'PENDIENTE');
    return pendientes;
  }

  const completadas =   siniestro?.vet_forms.filter((vf) => vf.estado === "COMPLETADO");

  const submitPendientes = async (e: any) => {
    const addIndicacionesPendientes_toSend: IndicacionesPendientesToSendType = {
      //Construir objeto aqui, se puede tomar la logica de enviar nuevo siniestro,
      //sobre todo para el uso de interfaces
    };
    setShowPopover({ showPopover: true, event: e });
  };

  function scroll() {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    if (indicacionesSeleccionadas.length) {
      setPendientesOpen(true);
    }
  }, [indicacionesSeleccionadas]);

  useEffect(() => {
    scroll();
  }, [indicacionesSeleccionadas?.length]);

  return (
    <div className="indicaciones ion-padding-horizontal">
      {indicaciones ? (
        <>
          <>
            {handleEnviadas()?.map((indicacion: SubVetForm, index: number) => (
              <IndicacionEnviada indicacion={indicacion} key={index} />
            ))}
          </>

          <>
            {handlePendientes()?.map(
              (indicacion: SubVetForm, index: number) => (
                <IndicacionPendiente
                  indicacion={indicacion}
                  indicacionesSeleccionadas={indicacionesSeleccionadas}
                  setIndicacionesSeleccionadas={setIndicacionesSeleccionadas}
                  setOkAlertOpen={setOkAlertOpen}
                  setFailAlertOpen={setFailAlertOpen}
                  nombre={nombre}
                  key={index}
                />
              )
            )}
            <div className="ion-padding-top">
              <IonButton
                ref={ref}
                hidden={!indicacionesSeleccionadas.length}
                expand="block"
                className="ion-margin-top"
                onClick={(e: any) => submitPendientes(e)}
              >
                Enviar Reintegros
              </IonButton>
            </div>
          </>

            {completadas && (
              <>
                {completadas.map((vfComp) =>  <VetFormCompletado key={vfComp.id} vetForm={vfComp} />)}
             </>
            )}
        </>
      ) : (
        <div className="indicaciones__loading-state">
          <IonSpinner color="light" />
        </div>
      )}

      <ToastAlert
        isOpen={pendientesOpen}
        setShowToast={setPendientesOpen}
        message={`${indicacionesSeleccionadas.length !== 1
          ? `${indicacionesSeleccionadas.length} reintegros añadidos para tu veterinario`
          : `Se añadio ${indicacionesSeleccionadas.length} reintegro para tu veterinario`
          }`}
        color="success"
        duration={500}
      />
    </div>
  );
};

export default Indicaciones;
