import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IonDatetime, IonItem, IonLabel, IonToggle } from '@ionic/react';

import { CrearEventoConsultaFormInterface } from '../../../interfaces/CrearEvento';
import {
  maxDateEvent,
  minDateEvent,
  today,
} from '../../../utils/DateUtilities';
import FormItem from '../../FormItem';
import TextBox from '../../TextBox';
import CrearEventoFooter from '../CrearEventoFooter';
import './CrearEventoConsultaForm.scss';
import { CrearEventoConsultaFormProps } from './types';

const CrearEventoConsultaForm: React.FC<CrearEventoConsultaFormProps> = ({
  onSubmit,
  defaultForm,
  onEditMode = false,
  changeReset,
  doReset,
}) => {
  const { control, errors, handleSubmit, reset } = useForm<
    CrearEventoConsultaFormInterface
  >({ defaultValues: defaultForm });

  const [realizado, setRealizado] = useState(
    defaultForm ? defaultForm.done : true
  );

  useEffect(() => {
    reset();
    !changeReset || changeReset(false);
  }, [doReset]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="crear-evento-consulta-form ion-padding">
        {onEditMode ? (
          <>
            <FormItem
              render={({ onChange, value }) => (
                <IonItem className="ion-no-padding">
                  <IonLabel>Fecha</IonLabel>
                  <IonDatetime
                    value={value}
                    displayFormat="DD/MM/YYYY"
                    cancelText="Cancelar"
                    doneText="Ok"
                    onIonChange={onChange}
                    max={maxDateEvent(realizado ?? false)}
                    min={minDateEvent(realizado ?? false)}
                  ></IonDatetime>
                </IonItem>
              )}
              control={control}
              name="fecha"
              errors={errors}
            />

            <FormItem
              render={({ onChange, value }) => (
                <IonItem className="ion-no-padding">
                  <IonLabel>Hora</IonLabel>
                  <IonDatetime
                    value={value}
                    minuteValues="0,15,30,45"
                    displayFormat="HH:mm"
                    pickerFormat="HH:mm"
                    cancelText="Cancelar"
                    doneText="Ok"
                    onIonChange={onChange}
                  ></IonDatetime>
                </IonItem>
              )}
              control={control}
              name="hora"
              errors={errors}
            />

            <div className="ion-margin-bottom">
              <FormItem
                render={() => (
                  <IonItem
                    lines="none"
                    hidden={defaultForm ? defaultForm.done : true}
                    className="ion-no-padding"
                  >
                    <IonLabel>¿Realizado?</IonLabel>
                    <IonToggle
                      mode='ios'
                      checked={realizado}
                      onIonChange={(e) => {
                        setRealizado(e.detail.checked);
                        control.setValue('done', e.detail.checked);
                        control.setValue('fecha', today);
                        control.setValue('hora', today);
                      }}
                    />
                  </IonItem>
                )}
                name="done"
                control={control}
              />
            </div>
          </>
        ) : null}

        <FormItem
          name="description"
          control={control}
          errors={errors}
          render={({ onChange, value, name }) => (
            <>
              <IonLabel>Descripción</IonLabel>
              <TextBox onIonChange={onChange} value={value} name={name} />
            </>
          )}
        />
      </div>
      {onEditMode ? <CrearEventoFooter onEdit={true} /> : <CrearEventoFooter />}
    </form>
  );
};

export default CrearEventoConsultaForm;
