import React from 'react';

import { useSiniestro } from '../../context/SiniestroContext';
import DocExtraInput from '../DocExtraInput';

const FormDocExtra: React.FC<{}> = () => {

   const {
    documentacion: { data: documentacion },
    } = useSiniestro();

  const  documentacionExtra = documentacion?.otros;
  // console.log('formextra render');
  return (
    <div className="form-add-doc-obligatoria ion-padding-start ion-margin-top">
      <span className='form-add-doc-obligatoria__comprobanteCBU'> Documentación extra </span>
      {documentacionExtra && documentacionExtra.map((dE)=>
        <DocExtraInput key={dE.label} doc={dE}/>
        )}
      <DocExtraInput />
      {/* <i className='form-add-doc-obligatoria__detalle-doc-extra'>&#x2734; Puede escribir el detalle del documento  </i> */}
     </div>
  );
};

export default FormDocExtra;
