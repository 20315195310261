import React, { useState } from 'react';
import {
  IonItem,
  IonInput,
  IonButton,
  IonSpinner,
  IonAlert,
  useIonRouter,
} from '@ionic/react';
import { useForm } from 'react-hook-form';

import { RegisterFormProps, RegisterInputs } from './types';
import { useAuth } from '../../hooks';
import FormItem from '../FormItem';
import { useEnter } from '../../hooks/useEnter';

import './RegisterForm.scss';
import { ValidationMail } from '../../assets/validations/validationRegex';

const RegisterForm: React.FC<RegisterFormProps> = () => {
  const history = useIonRouter();
  const { ref, handleEnter } = useEnter();
  const [showAlert, setAlert] = useState(false);
  const [showOkAlert, setOkAlert] = useState(false);
  const { register } = useAuth();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, errors, setError } = useForm<RegisterInputs>();

  const onSubmit = async ({ email, password, confirm }: RegisterInputs) => {
    setLoading(true);
    const res = await register(email.trim(), password, confirm);
    setLoading(false);
    switch (res.status) {
      case 200:
        setOkAlert(true);
        break;
      case 409:
        setError('email', {
          type: 'validate',
          message: 'La cuenta ingresada ya fue registrada',
        });
        break;
      default:
        setAlert(true);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="register-form"
      onKeyUp={handleEnter}
    >
      <FormItem
        errors={errors}
        render={({ onChange }) => (
          <IonItem>
            <IonInput type="email" placeholder="Email" onIonChange={onChange} />
          </IonItem>
        )}
        control={control}
        name="email"
        rules={{
          required: {
            value: true,
            message: 'Por favor, ingrese su email',
          },
          pattern: ValidationMail,
        }}
      />
      <FormItem
        errors={errors}
        render={({ onChange }) => (
          <IonItem>
            <IonInput
              type="password"
              clearOnEdit={false}
              placeholder="Contraseña"
              onIonChange={onChange}
            />
          </IonItem>
        )}
        control={control}
        name="password"
        rules={{
          required: {
            value: true,
            message: 'Por favor, ingrese su contraseña',
          },
          minLength: {
            value: '6',
            message: 'La contraseña debe tener una longitud minima de 6',
          },
        }}
      />

      <FormItem
        errors={errors}
        render={({ onChange }) => (
          <IonItem>
            <IonInput
              clearOnEdit={false}
              type="password"
              placeholder="Confirmar contraseña"
              onIonChange={onChange}
            />
          </IonItem>
        )}
        control={control}
        name="confirm"
        rules={{
          required: {
            value: true,
            message: 'Por favor, confirme su contraseña',
          },
          validate: (value: any) => {
            if (!value || control.getValues('password') === value) {
              return true;
            }
            return 'Las contraseñas no son iguales';
          },
        }}
      />
      <div className='ion-margin-top'>
        <IonButton ref={ref} className="register-form__button" type="submit" mode='ios'>
          {loading && (
            <IonSpinner
              name="crescent"
              className="register-form__loading-spinner"
              slot="end"
            />
          )}
          Registrate!
        </IonButton>
      </div>

      <IonAlert
        mode='ios'
        isOpen={showAlert}
        onDidDismiss={() => setAlert(false)}
        header={'Error'}
        message={'El servidor se encuentra en mantenimiento'}
        buttons={['Cancel', 'Ok']}
      />
      <IonAlert
        mode='ios'
        isOpen={showOkAlert}
        onDidDismiss={() => {
          history.push('/login','forward','push');
        }}

        header={'¡ Éxito !'}
        message={'Tu cuenta se registró correctamente'}
        buttons={['Iniciar sesión']}
      />
    </form>
  );
};

export default RegisterForm;
