import useSWR from 'swr';

import { GET_VACUNA } from '../axios/constants';
import { getVacuna } from '../axios/getVacunas';
import { useEffect, useState } from 'react';
import { Animal } from '../interfaces/Pet';
import { Vacunas } from '../interfaces/Vacuna';
import {useMascotas} from '../context/MascotasContext';

export const useGetVacunas = (id: string) => {
  const [vacunasFiltered, setVacunasFiltered] = useState<Vacunas>();
  const {mascotas:{pets}} = useMascotas();
  const pet = pets?.find((m)=> m.id.toString() === id);

  const { data, mutate, error } = useSWR(GET_VACUNA, getVacuna, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  const filterVacunas = (animal?: Animal) => {
    const newVacunas = animal
      ? data?.filter((vacuna) => vacuna.animal === animal)
      : undefined;
    setVacunasFiltered(newVacunas);
  };

  useEffect(() => {
    filterVacunas(pet?.animal);
  }, [data, pet]);

  return { vacunas: data, vacunasFiltered, filterVacunas, mutate, error };
};
