import React, { useRef, useState } from 'react';

import { IonSegment, IonSegmentButton, IonLabel, IonAlert } from '@ionic/react';

import { useSiniestro } from '../../context/SiniestroContext';
import FormDocObligatoriaInput from '../FormDocObligatoriaInput';
import './FormAddDocObligatoria.scss';
import { FormAddDocObligatoriaProps } from './types';

const FormAddDocObligatoria: React.FC<FormAddDocObligatoriaProps> = () => {
  const [CBU_Type, setCBU_Type] = useState<string>('propio');
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const {
    documentacion: { data: documentacion },
  } = useSiniestro();

  const bottomRef = useRef<any>();

  return (
    <div className="form-add-doc-obligatoria ion-padding-start">
      {documentacion && (
        <>
          <FormDocObligatoriaInput
            doc={documentacion ? documentacion.dniFrente : null}
            type="dni_frente"
          />

          <FormDocObligatoriaInput
            doc={documentacion ? documentacion.dniDorso : null}
            type="dni_dorso"
          />

          <FormDocObligatoriaInput
            doc={documentacion ? documentacion.libretaVacunacion : null}
            type="libreta_vacunacion"
          />
          <div className="ion-margin-top">
            <span className="form-add-doc-obligatoria__comprobanteCBU">
              Comprobante de CBU
            </span>
            <IonSegment
              className="ion-no-margin"
              value={CBU_Type}
              mode="md"
              onIonChange={(e) => setCBU_Type(e.detail.value!)}
            >
              <IonSegmentButton
                disabled
                value="laCuentaEs"
                className="form-add-doc-obligatoria__comprobanteCBU-label"
              >
                <IonLabel>Cuenta</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="propio">
                <IonLabel>Propia</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="tercero"
                onClick={(e) => {
                  bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
                  e.persist();
                  setShowAlert((prevState) => !prevState);
                }}
              >
                <IonLabel>De tercero</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {CBU_Type === 'propio' ? (
              <FormDocObligatoriaInput
                doc={documentacion ? documentacion.cbu : null}
                type="cbu"
              />
            ) : (
              <>
                <FormDocObligatoriaInput
                  doc={documentacion ? documentacion.terceroCbu : null}
                  type="tercero_cbu"
                />
                <FormDocObligatoriaInput
                  doc={documentacion ? documentacion.constancia : null}
                  type="constancia"
                />
              </>
            )}
            <div ref={bottomRef} />

            <IonAlert
              mode='ios'
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              header="Cuenta de tercero"
              subHeader="Mensaje importante"
              message="El titular de la póliza debe firmar una nota autorizando al tercero (nombre, apellido y CUIL/DNI) a recibir el reintegro."
              buttons={['Entendido']}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FormAddDocObligatoria;
