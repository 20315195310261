import React from 'react';

import {
  IonButton,
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
} from '@ionic/react';
import 'animate.css';
import moment from 'moment';

import { isOdd } from '../../utils/General';
import { sortPesajesByDate } from '../../utils/PetUtilities';
import NavigationBar from '../NavigationBar';
import './WeightHistoryModal.scss';
import { WeightHistoryModalProps } from './types';


const WeightHistoryModal: React.FC<WeightHistoryModalProps> = ({
  isOpen,
  setOpen,
  weightHistory,
}) => {

  return (
    <IonModal
      mode="ios"
      isOpen={isOpen}
      onDidDismiss={() => setOpen(false)}
      cssClass="weight-history-modal animate__animated animate__zoomIn"
      backdropDismiss
    >
      <NavigationBar hideBack={true} />
      <IonContent>
        <IonGrid className="ion-margin">
          <IonRow>
            <IonCol>
              <span className="weight-history-modal__title-span">
                Fecha de pesaje
              </span>
            </IonCol>
            <IonCol>
              <span className="weight-history-modal__title-span">Peso</span>
            </IonCol>
          </IonRow>
          {weightHistory
            .sort(sortPesajesByDate)
            .reverse()
            .map(({ fecha_pesaje, peso }, index) => (
              <IonRow
                key={index}
                className={isOdd(index) ? 'weight-history-modal__odd-row' : ''}
              >
                <IonCol>{moment(fecha_pesaje).format('DD/MM/YY')}</IonCol>
                <IonCol>{`${peso} kg`}</IonCol>
              </IonRow>
            ))}
        </IonGrid>
      </IonContent>
      <div className="ion-padding">
        <IonButton mode="ios" expand="block" onClick={() => setOpen(false)}>
          Cerrar
        </IonButton>
      </div>
    </IonModal>
  );
};

export default WeightHistoryModal;
