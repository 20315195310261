import { AxiosResponse } from 'axios';

import axiosInstance from '.';
import { EVENTO } from './constants';
import { CrearEventoFormInterface } from '../interfaces/CrearEvento';
import { Evento } from '../interfaces/Evento';

export const postEvent = async (form: CrearEventoFormInterface) => {
  try {
    await axiosInstance.post(EVENTO, form);
  } catch (e) {
    console.error(e.response ?? e);
    throw e;
  }
};

export const putEvent = async (id: string, data: CrearEventoFormInterface) => {
  try {
    await axiosInstance.put(EVENTO + '/' + id, data);
    return true;
  } catch (e) {
    console.error(e.response ?? e);
    return false;
  }
};

export const delEvent = async (id: string) => {
  try {
    await axiosInstance.delete(EVENTO + '/' + id);
    return true;
  } catch (e) {
    console.error(e.response ?? e);
    return false;
  }
};

export const getOneEvent = async (id: string) => {
  try {
    const { data: evento }: AxiosResponse<Evento> = await axiosInstance.get(
      EVENTO + '/' + id
    );
    return evento;
  } catch (e) {
    console.error(e.response ?? e);
    throw e;
  }
};
