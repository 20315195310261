import React, { useState } from 'react';

import { IonIcon, useIonRouter } from '@ionic/react';
import { add } from 'ionicons/icons';

import { useUserSession } from '../../hooks';
import PersonalDataAlert from '../PersonalDataAlert';
import './AddPetButton.scss';
import { AddPetButtonProps } from './types';

const AddPetButton: React.FC<AddPetButtonProps> = () => {
  const history = useIonRouter();
  const { hasPersonalData } = useUserSession();
  const [isAlertOpen, setAlertOpen] = useState<boolean>(false);

  const onClick = () =>
    hasPersonalData ? clickWithPersonalData() : clickWithoutPersonalData();

  const clickWithPersonalData = () => {
    history.push('/add-pet','forward','push');
  };
  const clickWithoutPersonalData = () => {
    setAlertOpen(true);
  };

  return (
    <div data-tut="reactour__add_pet_button">
      <div className="add-pet-button__icon ion-margin-bottom">
        <IonIcon icon={add} color="primary" onClick={onClick} />
      </div>

      {isAlertOpen && (
        <PersonalDataAlert isOpen={isAlertOpen} setOpen={setAlertOpen} />
      )}
    </div>
  );
};

export default AddPetButton;
