import React from 'react';
import { Link } from 'react-router-dom';
import { IonPage } from '@ionic/react';

import RegisterForm from '../../components/RegisterForm';
import AuthView from '../../components/AuthView';

import './RegisterPage.scss';

const RegisterPage: React.FC = () => {
  return (
    <IonPage>
      <AuthView src="assets/img/background/Perro1.png">
        <RegisterForm />
        <Link to="/login" className="register-page-link">
          ¿Tenés una cuenta? - Iniciá sesíon
        </Link>
      </AuthView>
    </IonPage>
  );
};

export default RegisterPage;
