import { AxiosResponse } from 'axios';

import axios from '.';
import { AddPetReq } from '../interfaces/AddPet';
import { Pet } from '../interfaces/Pet';
import { PETS } from './constants';

export const getPets = async (url: string) => {
  try {
    const { data: pets }: AxiosResponse<Pet[]> = await axios.get(url);
    return pets;
  } catch (error) {
    console.error(error);
  }
};

export const getPet = async (url: string, id: string) => {
  try {
    const { data: pet }: AxiosResponse<Pet> = await axios.get(url);
    return pet;
  } catch (error) {
    console.error(error);
  }
};

export const postPet = async ({
  datos_mascota,
  datos_pago,
  codigo_vendedor,
}: AddPetReq): Promise<boolean> => {
  try {
    await axios.post(PETS, {
      datos_mascota,
      datos_pago,
      codigo_vendedor,
    });
    return true;
  } catch (e) {
    console.error(e.response);
    return false;
  }
};
