import React from 'react';

import { IonButton, IonSpinner, useIonRouter } from '@ionic/react';

import { useGetSiniestros } from '../../hooks/useSiniestros';
import { Siniestro } from '../../interfaces/Siniestro';
import SiniestroCard from '../SiniestroCard';
import './Siniestros.scss';
import { SiniestrosProps } from './types';

const Siniestros: React.FC<SiniestrosProps> = ({ petId, sexo }) => {
  const { push } = useIonRouter();
  const { siniestros, isValidating, error } = useGetSiniestros(petId);
  if (isValidating)
    return (
      <div className="siniestros__loading-state">
        <IonSpinner color="light" />
      </div>
    );
  else if (error && !siniestros) {
    return <div>error</div>;
  }

  return (
    <div className="siniestros">
      {siniestros &&
        siniestros.map((siniestro: Siniestro, index: number) => (
          <SiniestroCard key={index} siniestro={siniestro} sexo={sexo!} />
        ))}

      {!siniestros?.length && <IonButton mode='ios' className='ion-margin' onClick={() => push(`/tabs/siniestros/new/${petId}`,'forward','push')}>Nuevo accidente o enfermedad</IonButton>}
    </div>
  );
};

export default Siniestros;
