import { login as axiosLogin } from '../axios/login';
import { useStorage } from './useStorage';
import { register as axiosRegister } from '../axios/register';
import { recoverPass as axiosRecovery } from '../axios/password-recovery';
import { useNotification } from './useNotification';
import { postLogout } from '../axios/logout';
import { getPersonalData as axiosGetPersonalData } from '../axios/getPersonalData';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export const useAuth = () => {
  const { setAuthStatus } = useContext(AuthContext);
  const { setValue, clearValue, getValue } = useStorage();
  const { clearNotificationToken, getNotificationToken } = useNotification();
  const login = async (email: string, password: string) => {
    const { ok, data } = await axiosLogin({ email, password });
    if (ok) {
      await setValue('token', data?.token);
      setAuthStatus(true);
      await setValue('refreshToken', data?.refreshToken);
      await setValue('USER_MAIL', email);
      const personalData = await axiosGetPersonalData();
      if (personalData == null) {
        await setValue('USER_NAME', '');
      } else {
        await setValue(
          'USER_NAME',
          personalData.nombre + ' ' + personalData.apellido
        );
      }
    }
    return ok;
  };

  const register = async (email: string, password: string, confirm: string) => {
    const { status } = await axiosRegister({ email, password, confirm });
    return status;
  };

  const recoverPassword = async (email: string) => {
    const { ok } = await axiosRecovery({ email });
    return ok;
  };

  const logout = async () => {
    const refreshToken = (await getValue('refreshToken')) ?? '';
    const firebase_token = (await getNotificationToken()) ?? '';
    await postLogout({ refreshToken, firebase_token });
    await clearNotificationToken();
    await clearValue('token');
    setAuthStatus(false);
    await clearValue('refreshToken');
    await clearValue('USER_NAME');
    await clearValue('USER_MAIL');
  };

  return { login, register, recoverPassword, logout };
};