import React from 'react';
import { useForm } from 'react-hook-form';
import {
  IonButton,
  IonItem,
  IonInput,
  IonLabel,
  IonDatetime,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';

import { useEnter } from '../../hooks/useEnter';
import { mustBeAdult } from '../../utils/DateUtilities';
import { capitalizeEveryWord } from '../../utils/General';

import { PersonalDataFormProps, PersonalDataFormInputs } from './types';
import './PersonalDataForm.scss';

import FormItem from '../FormItem';

const PersonalDataForm: React.FC<PersonalDataFormProps> = ({
  actualValues,
  liftValues,
  nextForm,
}) => {
  const initialFormValues:
    | PersonalDataFormInputs
    | undefined = actualValues && {
      name: actualValues.nombre,
      surname: actualValues.apellido,
      gender: actualValues.genero,
      birthdate: actualValues.fecha_nacimiento,
      dni: actualValues.dni,
      cellphone: actualValues.tel_celular,
    };

  const { control, handleSubmit, errors } = useForm<PersonalDataFormInputs>({
    defaultValues: initialFormValues,
  });

  const { ref: enterRef, handleEnter } = useEnter();

  const onSubmit = ({
    name,
    surname,
    gender,
    birthdate,
    cellphone,
    dni,
  }: PersonalDataFormInputs) => {
    liftValues({
      dni,
      nombre: name,
      apellido: surname,
      genero: gender,
      fecha_nacimiento: birthdate,
      tel_celular: cellphone,
    });
    nextForm();
  };

  return (
    <div className='ion-padding-horizontal'>
      <form
        className="personal-data-form ion-padding"
        onSubmit={handleSubmit(onSubmit)}
        onKeyUp={handleEnter}
      >
        <div className="personal-data-form__title ion-margin-vertical">
          Datos personales
        </div>

        <FormItem
          errors={errors}
          render={({ value, onChange }) => (
            <IonItem mode="ios" className='ion-no-padding'>
              <IonLabel position="floating">
                Nombre
              </IonLabel>
              <IonInput
                value={capitalizeEveryWord(value)}
                onIonChange={onChange}
              />
            </IonItem>
          )}
          control={control}
          name="name"
          rules={{
            required: {
              value: true,
              message: 'Por favor, ingrese su nombre',
            },
          }}
        />

        <FormItem
          errors={errors}
          render={({ value, onChange }) => (
            <IonItem mode="ios" className='ion-no-padding'>
              <IonLabel position="floating">
                Apellido
              </IonLabel>
              <IonInput
                value={capitalizeEveryWord(value)}
                onIonChange={onChange}
              />
            </IonItem>
          )}
          control={control}
          name="surname"
          rules={{
            required: {
              value: true,
              message: 'Por favor, ingrese su apellido',
            },
          }}
        />

        <FormItem
          errors={errors}
          render={({ value, onChange }) => (
            <IonItem mode="ios" className='ion-no-padding'>
              <IonLabel position="floating">
                DNI
              </IonLabel>
              <IonInput
                value={value}
                type="number"
                onIonChange={onChange}
              />
            </IonItem>
          )}
          control={control}
          name="dni"
          rules={{
            required: {
              value: true,
              message: 'Por favor, ingrese su número de documento',
            },
            minLength: {
              value: 8,
              message: 'Su número de documento debe tener 8 dígitos',
            },
            maxLength: {
              value: 8,
              message: 'Su número de documento debe tener 8 dígitos',
            },
          }}
        />
        
        <FormItem
          errors={errors}
          render={({ value, onChange }) => (
            <IonItem mode="ios" className='ion-no-padding'>
              <IonLabel position="floating">
                Teléfono
              </IonLabel>
              <IonInput
                value={value}
                type="number"
                onIonChange={onChange}
              />
            </IonItem>
          )}
          control={control}
          name="cellphone"
          rules={{
            required: {
              value: true,
              message: 'Por favor, ingrese su número de celular',
            },
            minLength: {
              value: 10,
              message:
                'Por favor, ingrese el número con el código de área sin el 0 y sin el 15',
            },
            maxLength: {
              value: 10,
              message:
                'Por favor, ingrese el número con el código de área sin el 0 y sin el 15',
            },
          }}
        />

        <FormItem
          errors={errors}
          render={({ value, onChange }) => (
            <IonItem mode="ios" className='ion-no-padding'>
              <IonLabel position="floating">
                Fecha de nacimiento
              </IonLabel>
              <IonDatetime
                value={value}
                onIonChange={onChange}
                max={mustBeAdult}
                displayFormat="DD/MM/YYYY"
                cancelText="Cancelar"
                doneText="Aceptar"
              />
            </IonItem>
          )}
          control={control}
          name="birthdate"
          rules={{
            required: {
              value: true,
              message: 'Por favor, ingrese su fecha de nacimiento',
            },
          }}
        />
        
        <div className='ion-margin-vertical'>
          <FormItem
            errors={errors}
            render={({ value, onChange }) => (
              <IonItem mode="ios" className='ion-no-padding'>
                <IonLabel>Sexo</IonLabel>
                <IonRadioGroup
                  value={value}
                  className="personal-data-form__radio-group"
                  onIonChange={onChange}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <IonItem lines="none">
                      <IonLabel>F </IonLabel>
                      <IonRadio slot="end" value="F" />
                    </IonItem>
                    <IonItem lines="none">
                      <IonLabel>M </IonLabel>
                      <IonRadio slot="end" value="M" />
                    </IonItem>
                  </div>
                </IonRadioGroup>
              </IonItem>
            )}
            control={control}
            name="gender"
            rules={{
              required: {
                value: true,
                message: 'Por favor, ingrese el sexo',
              },
            }}
          />
        </div>

        <div
          className=' personal-data-form__button-container ion-margin-top'
        >
          <IonButton ref={enterRef} type="submit" mode="ios" expand='block'>
            Siguiente
          </IonButton>
        </div>
      </form>
    </div>
  );
};

export default PersonalDataForm;
