import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { FoodRes } from '../interfaces/Food';
import { ADD_FOOD } from '../axios/constants';
import { getFoodPets as axiosGetFoodPets } from '../axios/food';

export const useNutrition = () => {
  const [selectedFood, setSelected] = useState<FoodRes | undefined>();

  const { data: foods, mutate, isValidating } = useSWR(ADD_FOOD, axiosGetFoodPets,{revalidateOnFocus:false});

  useEffect(() => {
    if (foods && foods.length > 0) {
      setSelected(foods[0]);
    }
  }, [foods]);

  return { foods, selectedFood, mutate, isValidating };
};
