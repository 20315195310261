import axiosInstance from '.';
import { RecoveryReq } from '../interfaces/PassRecovery';
import { RECOVERYPASS } from './constants';

export const recoverPass = async ({
  email,
}: RecoveryReq): Promise<{ ok: boolean }> => {
  try {
    await axiosInstance.post(RECOVERYPASS, {
      email,
    });
    return { ok: true };
  } catch (e) {
    console.error(e);
    return { ok: false };
  }
};
