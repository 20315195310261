import React from 'react';
import { IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/react';

import { PageRefresherProps } from './types';
import './PageRefresher.scss';

const PageRefresher: React.FC<PageRefresherProps> = ({ doRefresh }) => {
  return (
    <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
      <IonRefresherContent refreshingSpinner={null}>
        <div className="page-refresher">
          <IonSpinner name="circular" color="primary" />
        </div>
      </IonRefresherContent>
    </IonRefresher>
  );
};

export default PageRefresher;
