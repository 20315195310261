import React, { useContext, useEffect } from 'react';

import {
  IonToolbar,
  IonRouterOutlet,
  IonPage,
  IonHeader,
  IonContent,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

import ExitComponent from '../../components/ExitComponent';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import { CrearEventoFormContext } from '../../context/CrearEventoContext';
import CrearEventoPage from '../crear-evento/1';
import CrearEventoPage2 from '../crear-evento/2';
import './1/CrearEventoPage.scss';
import { CrearEventoProps } from './types';

const CrearEvento: React.FC<CrearEventoProps> = ({ history }) => {
  const { setCrearEventoFormValues } = useContext(CrearEventoFormContext);
  useEffect(() => history.replace('/crear-evento/1'), []);

  return (
    <IonPage className="crear-evento-page">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <ExitComponent
            icon={chevronBackOutline}
            title={'Agendar Evento'}
            showAlert={history.location.pathname === '/crear-evento/1'}
            exitTo="/tabs/historial"
            action={() => setCrearEventoFormValues(undefined)}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRouterOutlet>
          <PrivateRoute
            exact
            path="/crear-evento/1"
            component={CrearEventoPage}
          />
          <PrivateRoute
            exact
            path="/crear-evento/2"
            component={CrearEventoPage2}
          />
        </IonRouterOutlet>
      </IonContent>
    </IonPage>
  );
};

export default CrearEvento;
