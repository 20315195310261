import React, { createContext, useContext, useEffect } from 'react';
import {AuthContext} from './AuthContext';
import { useUserData } from '../hooks/useUserData';

class UserContextType {
  constructor(){
    this.user = useUserData();
  };
  user : ReturnType<typeof useUserData>;

};
const UserContext = createContext<UserContextType | null>(null);

export const UserContextProvider: React.FC = ({children}) => {
  const userContextData = new UserContextType();
  const {authStatus} = useContext(AuthContext);

  useEffect(()=>{
    userContextData.user.mutate();
  },[authStatus]);

  return (
    <UserContext.Provider value={userContextData}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser: () => UserContextType = () => {
  const userContext = useContext(UserContext);
  if (!userContext) throw Error('User context unavailable');
  return userContext;
};
