import React from 'react';

import { IonPage, IonContent } from '@ionic/react';

import Food from '../../assets/pet-food2.png';
import AddFoodForm from '../../components/AddFoodForm';
import NavigationBar from '../../components/NavigationBar';
import './NutritionAddPage.scss';

const NutritionAddPage: React.FC = () => {
  return (
    <IonPage className="nutrition-add-page">
      <NavigationBar goBack={false} path="alimentacion"/>
      <IonContent className="ion-padding">
        <div className='nutrition-add-page__heading ion-margin-vertical ion-margin-end'>
          <h3 className="ion-margin-start">Agregar alimento</h3>
          <img src={Food} />
        </div>
        <AddFoodForm />
      </IonContent>
    </IonPage>
  );
};

export default NutritionAddPage;
