import { useState } from 'react';
import { mutate } from 'swr';

import { PersonalDataReq, AddressDataReq } from '../interfaces/PersonalData';
import { sendPersonalData } from '../axios/sendPersonalData';
import { PERSONAL_DATA } from '../axios/constants';
import { useStorage } from './useStorage';
import { getPersonalData as axiosGetPersonalData } from '../axios/getPersonalData';

export const useSubmitPersonalData = () => {
  const { setValue } = useStorage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedOk, setSubmittedOk] = useState(false);
  const [submittedWrong, setSubmittedWrong] = useState(false);

  const submitPersonalData = async (
    personalData: PersonalDataReq,
    addressData: AddressDataReq
  ) => {
    setIsSubmitting(true);
    const ok = await sendPersonalData(personalData, addressData);

    setIsSubmitting(false);
    if (ok) {
      setValue('USER_NAME', `${personalData.nombre} ${personalData.apellido}`);
      setSubmittedOk(true);
      mutate(PERSONAL_DATA);
      const newPersonalData = await axiosGetPersonalData();
      setValue('USER_MAIL', `${newPersonalData.email}`);
    } else {
      setSubmittedWrong(true);
    }
  };

  return {
    isSubmitting,
    submittedOk,
    setSubmittedOk,
    submittedWrong,
    setSubmittedWrong,
    submitPersonalData,
  };
};
