import React from 'react';

// import { isDesktop } from '../../hooks/usePlatform';
// import WhatsAppLogo from '../../assets/whatsapp-icon.svg';
import { VideoLlamadaFormProps } from './types';
import { getDate, getHour } from '../../utils/DateUtilities';

import './VideoLlamadaForm.scss';

const VideoLlamadaForm: React.FC<VideoLlamadaFormProps> = ({ defaultForm }) => {
  // const whatsAppLink = isDesktop
  //   ? 'https://web.whatsapp.com/send?phone=549' + defaultForm?.telefono
  //   : 'https://api.whatsapp.com/send?phone=549' + defaultForm?.telefono;
  return (
    <>
      <div className="video-llamada-form__info">
        <div className="video-llamada-form__verde">¿Día?</div>
        <div className="video-llamada-form__black">
          {getDate(defaultForm?.fecha)}
        </div>
      </div>

      <div className="video-llamada-form__info">
        <div className="video-llamada-form__verde">¿Hora?</div>
        <div className="video-llamada-form__black">
          {getHour(defaultForm?.hora)} hs
        </div>
      </div>

      <div className="video-llamada-form__info">
        <div className="video-llamada-form__verde">¿Con quién?</div>
        <div className="video-llamada-form__black">
          {defaultForm?.infoVeterinario}
        </div>
      </div>
      <div className="video-llamada-form__block">
        El veterinario se comunicará con usted en el día y hora indicados.
        <br />
        <br />
        Ante cualquier inconveniente puede llamar al mismo número donde solicitó
        el turno (0800-888-7994).
      </div>
      {/* <a href={whatsAppLink} className="video-llamada-form__whatsapp">
        <span className="video-llamada-form__whatsapp__text">
          Comunicate con el veterinario
        </span>
        <span>
          <img
            className="video-llamada-form__whatsapp__img"
            src={WhatsAppLogo}
          ></img>
        </span>
      </a> */}
    </>
  );
};

export default VideoLlamadaForm;
