import React, { createContext, useEffect, useState } from 'react';
import { IonIcon, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { calendarSharp, home, medkit, paw, settings } from 'ionicons/icons';
import { getPersonalData as axiosGetPersonalData } from '../../axios/getPersonalData';
import AddSiniestroPage from '../add-siniestro';
import AllSiniestrosPage from '../all-siniestros';
import Confirm from '../confirm-page';
import EditEventPage from '../edit-event';
import Home from '../home';
import MenuSiniestroPage from '../menu-siniestro';
import NotFound from '../../components/NotFound';
import NutritionAddPage from '../nutrition-add';
import NutritionPage from '../nutrition';
import PersonalDataPage from '../PersonalData';
import addPetPage from '../add-pet/index';
import Pets from '../pets';
import PetDetailsPage from '../pet-details';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import SettingsPage from '../settings';
import ShowSiniestrosPage from '../show-siniestros';
import SyncPetsPage from '../sync-pets';
import TimelinePage from '../timeline';
import TutorialBasic from '../../components/TutorialBasic';
import VetCallPage from '../vet-call/index';
import withNotification from '../../hocs/withNotification';
import { useStorage } from '../../hooks';
import nutricionalInfoPage from '../nutricional-info/index';
import BajaCoberturaPage from '../baja-cobertura';

import './TabsPage.scss';

export const SetLoadingContext = createContext<(value: boolean) => void>(
  () => { }
);
const TabsPage: React.FC = () => {
  const { getValue, setValue } = useStorage();
  const [globalIsLoading, setGlobalIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getValue('USER_NAME').then(async (rta) => {
      if (rta === null) {
        const personalData = await axiosGetPersonalData();
        if (personalData == null) {
          await setValue('USER_NAME', '');
          await setValue('USER_MAIL', '');
        } else {
          await setValue(
            'USER_NAME',
            personalData.nombre + ' ' + personalData.apellido
          );
          await setValue('USER_MAIL', personalData.email);
        }
      }
    });
  }, [getValue, setValue, axiosGetPersonalData]);

  return (
    <IonPage>
      <SetLoadingContext.Provider value={setGlobalIsLoading}>
        <IonTabs className="tabs-page">
          <IonRouterOutlet key="tabsoutlet" id='tabsoutlet' basePath='/tabs/'>
            <PrivateRoute exact path="/tabs/home" component={Home} />
            <PrivateRoute exact path="/tabs" component={Home} />

            <PrivateRoute path="/tabs/personal-data/:id?" component={PersonalDataPage} />
            <PrivateRoute path="/tabs/edit-pet/:id?" component={addPetPage} />
            <PrivateRoute path="/tabs/home/vet-call" component={VetCallPage} />

            <PrivateRoute
              exact
              path="/tabs/pet-details/:id"
              component={PetDetailsPage}
            />

            <PrivateRoute
              exact
              path="/tabs/siniestros"
              component={AllSiniestrosPage} />

            <PrivateRoute
              exact
              path="/tabs/siniestros/new/:id"
              component={AddSiniestroPage}
            />

            <PrivateRoute
              exact
              path="/tabs/siniestros/show-siniestros/:id"
              component={ShowSiniestrosPage}
            />

            <PrivateRoute
              exact
              path="/tabs/siniestros/menu-siniestro/:id"
              component={MenuSiniestroPage}
            />

            <PrivateRoute
              exact
              path="/tabs/siniestros/pets"
              component={Pets}
            />

            <PrivateRoute
              exact
              path="/tabs/historial"
              component={TimelinePage}
            />

            <PrivateRoute
              path="/tabs/historial/edit-event/:id"
              component={EditEventPage}
            />

            <PrivateRoute
              exact
              path="/tabs/alimentacion"
              component={NutritionPage}
            />

            <PrivateRoute
              exact
              path="/tabs/alimentacion/agregar"
              component={NutritionAddPage}
            />

            <PrivateRoute
              exact
              path="/tabs/nutricional-info/:id"
              component={nutricionalInfoPage}
            />

            <PrivateRoute
              exact
              path="/tabs/ajustes"
              component={SettingsPage}
            />

            <PrivateRoute
              exact
              path="/tabs/ajustes/asociar"
              component={SyncPetsPage}
            />

            <PrivateRoute exact path="/tabs/confirm" component={Confirm} />

            <PrivateRoute path="/tabs/baja-cobertura" component={BajaCoberturaPage} />

            <PrivateRoute path="/not-found" component={NotFound} />
          </IonRouterOutlet>

          <IonTabBar slot="bottom">
            <IonTabButton data-tut="reactour__home"
              tab="home"
              href="/tabs/home"
              routerOptions={{ unmount: true }}
              disabled={globalIsLoading}
            >
              <IonIcon icon={home} />
            </IonTabButton>

            <IonTabButton
              data-tut="reactour__historial"
              tab="speakers"
              href="/tabs/historial"
              routerOptions={{ unmount: true }}
              disabled={globalIsLoading}
            >
              <IonIcon icon={calendarSharp} />
            </IonTabButton>

            <IonTabButton
              data-tut="reactour__alimentacion"
              tab="nutrition"
              href="/tabs/alimentacion"
              routerOptions={{ unmount: true }}
              disabled={globalIsLoading}
            >
              <IonIcon icon={paw} />
            </IonTabButton>

            <IonTabButton
              tab="siniestros"
              href="/tabs/siniestros"
              routerOptions={{ unmount: true }}
              disabled={globalIsLoading}
            >
              <IonIcon icon={medkit} />
            </IonTabButton>

            <IonTabButton
              data-tut="reactour__add-settings"
              tab="settings"
              href="/tabs/ajustes"
              routerOptions={{ unmount: true }}
              disabled={globalIsLoading}
            >
              <IonIcon icon={settings} />
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </SetLoadingContext.Provider>
      <TutorialBasic />
    </IonPage>
  );
};
export default withNotification(TabsPage);
