import React from 'react';

import { IonCol, IonGrid, IonIcon, IonRow, IonText, useIonRouter } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import moment from 'moment';
import Healht from '../../assets/hospital.png';
import './SiniestroCard.scss';
import { SiniestroCardProps } from './types';
import { capitalizeFirstLetter } from '../../utils/General';

const SiniestroCard: React.FC<SiniestroCardProps> = ({ siniestro, pets, allSiniestros }) => {
  const { push } = useIonRouter();

  const {
    id: siniestroId,
    fecha_carga,
    denuncia_administrativa: DA,
    mascotaId: petId,
  } = siniestro;

  const nombre = pets && pets.filter((e) => e.id === Number(petId))[0]?.nombre;

  const length = 60;

  const date = moment(fecha_carga).format('DD/MM/YYYY');

  const denuncia =
    DA && DA.length > length ? DA.substring(0, length - 3) + '...' : DA;

  return (
    <div
      className="siniestro-card ion-margin"
      onClick={() => push(`/tabs/siniestros/menu-siniestro/${siniestroId}`, 'forward', 'push')}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="2.5" className="siniestro-card__pic">
            <img src={Healht} />
          </IonCol>
          <IonCol size="8" className="siniestro-card__denuncia">
            <div>
              {pets && allSiniestros && <p className='aux-mb'>{nombre}</p>}
              <p className="siniestro-card__date">{date}</p>
              <IonText> {capitalizeFirstLetter(denuncia)}.</IonText>
            </div>
          </IonCol>
          <IonCol size="1.5">
            <IonIcon icon={chevronForwardOutline} color="primary" />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default SiniestroCard;
