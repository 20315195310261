import useSWR from 'swr';

import { GET_INDICACIONES } from '../axios/constants';
import { getIndicaciones } from '../axios/getIndicaciones';

export const useGetIndicaciones = (id: string) => {
  const { data, isValidating, error, mutate } = useSWR(
    [GET_INDICACIONES,id],
    (url) => getIndicaciones(url, id),
    {revalidateOnFocus:false,dedupingInterval:1500},
  );

  return { data, isValidating, error, mutate };
};
