import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IonContent, useIonRouter, useIonViewWillLeave, useIonViewDidEnter, useIonViewWillEnter, useIonViewDidLeave } from '@ionic/react';
import { useMascotas } from '../../context/MascotasContext';
import { useSiniestro } from '../../context/SiniestroContext';
import ConsultasIndicaciones from '../../components/ConsultasIndicaciones';
import DocumentacionObligatoria from '../../components/DocumentacionObligatoria';
import Movimientos from '../../components/Movimientos';
import NavigationBar from '../../components/NavigationBar';
import SiniestroInfo from '../../components/SiniestroInfo';
import SiniestroMenu from '../../components/SiniestroMenu';
import CustomIonBackButtonWrapper from '../../components/CustomIonBackButtonWrapper';


const MenuSiniestroWrapper : React.FC = () =>{
  const {siniestro:{data:siniestro}} = useSiniestro();
  const {mascotas:{pets}} = useMascotas();
  const pet  = pets?.find((m)=>m.id.toString() === siniestro?.mascotaId.toString());
  const [step, setStep] = useState<number>(-1);
  const history = useIonRouter();

  /**
   * @NOTE: toda la logica de forzar el desregistro con seteando step en -1 esta un poco craneo ,
   * pero por algun motivo queda registrado esto y habia que deployar.
   */

  const customHandleBackButton = useMemo<()=>void>( () => (()=> {
    setStep(0)
  }),[setStep]);
  const normalHandleBackButton = useMemo(() => (() => {
      history.push(pet?`/tabs/siniestros/show-siniestros/${pet.id}`:'/tabs/home','back','pop')
  }),[pet,setStep]);

  // inicializar en estado al mostrar 0
  useIonViewDidEnter(()=>{
    if (step !== 0){
      setStep(0)
    }
  },[step]);

  // Forzar desmonte de todos los componentes para que desregistren su handler de ionbackbutton
  useIonViewDidLeave(()=>{
    if(step !== -1){
      setStep(-1);
    }
  },[step]);

  function switchStep() {
    switch (step) {
      case -1:
        return <> </>
      case 0:
        if (pet) return (
          <CustomIonBackButtonWrapper
          onBackButtonRef={normalHandleBackButton}
          component={
            <SiniestroMenu
            setStep={setStep}
            nombre={pet?.nombre}
              />}
          />
        );
      case 1:
        return(
          <CustomIonBackButtonWrapper
           onBackButtonRef={customHandleBackButton}
           component={<ConsultasIndicaciones pet={pet}/>}
          />);
      case 2:
        return (
          <CustomIonBackButtonWrapper
           onBackButtonRef={customHandleBackButton}
           component={
             <DocumentacionObligatoria
              nombre={pet?.nombre}
              />
           }
           />
        );
      case 3:
        return (
          <CustomIonBackButtonWrapper
           onBackButtonRef={customHandleBackButton}
           component={<Movimientos nombre={pet?.nombre} />}
          />);
      default:
        console.log('menu siniestro error');
    }
  }

  return <>
      <NavigationBar
        path="siniestros/show-siniestros"
        id={pet?.id.toString()}
        step={step}
        setStep={setStep}
        goBack={false}
      />
      <IonContent fullscreen>
        <SiniestroInfo pet={pet} />
        <div className="menu-siniestro-page__main ion-padding-horizontal">
          {switchStep()}
        </div>
      </IonContent>

    </>
}

export default MenuSiniestroWrapper;
