import React, { useState } from 'react';

import {
  IonAlert,
  IonButton,
  IonIcon,
  IonTitle,
  useIonRouter,
} from '@ionic/react';

import './ExitComponent.scss';
import { ExitComponentProps } from './types';

const ExitComponent: React.FC<ExitComponentProps> = ({
  icon,
  title,
  showAlert,
  exitTo,
  action,
}) => {
  const [exitAlertOpen, setExitAlertOpen] = useState(false);
  const history = useIonRouter();
  const goBack = () => {
    if(exitTo.length>0){
      history.push(exitTo, 'back', 'pop');
    }else{
      history.back();
    }
  };
  const showAlertExit = () => {
    showAlert ? setExitAlertOpen(true) : goBack();
  };
  return (
    <>
      <div className="exit-component__content">
        <IonButton
          className="exit-component__content__button"
          onClick={showAlertExit}
          fill="clear"
        >
          <IonIcon slot="icon-only" icon={icon} color="dark" />
        </IonButton>
        <IonTitle>{title}</IonTitle>
      </div>

      <IonAlert
        mode='ios'
        isOpen={exitAlertOpen}
        onDidDismiss={() => {
          //          setExitAlertOpen(false); //@TODO revisar por que estaba peusto esto, dispara un error de react y no tiene mayor gollete
        }}
        cssClass="new-generic-alert"
        header={'Espere'}
        subHeader={'Se perderán los cambios'}
        message={'¿Está seguro que desea salir?'}
        buttons={[
          {
            text: 'Cancelar',
          },
          {
            text: 'Salir',
            handler: () => {
              goBack();
              if (action) action();
            },
          },
        ]}
      />
    </>
  );
};

export default ExitComponent;
