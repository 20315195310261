import React, { useState } from 'react';
import { IonPopover, IonButton } from '@ionic/react';

import { GET_INDICACIONES } from '../../axios/constants';
import { useVetFormLinkData } from '../../hooks/useVetFormLinkData';
import { Derivacion } from '../../interfaces/Derivacion';
import { Veterinario as VeterinarioInterface } from '../../interfaces/Veterinario';
import { VetFormLinkDataAddFormType } from '../../interfaces/forms/VetFormLinkData';
import './Veterinario.scss';
import { VeterinarioProps } from './types';
import { useSiniestro } from '../../context/SiniestroContext';

const Veterinario: React.FC<VeterinarioProps> = ({
  indicacion,
  veterinario,
  indicacionesSeleccionadas,
  setOkAlertOpen,
  setFailAlertOpen,
  checked,
}) => {
  const { nombre, apellido } = veterinario;

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const { siniestroId, siniestro : { data: siniestro }, indicaciones } = useSiniestro();
  const { vetFormLinkDataToSend } = useVetFormLinkData();

  const sendVetForm = async () => {
    let derivacionLinkData_toSend: Derivacion[] = [];
    const vetFormLinkData_toSend: VeterinarioInterface = {
      id: veterinario.id,
      nombre: veterinario.nombre,
      mail: veterinario.mail,
      telefono: veterinario.telefono,
      tipo: veterinario.tipo,
    };

    if (indicacionesSeleccionadas !== undefined) {
      console.log(indicacionesSeleccionadas);
      derivacionLinkData_toSend = indicacionesSeleccionadas.map(function (
        val
      ) {
        const d: Derivacion = {
          id: val.id,
          tipo: val.tipo,
        };
        return d;
      });
    }
    //hay que ahcer un .append de d a derivacionLinkData_toSend
    if (indicacion !== undefined) {
      const d: Derivacion[] = [
        {
          id: indicacion.id,
          tipo: indicacion.tipo,
        },
      ];
      derivacionLinkData_toSend = d;
    }

    const vetFormLinkDataObj: VetFormLinkDataAddFormType = {
      veterinario: vetFormLinkData_toSend,
      derivaciones: derivacionLinkData_toSend,
      siniestroId: siniestroId,
    };

    const ok = await vetFormLinkDataToSend(vetFormLinkDataObj);
    indicaciones.mutate(undefined,true);
    if (ok) {
      setShowPopover({ showPopover: false, event: undefined });
      setOkAlertOpen(true);
    } else {
      setShowPopover({ showPopover: false, event: undefined });
      setFailAlertOpen(true);
    }
  };

  return (
    <div className="veterinario">
      <IonButton
        disabled={checked}
        mode='ios'
        expand="block"
        fill="outline"
        size="small"
        className="ion-text-wrap"
        onClick={(e: any) => {
          e.persist();
          setShowPopover({ showPopover: true, event: e });
        }}
      >
        {nombre} {apellido}
      </IonButton>

      <IonPopover
        mode="ios"
        cssClass="veterinario__popover"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <div className="ion-padding">
          <div className="veterinario__title ion-padding-bottom">
            <span>¿Quiere confirmar el envio a {nombre} {apellido}?</span>
          </div>
          <div>
            <IonButton
              expand="block"
              className="ion-margin-top"
              onClick={() => {
                setShowPopover({ showPopover: false, event: undefined });
                sendVetForm();
              }}
            >
              Enviar
            </IonButton>
            <IonButton
              expand="block"
              color='dark'
              fill="outline"
              onClick={() => {
                setShowPopover({ showPopover: false, event: undefined });
              }}
            >
              Cancelar
            </IonButton>
          </div>
        </div>
      </IonPopover>
    </div>
  );
};

export default Veterinario;
