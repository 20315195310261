import { useEffect, useState } from 'react';

import useSWR, { mutate } from 'swr';

import { DATOS_PAGO } from '../axios/constants';
import { getDatosPago as axiosGetDatosPago } from '../axios/datosPago';
import { postDatosPago as axiosPostDatosPago } from '../axios/datosPago';

export const useGetDatosPago = () => {
  const { data, error, mutate, isValidating } = useSWR(DATOS_PAGO, axiosGetDatosPago,  {
    shouldRetryOnError: false,
    revalidateOnFocus:false,
  });

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
  }, [data, mutate, error]);

  // Eliminar duplicados basados en el número de tarjeta
  const uniqueData = data
    ? data.filter((item, index, self) => {
        return (
          index === self.findIndex((i) => i.numero_pago === item.numero_pago)
        );
      })
    : data;

  return { datosPago: uniqueData, mutate, error, isValidating };
};

export const usePostDatosPago = () => {
  const [isLoading, setLoading] = useState(false);

  const addDatosPago = async (
    numero_pago: string | undefined,
    vencimiento: string | undefined,
    tipo: number | undefined
  ) => {
    setLoading(true);
    const ok = await axiosPostDatosPago({
      numero_pago,
      vencimiento,
      tipo,
    });
    setLoading(false);
    mutate(DATOS_PAGO);
    return ok;
  };
  return { isLoading, addDatosPago };
};
