import React from 'react';
import { IonPopover } from '@ionic/react';

import VetFormLinkData from '../VetFormLinkData';
import './AddVetContact.scss';
import { AddVetContactProps } from './types';

const AddVetContact: React.FC<AddVetContactProps> = ({
  indicacion,
  indicacionesSeleccionadas,
  setIndicacionesSeleccionadas,
  popoverState,
  setShowPopover,
  setOkAlertOpen,
  setFailAlertOpen,
  nombre,
}) => {
  return (
    <div className="add-vet-contact">
      <IonPopover
        mode="ios"
        cssClass="add-vet-contact__popover"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <VetFormLinkData
          indicacion={indicacion}
          indicacionesSeleccionadas={indicacionesSeleccionadas}
          setIndicacionesSeleccionadas={setIndicacionesSeleccionadas}
          setOkAlertOpen={setOkAlertOpen}
          setFailAlertOpen={setFailAlertOpen}
          setShowPopover={setShowPopover}
          nombre={nombre}
        />
      </IonPopover>
    </div>
  );
};

export default AddVetContact;
