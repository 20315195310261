import React from 'react';
import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import { FoodMascotaReq } from '../../interfaces/Food';
import { Pet } from '../../interfaces/Pet';
import './AddFoodPetItem.scss';
import { AddFoodPetItemProps } from './types';
import "animate.css";

const AddFoodPetItem: React.FC<AddFoodPetItemProps> = ({
  pet,
  addToList,
  removeFromList,
  added
}) => {
  const addPet = (pet: Pet) => {
    if (!added?.some(e => e.mascotaId === pet.id)) {
      let addedPet: FoodMascotaReq;
      addedPet = {
        mascotaId: pet.id,
      };
      addToList(addedPet);
    } else {
      removeFromList(pet.id);
    }
  };

  return (
    <div className='add-food-pet-item__pet-box'
      key={pet.id}
      onClick={() => addPet(pet)}
    >
      <img className={added?.some(e => e.mascotaId === pet.id) ? "add-food-pet-item__pet-selected animate__animated animate__pulse animate__faster" : undefined}
        src={pet.img_link ? pet.img_link : pet.animal === "Perro" ? DogPlaceholder : CatPlaceholder}
      />
      <div className={added?.some(e => e.mascotaId === pet.id) ? 'add-food-pet-item__pet-selected__bold' : undefined}>{pet.nombre}</div>
    </div>
  );
};

export default AddFoodPetItem;
