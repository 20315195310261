import axiosInstance from '.';
import { LOGOUT } from './constants';
import { LogoutReq } from '../interfaces/Logout';

export const postLogout = async (value: LogoutReq): Promise<boolean> => {
  try {
    await axiosInstance.post(LOGOUT, value);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
