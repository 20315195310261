import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonSpinner,
  IonToast,
  useIonRouter,
} from '@ionic/react';

import { useGetLocalidades, useSubmitPersonalData } from '../../../hooks';
import { useEnter } from '../../../hooks/useEnter';
import {
  AddressDataReq,
  PersonalDataModificationRequest,
  getEmptyPersonalDataModificationRequest,
} from '../../../interfaces/PersonalData';
import {
  Option,
  buildModification,
  getNonNullFields,
  isReqValid,
} from '../../../utils/Form';
import { capitalizeEveryWord } from '../../../utils/General';
import { provinces } from '../../../utils/GetProvinces';
import AdviceAlert from '../../AdviceAlert';
import FormItem from '../../FormItem';
import FormSelectOrCreate from '../../FormSelectOrCreate';
import ToastAlert from '../../ToastAlert';
import './AddressDataForm.scss';
import { AddressDataFormInputs, AddressDataFormProps } from './types';

const AddressDataForm: React.FC<AddressDataFormProps> = ({
  actualValues,
  liftValues,
  prevForm,
  prevFormValues,
  userReq,
  setNonNullFields,
  setShowPopover,
}) => {
  const history = useIonRouter();
  const { ref: enterRef, handleEnter } = useEnter();
  const [showNonValidReqToast, setShowNonValidReqToast] = useState<boolean>(
    false
  );
  const { localidades, isLoading } = useGetLocalidades(actualValues?.provincia);

  const [option, setOption] = useState<Option | null>();
  const [option1, setOption1] = useState<Option | null>();

  const {
    submitPersonalData,
    isSubmitting,
    submittedOk,
    submittedWrong,
    setSubmittedOk,
    setSubmittedWrong,
  } = useSubmitPersonalData();

  const initialFormValues: AddressDataFormInputs | undefined = actualValues && {
    address: actualValues.direccion,
    number: actualValues.numero,
    floor: actualValues.piso,
    department: actualValues.departamento,
    // province: { value: actualValues.provincia, label: actualValues.provincia },
    // location: {
    //   value: actualValues.localidadId,
    //   label: String(actualValues.localidadId),
    // },
  };

  useEffect(() => {
    if (actualValues?.provincia !== undefined) {
      setOption({
        label: actualValues.provincia,
        value: actualValues.provincia,
      });
    }

    if (actualValues?.localidad !== undefined) {
      setOption1({
        label: actualValues.localidad,
        value: actualValues.localidad,
      });
    }
  }, [actualValues]);

  const { control, handleSubmit, errors, setValue, watch, getValues } = useForm<
    AddressDataFormInputs
  >({
    defaultValues: initialFormValues,
  });

  const formMethods = { control, errors, setValue };

  const onBack = () => {
    const {
      address,
      number,
      floor,
      department,
      province,
      location,
    } = control.getValues();
    // TODO FIXXX
    // liftValues({
    //   direccion: address,
    //   numero: number,
    //   piso: floor,
    //   departamento: department,
    //   provincia: String(getValues('province')),
    //   localidad: String(getValues('location')),
    // });
    prevForm();
  };

  const onSubmit = async (data: AddressDataFormInputs) => {
    const { address, number, floor, department, province, location } = data;
    const addressData: AddressDataReq = {
      direccion: address,
      numero: Number(number),
      piso: Number(floor),
      departamento: department,
      // provincia: province?.value as string,
      // localidadId: location?.value as number,
      provincia: String(getValues('province')),
      localidadId: localidades?.findIndex(
        (element) => element.label === String(getValues('location'))
      ),
      localidad: String(getValues('location')),
    };

    if (!userReq && prevFormValues) {
      await submitPersonalData(prevFormValues, addressData);
    } else {
      const emptyPersonalDataModificationRequest: PersonalDataModificationRequest = getEmptyPersonalDataModificationRequest();
      let req: PersonalDataModificationRequest = buildModification(
        emptyPersonalDataModificationRequest,
        [prevFormValues, userReq]
      );
      console.log('adressData: ', addressData);
      console.log('actualValues: ', actualValues);
      req = buildModification(emptyPersonalDataModificationRequest, [
        addressData,
        actualValues,
      ]);
      const isValid: boolean = isReqValid(req);

      if (isValid) {
        setNonNullFields(getNonNullFields(req));
        setShowPopover({ showPopover: true, event: undefined });
      } else {
        setShowNonValidReqToast(true);
      }
    }
  };

  return (
    <div className="ion-padding-horizontal">
      <form
        className="address-data-form ion-padding"
        onKeyUp={handleEnter}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="title ion-margin-vertical">Domicilio</div>

        <FormItem
          errors={errors}
          render={({ value, onChange }) => (
            <IonItem mode="ios" className="ion-no-padding">
              <IonLabel position="floating">Dirección</IonLabel>
              <IonInput
                value={capitalizeEveryWord(value)}
                onIonChange={onChange}
              />
            </IonItem>
          )}
          control={control}
          name="address"
          rules={{
            required: {
              value: true,
              message: 'Por favor, ingrese la calle en la que vive',
            },
            maxLength: {
              value: 40,
              message: 'Supera la longitud máxima',
            },
          }}
        />

        <FormItem
          errors={errors}
          render={({ value, onChange }) => (
            <IonItem mode="ios" className="ion-no-padding">
              <IonLabel position="floating">Altura</IonLabel>
              <IonInput value={value} type="number" onIonChange={onChange} />
            </IonItem>
          )}
          control={control}
          name="number"
          rules={{
            required: {
              value: true,
              message: 'Por favor, ingrese la altura',
            },
            maxLength: {
              value: 6,
              message: 'Supera la longitud máxima',
            },
          }}
        />

        <FormItem
          errors={errors}
          render={({ value, onChange }) => (
            <IonItem mode="ios" className="ion-no-padding">
              <IonLabel position="floating">Piso (opcional)</IonLabel>
              <IonInput value={value} type="number" onIonChange={onChange} />
            </IonItem>
          )}
          control={control}
          name="floor"
          rules={{
            maxLength: {
              value: 3,
              message: 'Supera la longitud máxima',
            },
          }}
        />

        <FormItem
          errors={errors}
          render={({ value, onChange }) => (
            <IonItem mode="ios" className="ion-no-padding">
              <IonLabel position="floating">Depto. (opcional)</IonLabel>
              <IonInput value={value} onIonChange={onChange} />
            </IonItem>
          )}
          control={control}
          name="department"
          rules={{
            maxLength: {
              value: 4,
              message: 'Supera la longitud máxima',
            },
          }}
        />

        <div className="ion-margin-vertical">
          <div className="ion-margin-bottom">
            <FormSelectOrCreate
              options={provinces as Option[]}
              option={option}
              formMethods={formMethods}
              fieldProps={{
                name: 'province',
                placeholder: 'Seleccione una provincia',
              }}
              validationProps={{
                errorMessage: 'Por favor indique una provincia',
              }}
            />
          </div>

          <FormSelectOrCreate
            options={localidades as Option[]}
            option={option1}
            formMethods={formMethods}
            isLoading={isLoading}
            fieldProps={{
              name: 'location',
              placeholder: 'Seleccione una localidad',
            }}
            validationProps={{
              errorMessage: 'Por favor indique una localidad',
            }}
          />
        </div>

        <div className="ion-margin-top">
          <IonButton
            onClick={onBack}
            mode="ios"
            expand="block"
            fill="outline"
            color="dark"
          >
            Volver
          </IonButton>
          <IonButton ref={enterRef} type="submit" mode="ios" expand="block">
            {isSubmitting && (
              <IonSpinner
                name="crescent"
                className="button-spinner"
                slot="end"
              />
            )}
            Enviar
          </IonButton>
        </div>

        <div>
          <AdviceAlert
            isOpen={submittedOk}
            setOpen={setSubmittedOk}
            action={() => {}}
            onDidDismiss={() => {
              history.push('/add-pet');
            }}
            header="¡ Excelente !"
            subHeader=""
            message="Sus datos fueron cargados con éxito"
          />

          <IonToast
            isOpen={submittedWrong}
            onDidDismiss={() => {
              setSubmittedWrong(false);
            }}
            message="Hubo un problema. Por favor, intente nuevamente más tarde."
            duration={2000}
          />

          <ToastAlert
            isOpen={showNonValidReqToast}
            setShowToast={setShowNonValidReqToast}
            message="Ups... Debe modificar al menos uno de sus datos personales para continuar."
            color="warning"
            duration={3500}
          />
        </div>
      </form>
    </div>
  );
};

export default AddressDataForm;
