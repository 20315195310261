import { useState } from 'react';

import { FoodMascotaReq } from '../interfaces/Food';

export const useAddPetFood = () => {
  const [added, setAdded] = useState<FoodMascotaReq[]>([]);

  const addToList = (pet: FoodMascotaReq) => {
    const currentPets = added;
    setAdded(currentPets.concat(pet));
  };

  const removeFromList = (petId: number) => {
    const currentPets = added;
    const newPets = currentPets.filter((pet) => pet.mascotaId !== petId);
    setAdded(newPets);
  };

  return { added, addToList, removeFromList };
};
