import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { IonItem, IonLabel, IonInput, IonButton, IonDatetime, IonSpinner } from '@ionic/react';
import { useEnter } from '../../hooks';
import { useVetFormLinkData } from '../../hooks/useVetFormLinkData';
import { Derivacion } from '../../interfaces/Derivacion';
import { Veterinario } from '../../interfaces/Veterinario';
import {
  VetFormLinkDataAddFormType,
  emptyVetFormLinkData,
} from '../../interfaces/forms/VetFormLinkData';
import FormItem from '../FormItem';
import './VetFormLinkData.scss';
import { VetFormLinkDataProps } from './types';
import {
  ValidationMail,
  validationName,
  validationPhone,
} from '../../assets/validations/validationRegex';
import { useSiniestro } from '../../context/SiniestroContext';
import { today } from '../../utils/DateUtilities';
import { capitalizeEveryWord } from '../../utils/General';

const VetFormLinkData: React.FC<VetFormLinkDataProps> = ({
  indicacion,
  indicacionesSeleccionadas,
  setIndicacionesSeleccionadas,
  setOkAlertOpen,
  setFailAlertOpen,
  setShowPopover,
  nombre,
}) => {
  const {
    siniestroId,
    siniestro: { data: siniestro },
    indicaciones
  } = useSiniestro();
  const { handleEnter } = useEnter();
  const { vetFormLinkDataToSend } = useVetFormLinkData();
  const { control, handleSubmit, errors } = useForm<VetFormLinkDataAddFormType>(
    {
      defaultValues: emptyVetFormLinkData,
    }
  );

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    let derivacionLinkData_toSend: Derivacion[] = [];
    const vetFormLinkData_toSend: Veterinario = {
      nombre: values.nombreVet + ' ' + values.apellidoVet,
      mail: values.emailVet.trim(),
      telefono: values.telefonoVet,
      tipo: 'VetContact',
    };

    if (indicacionesSeleccionadas) {
      derivacionLinkData_toSend = indicacionesSeleccionadas.map(function (
        val,
        index
      ) {
        const d: Derivacion = {
          id: val.id,
          tipo: val.tipo,
        };
        return d;
      });
    }

    //TODO .append de d a derivacionLinkData_toSend
    if (indicacion) {
      const d: Derivacion[] = [
        {
          id: indicacion.id,
          tipo: indicacion.tipo,
        },
      ];
      derivacionLinkData_toSend = d;
    }

    const vetFormLinkDataObj: VetFormLinkDataAddFormType = {
      veterinario: vetFormLinkData_toSend,
      derivaciones: derivacionLinkData_toSend,
      siniestroId: siniestroId,
      fechaAtencion: values.fechaAtencion
    };
    const ok = await vetFormLinkDataToSend(vetFormLinkDataObj);
    indicaciones.mutate(undefined, true);
    if (ok) {
      setIndicacionesSeleccionadas([]);
      setShowPopover({ showPopover: false, event: undefined });
      setOkAlertOpen(true);
    } else {
      setShowPopover({ showPopover: false, event: undefined });
      setFailAlertOpen(true);
    }
    setLoading(false);
  };

  return (
    <div className="vet-form-link-data ion-padding">
      <form onSubmit={handleSubmit(onSubmit)} onKeyUp={handleEnter}>
        <div className="vet-form-link-data__title ion-padding-bottom">
          <span>
            Ingrese los datos de contacto del veterinario que atendió a{' '}
            {nombre}
          </span>
        </div>
        <div className="ion-text-center ion-padding-vertical">
          <FormItem
            render={({ onChange, value, name }) => (
              <IonItem mode="md">
                <IonLabel position="floating">Nombre</IonLabel>
                <IonInput
                  value={capitalizeEveryWord(value)}
                  type="text"
                  name={name}
                  onIonChange={onChange}
                />
              </IonItem>
            )}
            control={control}
            name="nombreVet"
            errors={errors}
            rules={{
              required: {
                value: true,
                message:
                  'Por favor, ingrese nombre y apellido de su veterinario',
              },
            }}
          />
          <FormItem
            render={({ onChange, value, name }) => (
              <IonItem mode="md">
                <IonLabel position="floating">Apellido</IonLabel>
                <IonInput
                  value={capitalizeEveryWord(value)}
                  type="text"
                  name={name}
                  onIonChange={onChange}
                />
              </IonItem>
            )}
            control={control}
            name="apellidoVet"
            errors={errors}
            rules={{
              required: {
                value: true,
                message:
                  'Por favor, ingrese nombre y apellido de su veterinario',
              },
              pattern: validationName,
            }}
          />
          <FormItem
            render={({ onChange, value, name }) => (
              <IonItem mode="md">
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  value={value}
                  type="text"
                  name={name}
                  onIonChange={onChange}
                />
              </IonItem>
            )}
            control={control}
            name="emailVet"
            errors={errors}
            rules={{
              required: {
                value: true,
                message: 'Por favor, ingrese el email de su veterinario',
              },
              pattern: ValidationMail,
            }}
          />
          <FormItem
            render={({ onChange, value, name }) => (
              <IonItem mode="md">
                <IonLabel position="floating">Teléfono</IonLabel>
                <IonInput
                  value={value}
                  type="text"
                  name={name}
                  onIonChange={onChange}
                />
              </IonItem>
            )}
            control={control}
            name="telefonoVet"
            errors={errors}
            rules={{
              required: {
                value: true,
                message: 'Por favor, ingrese el teléfono de su veterinario',
              },
              pattern: validationPhone,
            }}
          />
          <FormItem
            render={({ onChange, name, value }) => (
              <IonItem mode="md">
                <IonLabel position="floating">
                  Fecha de atención
                </IonLabel>
                <IonDatetime
                  name={name}
                  value={value}
                  displayFormat="DD/MM/YYYY"
                  cancelText="Cancelar"
                  doneText="Ok"
                  onIonChange={onChange}
                  max={today}
                />
              </IonItem>
            )}
            control={control}
            name="fechaAtencion"
            errors={errors}
            rules={{
              required: {
                value: true,
                message: 'Por favor, ingrese la fecha de atención veterinaria.',
              },
            }}
          />

        </div>
        <IonButton type="submit" disabled={loading} expand="block">
          {loading ? <IonSpinner color='light' /> : "Confirmar"}
        </IonButton>
      </form>
    </div>
  );
};

export default VetFormLinkData;
