import axiosInstance from '.';
import { PERSONAL_DATA } from './constants';
import {
  PersonalDataReq,
  AddressDataReq,
  PersonalAndAddressDataReq,
} from '../interfaces/PersonalData';

export const sendPersonalData = async (
  personalData: PersonalDataReq,
  addressData: AddressDataReq
) => {
  const body: PersonalAndAddressDataReq = {
    datos_persona: personalData,
    datos_domicilio: addressData,
  };
  try {
    await axiosInstance.post(PERSONAL_DATA, body);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
