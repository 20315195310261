/* eslint-disable no-useless-catch */

import { useState } from 'react';
import { mutate } from 'swr';
import { ADD_FOOD, GET_A_FOOD } from '../axios/constants';
import {
  addFood,
  updateFood,
  removePetFood,
  deleteFoodBag,
} from '../axios/food';
import { FoodReq, RemovePetFood, UpdateFoodRes } from '../interfaces/Food';

export const useSubmitNewFood = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const submitNewFood = async (food: FoodReq) => {
    setSubmitting(true);
    setSubmitted(false);
    try {
      const er = await addFood(food);
      switch (er.status.status) {
        case 201:
          mutate(ADD_FOOD);
          setSubmitting(false);
          setSubmitted(true);
          return er.status.status;
        case 409:
          setSubmitting(false);
          setSubmitted(false);
          return er.status.status;
        default:
          setSubmitting(false);
          setSubmitted(false);
      }
    } catch (e) {
      setSubmitting(false);
      setSubmitted(false);
      throw e;
    }
  };

  const updatePetFood = async (food: UpdateFoodRes, id: number) => {
    setSubmitting(true);
    const rta = await updateFood(food);
    setSubmitting(false);
    if (rta) {
      mutate([GET_A_FOOD, id]);
      return true;
    } else {
      return false;
    }
  };

  const removePetFromFood = async (food: RemovePetFood, id: number) => {
    setSubmitting(true);
    const rta = await removePetFood(food);
    setSubmitting(false);
    if (rta) {
      mutate([GET_A_FOOD, id]);
      return true;
    } else {
      return false;
    }
  };

  const deleteFood = async (id: number) => {
    setSubmitting(true);
    const rta = await deleteFoodBag(id);
    setSubmitting(false);
    if (rta) {
      mutate(GET_A_FOOD);
      return true;
    } else {
      return false;
    }
  };

  return {
    submitNewFood,
    isSubmitting,
    isSubmitted,
    setSubmitted,
    updatePetFood,
    removePetFromFood,
    deleteFood,
  };
};
