import { useEffect, useState } from 'react';

import useSWR from 'swr';

import { GET_EVENTS } from '../axios/constants';
import { getEvents as axiosGetEvents } from '../axios/getEventos';

export const useGetEvents = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data, error, mutate, isValidating } = useSWR(
    GET_EVENTS,
    axiosGetEvents,
    {revalidateOnFocus:false,
     dedupingInterval:3000}
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
    
    if (!data) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [data, mutate, error]);

  return { eventos: data, isValidating, isLoading };
};
