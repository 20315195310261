import useSWR from 'swr';

import { GET_MOV_SINIESTRO } from '../axios/constants';
import { getMovimientos } from '../axios/getMovimientos';

export const useGetMovimientos = (id: string) => {
  const { data, isValidating, error } = useSWR(GET_MOV_SINIESTRO, (url) =>
    getMovimientos(url, id),{revalidateOnFocus:false,dedupingInterval:2000}
  );

  return { movimientos: data, isValidating, error };
};
