import axios from '.';
import {
  ADD_COMPROBANTE_CLIENTE_FILE
} from './constants';

export const addComprobanteCliente = async (formData: FormData) => {
  await axios.post(ADD_COMPROBANTE_CLIENTE_FILE, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
