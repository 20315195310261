import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonLabel,
  IonSelect,
  IonRadioGroup,
  IonItem,
  IonRadio,
  IonDatetime,
  IonSelectOption,
  IonButton,
  useIonRouter,
  IonIcon,
  IonPopover,
  useIonViewWillLeave,
  useIonViewDidEnter
} from '@ionic/react';
import { alertCircleSharp, informationCircleSharp } from 'ionicons/icons';
import { CrearEventoFormContext } from '../../../context/CrearEventoContext';
import { useMascotas } from '../../../context/MascotasContext';
import { useEventsType } from '../../../hooks/useEventType';
import { CrearEventoFirstFormInterface } from '../../../interfaces/CrearEvento';
import { maxDateEvent, minDateEvent } from '../../../utils/DateUtilities';
import FormItem from '../../FormItem';
import './CrearEventoFirstForm.scss';
import { CrearEventoFirstFormProps } from './types';

const CrearEventoFirstForm: React.FC<CrearEventoFirstFormProps> = ({
  onSubmit,
}) => {
  const { crearEventoFormValues } = useContext(CrearEventoFormContext);
  const { control, errors, handleSubmit, reset } = useForm<
    CrearEventoFirstFormInterface
  >({ defaultValues: crearEventoFormValues });
  const { mascotas: { pets } } = useMascotas();
  const [done, setDone] = useState<boolean>();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const { eventsType, changeEventType } = useEventsType();
  const history = useIonRouter();
  useEffect(() => {
    crearEventoFormValues ?? reset();
  }, [crearEventoFormValues]);

  useEffect(() => {
    if (!eventsType.find((et) => et.value === control.getValues('tipo')))
      control.setValue('tipo', undefined);
  }, [eventsType]);

  useIonViewDidEnter(() =>
    setShowPopover({ showPopover: true, event: undefined }), [setShowPopover]);
/* @NOTA hay redundancias de esta accion porque este evento desde la popover no se dispara */
  useIonViewWillLeave(() =>
    setShowPopover({ showPopover: false, event: undefined }), [setShowPopover]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="crear-evento-first-form ion-padding">
          <IonIcon icon={informationCircleSharp} color='primary' onClick={() => setShowPopover({ showPopover: true, event: undefined })}></IonIcon>
          <div className="ion-margin-bottom">
            <FormItem
              errors={errors}
              control={control}
              name="mascota"
              render={({ onChange, name, value }) => (
                <div>
                  <IonLabel>Nombre</IonLabel>

                  <IonSelect
                    name={name}
                    value={value}
                    // style={{ textAlign: 'right' }}
                    onIonChange={(e) => {
                      const sexo = pets?.find((pet) => pet.id === e.detail.value)
                        ?.sexo;
                      onChange(e);
                      changeEventType(sexo);
                    }}
                    placeholder="Seleccione nombre"
                    interface="popover"
                    className="ion-no-padding ion-padding-top"
                  >
                    {pets
                      ? pets.map((pet) => (
                        <IonSelectOption key={pet.id} value={pet.id}>
                          {pet.nombre}
                        </IonSelectOption>
                      ))
                      : undefined}
                  </IonSelect>
                </div>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, seleccione el nombre.',
                },
              }}
            />
          </div>

          <div className="ion-margin-bottom">
            <FormItem
              errors={errors}
              control={control}
              name="tipo"
              render={({ onChange, name, value }) => (
                <div>
                  <IonLabel>Evento</IonLabel>
                  <IonSelect
                    disabled={!control.getValues('mascota')}
                    name={name}
                    value={value}
                    // style={{ textAlign: 'right' }}
                    onIonChange={onChange}
                    placeholder="Seleccione evento"
                    interface="popover"
                    okText="Confirmar"
                    cancelText="Cancelar"
                    className="ion-no-padding ion-padding-top"
                  >
                    {eventsType.map((event) => (
                      <IonSelectOption key={event.value} value={event.value}>
                        {event.nombre}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, seleccione el tipo de evento.',
                },
              }}
            />
          </div>

          <div className="ion-margin-bottom">
            <FormItem
              errors={errors}
              control={control}
              name="done"
              render={({ onChange, name, value }) => (
                <div className="crear-evento-first-form__row">
                  <IonLabel>¿Ha ocurrido?</IonLabel>
                  <IonRadioGroup
                    name={name}
                    value={value}
                    onIonChange={(e) => {
                      setDone(e.detail.value === 'si' ? true : false);
                      control.setValue('event_on', undefined);
                      onChange(e.detail.value);
                    }}
                  >
                    <div className="crear-evento-first-form__row--radio">
                      <IonItem lines="none" className="ion-no-padding">
                        <IonLabel>Si</IonLabel>
                        <IonRadio slot="start" value={'si'} />
                      </IonItem>
                      <IonItem lines="none" className="ion-no-padding">
                        <IonLabel>No</IonLabel>
                        <IonRadio slot="start" value={'no'} />
                      </IonItem>
                    </div>
                  </IonRadioGroup>
                </div>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, seleccione si el evento ha ocurrido o no.',
                },
              }}
            />
          </div>

          <div className="ion-margin-bottom">
            <FormItem
              errors={errors}
              control={control}
              name="event_on"
              render={({ onChange, value, name }) => (
                <div>
                  <IonLabel>
                    {done ? '¿Cuándo ocurrió?' : '¿Proximo evento?'}
                  </IonLabel>
                  <IonItem style={{ flexGrow: 1 }} className="ion-no-padding">
                    <IonDatetime
                      name={name}
                      value={value}
                      onIonChange={(e) => onChange(e)}
                      picker-format="DD MM YYYY"
                      displayFormat="DD/MM/YYYY"
                      placeholder="Fecha"
                      max={maxDateEvent(done ?? false)}
                      min={minDateEvent(done ?? true)}
                      disabled={done === undefined}
                      cancelText="Cancelar"
                      doneText="Ok"
                      className="ion-no-padding"
                    />
                  </IonItem>
                </div>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, ingrese la fecha del evento.',
                },
              }}
            />
          </div>

          <div className="ion-margin-bottom">
            <FormItem
              errors={errors}
              control={control}
              name="hora"
              render={({ onChange, value, name }) => (
                <div>
                  <IonLabel>¿Hora?</IonLabel>
                  <IonItem style={{ flexGrow: 1 }}
                    className='ion-no-padding'
                  >
                    <IonDatetime
                      value={value}
                      name={name}
                      picker-format="HH:mm"
                      displayFormat="HH:mm"
                      placeholder="Hora"
                      minuteValues="0,15,30,45"
                      onIonChange={onChange}
                      disabled={done === undefined}
                      cancelText="Cancelar"
                      doneText="Ok"
                      className='ion-no-padding'
                    />
                  </IonItem>
                </div>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, ingrese la hora del evento.',
                },
              }}
            />
          </div>

          <div className="ion-margin-top">
            <div className="ion-padding-top">
              <IonButton type="submit" mode="ios" expand="block">
                Siguiente
              </IonButton>
            </div>
          </div>
        </div>
      </form >
      <IonPopover
        mode="ios"
        cssClass="crear-evento-info__popover"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <>
          <IonIcon icon={alertCircleSharp} color='warning' />
          <span>Esta agenda es para su organización y uso personal. Para <b>solicitar un reintegro por enfermedad o accidente</b> vaya a la sección denuncias.</span>
          <div className='popover-btn-container ion-margin-top'>
            <IonButton
             mode='ios'
             fill='outline'
             className="ion-no-margin"
             onClick={() => {
             setShowPopover({ showPopover: false, event: undefined });
             history.push('/tabs/siniestros/pets','forward','push');
             }}
            >
              Ir a denuncias
            </IonButton>
            <IonButton mode='ios' fill='outline' className="ion-no-margin" onClick={() => setShowPopover({ showPopover: false, event: undefined })}>Entendido</IonButton>
          </div>
        </>
      </IonPopover>

    </>
  );
};

export default CrearEventoFirstForm;
