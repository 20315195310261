import React from 'react';

import { IonButton, useIonRouter } from '@ionic/react';

import './CrearEventoFooter.scss';
import { CrearEventoFooterProps } from './types';

const CrearEventoFooter: React.FC<CrearEventoFooterProps> = ({
  onEdit = false,
}) => {
  const history = useIonRouter();
  return (
    <div className="crear-evento-footer ion-padding">
      <IonButton
        mode="ios"
        expand="block"
        fill="outline"
        hidden={onEdit}
        onClick={() => history.back()}
      >
        Volver
      </IonButton>
      <IonButton type="submit" mode="ios" expand="block">
        {onEdit ? 'Guardar' : 'Confirmar'}
      </IonButton>
    </div>
  );
};

export default CrearEventoFooter;
