import React from 'react';

import { AuthViewProps } from './types';

import './AuthView.scss';

const AuthView: React.FC<AuthViewProps> = (props) => {
  return (
    <div className="auth-view" style={{ background: props.src }}>
      <div className="auth-view__row">
        <img
          className="auth-view__row--img"
          src="assets/img/FielPetLogo.png"
          alt="auth-view__img"
        ></img>
      </div>
      <div className="auth-view__row--form">{props.children}</div>
      <img className="auth-view__row--pet-img" src={props.src}></img>
    </div>
  );
};

export default AuthView;
