import useSWR from 'swr';

import {
  GET_VETERINARIO,
  GET_VETERINARIO_VETFORM,
  GET_VETERINARIOS,
  GET_VETERINARIOS_RECOMENDADOS,
  GET_VETERINARIOS_SINIESTRO,
} from '../axios/constants';
import {
  getVeterinarios,
  getVeterinariosRecomendados,
  getVeterinario,
  getVeterinarioVetform,
  getVeterinariosBySiniestro,
} from '../axios/getVeterinarios';


export const useGetVeterinariosBySineistro = (siniestro: string) => {
  const { data, isValidating, error } = useSWR([GET_VETERINARIOS_SINIESTRO, siniestro], 
    (url) => getVeterinariosBySiniestro(url, siniestro)
  );

  return{veterinarios: data, isValidating, error}
  }

export const useGetVeterinarios = () => {
  const { data, isValidating, error } = useSWR([GET_VETERINARIOS], (url) =>
    getVeterinarios(url)
  );

  return { veterinarios: data, isValidating, error };
};

export const useGetVeterinariosRecomendados = (id: number, tipo: string) => {
  const { data, isValidating, error } = useSWR(
    [GET_VETERINARIOS_RECOMENDADOS, id],
    (url) => getVeterinariosRecomendados(url, id, tipo)
  );

  return { veterinariosRecomendados: data, isValidating, error };
};

export const useGetVeterinario = (id: string) => {

  const { data, isValidating, error } = useSWR((id != undefined) ? [GET_VETERINARIO, id] : null,
    
    (url) => getVeterinario(url, id)
  );

  return { veterinario: data, isValidating, error };
};

export const useGetVeterinarioVetform = (vetFormId: string) => {

  const { data, isValidating, error } = useSWR((vetFormId != undefined) ? [GET_VETERINARIO_VETFORM, vetFormId] : null,
    
    (url) => getVeterinarioVetform(url, vetFormId)
  );

  return { veterinario: data, isValidating, error };
};

//este hook que no utilize SWR es para fetchear veterinarios dentro de useEffects
export const useVeterinario = () => {
  const getVeterinarioById = async (id: string) => {
    try {
      const veterinario = await getVeterinario(GET_VETERINARIO, id);
      return veterinario;
    } catch (e) {
      console.error(e);
    }
  };

  const getVeterinarioByVetform = async (vetFormId: string) => {
    try {
      const veterinario = await getVeterinarioVetform(GET_VETERINARIO_VETFORM, vetFormId);
      return veterinario;
    } catch (e) {
      console.error(e);
    }
  };

  return { getVeterinarioById, getVeterinarioByVetform };
};

