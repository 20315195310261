import React, { useState } from 'react';

import { IonModal, IonIcon, useIonRouter, IonAlert } from '@ionic/react';
import {
  addCircleSharp,
  closeOutline,
  logOutOutline,
  medkit,
  menuOutline,
  pawSharp,
  timeSharp,
  videocam,
} from 'ionicons/icons';

import Logo from '../../assets/FielPetLogo.png';
import './HomeMenuModal.scss';
import { HomeMenuModalProps } from './types';
import { useAuth, useUserSession } from '../../hooks';
import PersonalDataAlert from '../PersonalDataAlert';

const HomeMenuModal: React.FC<HomeMenuModalProps> = ({ pets, showModal, setShowModal }) => {
  const { logout } = useAuth();
  const { push } = useIonRouter();
  const { hasPersonalData } = useUserSession();
  const [isAlertOpen, setAlertOpen] = useState<boolean>(false);
  const [showLogOut, setShowLogOut] = useState<boolean>(false);

  function handler(goTo: string) {
    if (goTo === 'newSiniestro' || goTo === 'showSiniestros') {
      switch (true) {
        case pets?.length === 0:
          setShowModal(false);
          push('/add-pet', 'forward', 'push');
          break;
        case pets?.length === 1:
          if (goTo === 'newSiniestro') {
            push(`/tabs/siniestros/new/${pets![0].id}`);
          } else {
            push(`/tabs/siniestros/show-siniestros/${pets![0].id}`);
          }
          break;
        case pets?.length! > 1:
          if (goTo === 'newSiniestro') {
            push('/tabs/siniestros/pets');
          } else {
            push('/tabs/siniestros/pets');
          }
          break;
        default:
          console.log(`siniestros error`);
      }
    }

    if (goTo === 'historial') {
      switch (true) {
        case pets?.length === 0:
          push('/add-pet');
          break;
        case pets?.length! >= 1:
          push(`/tabs/historial`);
          break;
        default:
          console.log(`historial error`);
      }
    }

    if (goTo === 'alimentacion') {
      switch (true) {
        case pets?.length === 0:
          push('/add-pet');
          break;
        case pets?.length! >= 1:
          push(`/tabs/alimentacion`);
          break;
        default:
          console.log(`alimentacion error`);
      }
    }

    if (goTo === 'vet-call') {
      push('/tabs/home/vet-call')
    }
    setShowModal(false);
  }

  const addPetOnClick = () =>
    hasPersonalData ? clickWithPersonalData() : clickWithoutPersonalData();

  const clickWithPersonalData = () => {
    setShowModal(false);
    push('/add-pet');
  };

  const clickWithoutPersonalData = () => {
    setAlertOpen(true);
  };

  return (
    <div className="home-menu-modal">
      <IonIcon
        icon={menuOutline}
        className="home-menu-modal__show-modal-icon"
      />
      <IonModal isOpen={showModal} mode="ios" onDidDismiss={() => setShowModal(false)} backdropDismiss>
        <div className="home-menu-modal__container ion-padding">
          <div className="home-menu-modal__logo-box">
            <img src={Logo} />
            <IonIcon
              icon={closeOutline}
              color="dark"
              onClick={() => setShowModal(false)}
            />
          </div>
          <div className="ion-margin-top">
            <div
              className="home-menu-modal__option"
              onClick={() => addPetOnClick()}
            >
              <IonIcon icon={addCircleSharp} />
              <p>Agregar Mascota</p>
            </div>
            <div
              className="home-menu-modal__option"
              onClick={() => handler('newSiniestro')}
            >
              <IonIcon icon={medkit} color='danger' />
              <p>Nuevo accidente o enfermedad</p>
            </div>
            <div
              className="home-menu-modal__option"
              onClick={() => handler('historial')}
            >
              <IonIcon icon={timeSharp} />
              <p>Historial de mi mascota</p>
            </div>
            <div
              className="home-menu-modal__option"
              onClick={() => handler('alimentacion')}
            >
              <IonIcon icon={pawSharp} />
              <p>Alimentación</p>
            </div>
            <div
              className="home-menu-modal__option"
              onClick={() => handler("vet-call")}
            >
              <IonIcon icon={videocam} />
              <p>Videollamada con un profesional</p>
            </div>

            <div
              className="home-menu-modal__option"
              onClick={() => setShowLogOut(true)}
            >
              <IonIcon icon={logOutOutline} />
              <p>Cerrar sesión</p>
            </div>
          </div>
        </div>
      </IonModal>

      {isAlertOpen && (
        <PersonalDataAlert isOpen={isAlertOpen} setOpen={setAlertOpen} />
      )}

      <IonAlert
        mode='ios'
        isOpen={showLogOut}
        onDidDismiss={() => setShowLogOut(false)}
        header="¿Deseas cerrar sesión?"
        subHeader="Mensaje importante"
        // message="El titular de la póliza debe firmar una nota autorizando al tercero (nombre, apellido y CUIL/DNI) a recibir el reintegro."
        buttons={[
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              setShowLogOut(false);
            },
          },
          {
            text: 'Confirmar',
            handler: async () => {
              await logout();
              push('/login');
            },
          },
        ]}
      />
    </div>
  );
};

export default HomeMenuModal;
