import { useEffect } from 'react';
import { useStorage } from './useStorage';
import { useIonRouter } from '@ionic/react';

export const useInitialSlides = () => {
  const { setValue, getValue } = useStorage();
  const history = useIonRouter();

  const getInitialSlidesState = async () => {
    const initialSlides = await getValue('initial_slides');
    if (initialSlides === null) {
      await setValue('initial_slides', 'true');
      history.push('/initial-slides','forward','push');
    }
  };

  useEffect(() => {
    getInitialSlidesState();
  }, []);
};
