import axiosInstance from '.';
import { LOGIN } from './constants';
import { AxiosResponse } from 'axios';
import { LoginRes, LoginReq } from '../interfaces/Login';

export const login = async ({
  email,
  password,
}: LoginReq): Promise<{ ok: boolean; data?: LoginRes }> => {
  try {
    const { data }: AxiosResponse<LoginRes> = await axiosInstance.post(LOGIN, {
      email,
      password,
    });
    return { ok: true, data };
  } catch (e) {
    console.error(e);
    return { ok: false };
  }
};
