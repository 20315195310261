import axiosInstance from '.';
import { GET_PAYMENT } from './constants';
import { AxiosResponse } from 'axios';
import { Payment } from '../interfaces/Payment';

export const getCards = async () => {
  try {
    const {
      data: payments,
    }: AxiosResponse<Payment[]> = await axiosInstance.get(GET_PAYMENT);
    return payments;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
