import React from 'react';
import { IonContent, IonAlert } from '@ionic/react';

import { DeleteAlertProps } from './types';

import './DeleteAlert.scss';

const DeleteAlert: React.FC<DeleteAlertProps> = ({
  delText,
  isOpen,
  setOpen,
  action,
}) => {
  return (
    <IonContent>
      <IonAlert
        mode='ios'
        isOpen={isOpen}
        onDidDismiss={() => {
          setOpen(false);
        }}
        header="Espere"
        message={'¿Está seguro/a que desea eliminar' + delText + '?'}
        buttons={[
          {
            text: 'Cancelar',
          },
          {
            text: 'Confirmar',
            handler: () => {
              action();
            },
          },
        ]}
      />
    </IonContent>
  );
};

export default DeleteAlert;
