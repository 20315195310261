import useSWR from 'swr';

import { GET_SINIESTRO } from '../axios/constants';
import { getSiniestro } from '../axios/siniestros';

export const useGetSiniestro = (siniestroId: string) => {
  const { data, isValidating, error, mutate } = useSWR(
    [GET_SINIESTRO, siniestroId],
    (url) => getSiniestro(url, siniestroId),
    {revalidateOnFocus:false,dedupingInterval:1500}
  );

  return { data, error, isValidating, mutate };
};
