import { AxiosResponse } from 'axios';
import axiosInstance from '.';

import { GET_PRODUCTOS_DESPARASITACION } from './constants';
import { ProductosDesparasitacion } from '../interfaces/Desparasitacion';

export const getProductosDesparasitacion = async () => {
  try {
    const {
      data: productosDes,
    }: AxiosResponse<ProductosDesparasitacion> = await axiosInstance.get(
      GET_PRODUCTOS_DESPARASITACION
    );
    return productosDes;
  } catch (e) {
    console.error(e.response ?? e);
    throw e;
  }
};
