import { FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';

import moment from 'moment';

interface Difference {
  campo: string;
  valorOriginal?: string;
  nuevoValor: string;
}

export interface Option {
  readonly label: string;
  readonly value: string;
}

export interface nonNullFields {
  key: string;
  value: string;
}

//Errors in form
//Obtiene errores anidados de un objeto de errores
export const getErrors = (errors: DeepMap<any, FieldError>, name: string) => {
  // Verifica si hay errores
  if (!errors) {
    return undefined;
  }

  // Utiliza reduce para obtener el error anidado basado en el nombre de la propiedad
  const nestedError = name.split('.').reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : undefined;
  }, errors);

  return nestedError;
};

export const assignDataToObj = (data, obj, setter) => {
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && key in obj) {
      obj[key] = data[key];
    }
  }

  setter(obj);
};

export const buildModification = (obj: any, objToCompare: any[]) => {
  const differences: Difference[] = findDifferences(objToCompare);

  for (const dif of differences) {
    if (dif.campo in obj) {
      obj[dif.campo] = dif.nuevoValor;
    }
  }

  return obj;
};

export const findDifferences = (objToCompare: any[]): Difference[] => {
  if (objToCompare.length < 2) {
    // Si hay menos de dos objetos para comparar, no hay diferencias
    return [];
  }

  const obj1 = objToCompare[0];
  const obj2 = objToCompare[1];

  const differences: Difference[] = [];
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
      // y también cuando los valores sean números equivalentes pero uno esté como cadena y el otro como número
      if (
        !(
          (isNaN(obj1[key]) && obj2[key] === undefined) ||
          (typeof obj1[key] === 'number' &&
            typeof obj2[key] === 'string' &&
            Number(obj2[key]) === obj1[key]) ||
          (typeof obj1[key] === 'string' &&
            typeof obj2[key] === 'number' &&
            Number(obj1[key]) === obj2[key])
        )
      ) {
        differences.push({
          campo: key,
          // valorOriginal: obj2[key],
          nuevoValor: obj1[key],
        });
      }
    }
  }
  return differences;
};

export const isReqValid = (req: any): boolean => {
  for (const key in req) {
    if (req[key] !== null && req[key] !== 0) {
      // Si al menos un campo no es null, retorna true
      return true;
    }
  }
  return false;
};

const formatKey = (key: string): string => {
  // Reemplaza los caracteres especiales como "_" por espacios en blanco
  return key.replace(/_/g, ' ');
};

export const formatDateValue = (value: any): any => {
  if (
    typeof value === 'string' &&
    moment(value, moment.ISO_8601, true).isValid()
  ) {
    // Si el valor es una cadena y tiene un formato de fecha ISO 8601 válido
    return moment(value).format('DD/MM/YYYY');
  }
  return value;
};

export const getNonNullFields = (obj) => {
  const nonNullFields: nonNullFields[] = [];

  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== '' && 
      obj[key] !== 0
    ) {
      const formattedKey = formatKey(key);
      const formattedValue = formatDateValue(obj[key]);
      nonNullFields.push({ key: formattedKey, value: formattedValue });
    }
  }

  return nonNullFields;
};
