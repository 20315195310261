import React, { useState } from "react";
import { useParams } from 'react-router';
import { useForm } from "react-hook-form";
import moment from "moment";
import { IonPopover, IonIcon, IonSelect, IonSelectOption, IonDatetime, IonItem, IonLabel, IonButton, IonAlert, IonCol, IonGrid, IonRow } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { CameraSource } from "@capacitor/camera";

import { LibretaVacunacionProps } from './types';
import './LibretaVacunacion.scss';
import 'animate.css'

import { useGetPet } from "../../hooks";
import { useGetVacunas } from "../../hooks/useGetVacunas";
import { useLibVacunacionPic } from "../../hooks/useLibVacunacionPic";
import Libreta from '../../assets/vaccinationLibreta.png';
import Vaccinatted from '../../assets/vaccinated.png'
import Vaccine from '../../assets/vaccine.png'
import Camera from '../../assets/camera.png';
import Vaccination from '../../assets/vaccination.png';

import FormItem from "../FormItem";

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

type ParamsType = {
  id: string;
};

const LibretaVacunacion: React.FC<LibretaVacunacionProps> = ({ libretaVacunacionPopoverState, setLibretaVacunacionPopoverState }) => {
  const { handleSubmit, control, errors, setError } = useForm();
  const { id: petId } = useParams<ParamsType>();
  const { pet } = useGetPet(petId);
  const { vacunasFiltered } = useGetVacunas(petId);
  const { updateLibVacunacionPic } = useLibVacunacionPic();

  const [libretaVacStepper, setLibretaVacStepper] = useState<number>(0);
  const [editLibreta, setEditLibreta] = useState<boolean>(false);

  const [vacuna, setVacuna] = useState<string | undefined>(undefined);
  const [fechaColocacion, setFechaColocacion] = useState<any>(undefined);


  const [seeLibretaVacunacionPopoverState, setSeeLibretaVacunacionPopoverState] = useState({
    showPopover: false,
    event: undefined,
  });

  const [zoom, setZoom] = useState<boolean>(false);
  const [showCameraAlert, setShowCameraAlert] = useState<boolean>(false);

  function goBack() {
    if (libretaVacStepper === 0 && !editLibreta) {
      setLibretaVacunacionPopoverState({ showPopover: false, event: undefined });
    } else if (libretaVacStepper === 0 && editLibreta) {
      setEditLibreta(false);
    } else {
      setLibretaVacStepper(libretaVacStepper - 1);
    }
  }

  async function newLibVacunacionPicHandler(sourceType) {
    // await updateProfilePic(sourceType, petId);
    // await updateLibVacunacionPicHandler(sourceType, petId);
    // mutate();
    // setLibretaVacunacionPopoverState({ showPopover: true, event: undefined })
  }

  const imgDemoArray: string[] = [
    Libreta, Vaccinatted, Vaccine, Camera, Vaccination
  ];

  return (
    <div>
      <IonPopover
        mode="ios"
        cssClass="libreta-vacunacion-popover"
        event={libretaVacunacionPopoverState.event}
        isOpen={libretaVacunacionPopoverState.showPopover}
        onDidDismiss={() => {
          setLibretaVacunacionPopoverState({ showPopover: false, event: undefined });
          setEditLibreta(false);
        }}
      >
        <div className='container'>
          <IonIcon className='goBack' icon={chevronBack} onClick={() => goBack()} />
          <img src={libretaVacStepper !== 3 ? Libreta : Camera} />
          <div className="title">
            {libretaVacStepper !== 3 ? " Libreta de vacunación" : "Adjuntar foto de libreta"}
          </div>

          {libretaVacStepper === 0 &&
            <div className="options">
              {!editLibreta ?
                <IonGrid>
                  <IonRow>
                    <IonCol size="6">
                      <div className="option" onClick={() => {
                        setLibretaVacunacionPopoverState({ showPopover: false, event: undefined });
                        setSeeLibretaVacunacionPopoverState({ showPopover: true, event: undefined })
                      }
                      }>
                        <img src={Vaccinatted} />
                        <p>Libreta de vacunación </p>
                      </div>
                    </IonCol>
                    <IonCol size="6">
                      <div className="option" onClick={() => setEditLibreta(true)}>
                        <img src={Vaccine} />
                        <p>Vacunas y portada</p>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                :
                <IonGrid>
                  <IonRow className="animate__animated animate__backInRight">
                    <IonCol size="6">
                      <div className="option" onClick={() => {
                        setLibretaVacunacionPopoverState({ showPopover: false, event: undefined });
                        setShowCameraAlert(true);
                      }}>
                        <img src={Camera} />
                        <p>Nueva portada de la libreta</p>
                      </div>
                    </IonCol>
                    <IonCol size="6">
                      <div className="option" onClick={() => setLibretaVacStepper(1)}>
                        <img src={Vaccination} />
                        <p>Nueva vacuna de la libreta</p>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            </div>
          }

          {libretaVacStepper === 1 &&
            <div className="form-box animate__animated animate__backInRight">
              <p>¿Que vacuna se le colocó a {pet?.nombre}?</p>
              <FormItem
                name="VacunaId"
                control={control}
                errors={errors}
                render={({ onChange, name }) => (
                  <IonItem className="ion-no-padding ion-margin-vertical">
                    <IonSelect
                      name={name}
                      value={vacuna}
                      onIonChange={(e) => {
                        setVacuna(e.detail.value);
                        onChange();
                      }}
                      placeholder="Seleccione la vacuna"
                      interface="popover"
                      className='ion-no-padding'
                    >
                      {vacunasFiltered?.map((event) => (
                        <IonSelectOption key={event.id} value={event.nombre}>
                          {event.nombre}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Por favor, complete este campo.',
                  },
                }}
              />

              <IonButton expand="block" disabled={!vacuna} onClick={() => vacuna !== undefined && setLibretaVacStepper(libretaVacStepper + 1)}>Continuar</IonButton>
            </div>
          }

          {libretaVacStepper === 2 &&
            <div className="form-box animate__animated animate__backInRight">
              <p>¿En que fecha se le colocó a {pet?.nombre} la vacuna?</p>
              <FormItem
                render={({ onChange, value }) => (
                  <IonItem className="ion-no-padding ion-margin-vertical">
                    <IonLabel>Colocación</IonLabel>
                    <IonDatetime
                      value={fechaColocacion}
                      displayFormat="DD/MM/YYYY"
                      cancelText="Cancelar"
                      doneText="Ok"
                      onIonChange={(e) => {
                        setFechaColocacion(e.detail.value);
                        onChange();
                      }}
                    // max={maxDateEvent(realizado ?? false)}
                    // min={minDateEvent(realizado ?? false)}
                    ></IonDatetime>
                  </IonItem>
                )}
                control={control}
                name="fechaColocacion"
                errors={errors}
              />
              <IonButton expand="block" disabled={!fechaColocacion} onClick={() => vacuna !== undefined && setLibretaVacStepper(libretaVacStepper + 1)}>Continuar</IonButton>
            </div>
          }

          {libretaVacStepper === 3 &&
            <div className="vacunaColocada">
              <p className="info">
                Se le coloco a <span>{pet?.nombre}</span> la vacuna: <span>{vacuna}</span> el día <span>{moment(fechaColocacion).format('DD-MM-YYYY')}</span>.
              </p>
              <p className="photoInfo">
                Adjunta una foto aquí para dejar constancia de la misma.
                La imagen formara parte de la libreta de vacunación digital de {pet?.nombre}.
              </p>
              <IonButton expand="block" onClick={() => {
                setLibretaVacunacionPopoverState({ showPopover: false, event: undefined });
                setShowCameraAlert(true);
              }
              }
              >Adjuntar imagen</IonButton>
            </div>
          }
        </div>
      </IonPopover >

      <IonPopover
        mode="ios"
        cssClass="see-libreta-vacunacion__popover"
        event={seeLibretaVacunacionPopoverState.event}
        isOpen={seeLibretaVacunacionPopoverState.showPopover}
        onDidDismiss={() => {
          setLibretaVacunacionPopoverState({ showPopover: false, event: undefined })
          setSeeLibretaVacunacionPopoverState({ showPopover: false, event: undefined })
        }
        }
      >
        <div className="container">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerView={1}
            navigation
          // spaceBetween={15}
          // pagination={{ clickable: true }}
          >
            {imgDemoArray.map((img, index) => (
              <SwiperSlide key={index}>
                <div className="seeLibreta-slide">
                  <img src={img} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </IonPopover>

      <IonAlert
        mode='ios'
        isOpen={showCameraAlert}
        onDidDismiss={() => setShowCameraAlert(false)}
        header="Indique desde donde subir la imagen"
        message="Por favor seleccione"
        buttons={[
          {
            text: 'Camara',
            handler: () => {
              newLibVacunacionPicHandler(CameraSource.Camera)
            },
          },
          {
            text: 'Galería',
            handler: () => {
              newLibVacunacionPicHandler(CameraSource.Photos)
            },
          },
        ]}
      />
    </div >
  )
}

export default LibretaVacunacion;