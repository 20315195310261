import React from 'react';
import Select from 'react-select';

import { SearchbarProps } from './types';

const Searchbar: React.FC<SearchbarProps> = ({
  options,
  onChange,
  placeholder,
  value,
  name,
  isLoading,
  onMenuOpen,
  onMenuClose
}) => {
  const isDisabled = options.length === 0;
  return (
    <div className="searchbar">
      <Select
        selectedText={value}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onValueClick={(e)=>e.stopPropagation()}
        key={value}
        value={value}
        name={name}
        noOptionsMessage={() => 'No hay resultados'}
        isClearable
        isSearchable
        isDisabled={isDisabled}
        isLoading={isLoading}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 10 }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'var(--ion-color-primary)',
            primary25: 'var(--ion-color-tertiary)',
          },
        })}
      />
    </div>
  );
};

export default Searchbar;
