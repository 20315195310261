import React, { useEffect, useState } from 'react';
import {
  IonLabel,
  IonItem,
  IonInput,
  IonDatetime,
  IonToggle,
} from '@ionic/react';
import { useForm } from 'react-hook-form';

import { CrearEventoCriaFormProps } from './types';
import FormItem from '../../FormItem';
import TextBox from '../../TextBox';
import { CrearEventoConsultaFormInterface } from '../../../interfaces/CrearEvento';
import CrearEventoFooter from '../CrearEventoFooter';
import {
  maxDateEvent,
  minDateEvent,
  today,
} from '../../../utils/DateUtilities';

import './CrearEventoCriaForm.scss';

const CrearEventoCriaForm: React.FC<CrearEventoCriaFormProps> = ({
  onSubmit,
  defaultForm,
  onEditMode = false,
  changeReset,
  doReset,
}) => {
  const { control, errors, handleSubmit, reset } = useForm<
    CrearEventoConsultaFormInterface
  >({ defaultValues: defaultForm });

  const [realizado, setRealizado] = useState(
    defaultForm ? defaultForm.done : true
  );

  useEffect(() => {
    reset();
    !changeReset || changeReset(false);
  }, [doReset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="crear-evento-cria-form">
        {onEditMode ? (
          <>
            <FormItem
              render={({ onChange, value }) => (
                <IonItem>
                  <IonLabel>Fecha</IonLabel>
                  <IonDatetime
                    value={value}
                    displayFormat="DD/MM/YYYY"
                    cancelText="Cancelar"
                    doneText="Ok"
                    onIonChange={onChange}
                    max={maxDateEvent(realizado ?? false)}
                    min={minDateEvent(realizado ?? false)}
                  ></IonDatetime>
                </IonItem>
              )}
              control={control}
              name="fecha"
              errors={errors}
            />

            <FormItem
              render={({ onChange, value }) => (
                <IonItem>
                  <IonLabel>Hora</IonLabel>
                  <IonDatetime
                    value={value}
                    minuteValues="0,15,30,45"
                    displayFormat="HH:mm"
                    pickerFormat="HH:mm"
                    cancelText="Cancelar"
                    doneText="Ok"
                    onIonChange={onChange}
                  ></IonDatetime>
                </IonItem>
              )}
              control={control}
              name="hora"
              errors={errors}
            />

            <FormItem
              render={() => (
                <IonItem
                  lines="none"
                  hidden={defaultForm ? defaultForm.done : true}
                >
                  <IonLabel>Realizado?</IonLabel>
                  <IonToggle
                    checked={realizado}
                    onIonChange={(e) => {
                      setRealizado(e.detail.checked);
                      control.setValue('done', e.detail.checked);
                      control.setValue('fecha', today);
                      control.setValue('hora', today);
                    }}
                  />
                </IonItem>
              )}
              name="done"
              control={control}
            ></FormItem>
          </>
        ) : null}

        <FormItem
          name="machos"
          control={control}
          errors={errors}
          render={({ onChange, value, name }) => (
            <IonItem>
              <IonLabel position="floating">Machos</IonLabel>
              <IonInput
                type="number"
                onIonChange={(e: any) =>
                  e.detail.value
                    ? onChange(parseInt(e.detail.value, 10))
                    : onChange(e)
                }
                value={value}
                name={name}
              />
            </IonItem>
          )}
          rules={{
            required: {
              value: true,
              message: 'Por favor, complete este campo.',
            },
          }}
        />
        <FormItem
          name="hembras"
          control={control}
          errors={errors}
          render={({ onChange, value, name }) => (
            <IonItem>
              <IonLabel position="floating">Hembras</IonLabel>
              <IonInput
                type="number"
                onIonChange={(e: any) =>
                  e.detail.value
                    ? onChange(parseInt(e.detail.value, 10))
                    : onChange(e)
                }
                value={value}
                name={name}
              />
            </IonItem>
          )}
          rules={{
            required: {
              value: true,
              message: 'Por favor, complete este campo.',
            },
          }}
        />
        <FormItem
          name="fallecidos"
          control={control}
          errors={errors}
          render={({ onChange, value, name }) => (
            <IonItem>
              <IonLabel position="floating">Fallecidos</IonLabel>
              <IonInput
                type="number"
                onIonChange={(e: any) =>
                  e.detail.value
                    ? onChange(parseInt(e.detail.value, 10))
                    : onChange(e)
                }
                value={value}
                name={name}
              />
            </IonItem>
          )}
          rules={{
            required: {
              value: true,
              message: 'Por favor, complete este campo.',
            },
          }}
        />
        <FormItem
          name="description"
          control={control}
          errors={errors}
          render={({ onChange, value, name }) => (
            <>
              <IonLabel>Descripción</IonLabel>
              <TextBox onIonChange={onChange} value={value} name={name} />
            </>
          )}
        />
      </div>
      {onEditMode ? <CrearEventoFooter onEdit={true} /> : <CrearEventoFooter />}
    </form>
  );
};

export default CrearEventoCriaForm;
