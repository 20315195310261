import React, { useState } from "react";
import { useParams } from 'react-router';
import { IonPopover, IonIcon, useIonRouter } from "@ionic/react";
import { refresh, calendarNumber, add } from "ionicons/icons";

import { NutricionMenuProps } from './types';

import 'animate.css';
import './NutricionMenu.scss';

import Nutricion from '../../assets/pet-food3.png';
import NewWeightAlert from "../NewWeightAlert";
import WeightHistoryModal from "../WeightHistoryModal";


type ParamsType = {
  id: string;
};

const NutricionMenu: React.FC<NutricionMenuProps> = ({ pet, mutate, nutricionPopoverState, setNutricionPopoverState }) => {
  const { id: petId } = useParams<ParamsType>();
  const { push } = useIonRouter();
  const [isNewWeightOpen, setNewWeightOpen] = useState<boolean>(false);
  const [isWeightHistoryOpen, setWeightHistoryOpen] = useState<boolean>(false);

  return (
    <div>
      <IonPopover
        mode="ios"
        cssClass="nutricion-menu-popover animate__animated animate__zoomIn"
        event={nutricionPopoverState.event}
        isOpen={nutricionPopoverState.showPopover}
        onDidDismiss={() =>
          setNutricionPopoverState({ showPopover: false, event: undefined })
        }
      >
        <img src={Nutricion} />
        <div className='title'>Nutrición</div>
        <div className='options'>
          <div onClick={() => {
            setNewWeightOpen(true)
            setNutricionPopoverState({ showPopover: false, event: undefined })
          }}>
            <div className='option'><IonIcon icon={refresh} /> Actualizar peso
            </div>
          </div>

          <div onClick={() => {
            setWeightHistoryOpen(true)
            setNutricionPopoverState({ showPopover: false, event: undefined })
          }}>
            <div className='option'><IonIcon icon={calendarNumber} /> Historial de pesaje
            </div>
          </div>

          <div onClick={() => {
            push("/tabs/alimentacion/agregar")
            setNutricionPopoverState({ showPopover: false, event: undefined })
          }}>
            <div className='option'><IonIcon icon={add} /> Nuevo alimento                </div>
          </div>
        </div>
      </IonPopover>

      <NewWeightAlert
        petId={petId}
        isOpen={isNewWeightOpen}
        setOpen={setNewWeightOpen}
        setWeightHistoryOpen={setWeightHistoryOpen}
        mutate={mutate}
        nombre={pet?.nombre}
      />

      <WeightHistoryModal
        isOpen={isWeightHistoryOpen}
        setOpen={setWeightHistoryOpen}
        weightHistory={pet?.historial_peso || []}
      />
    </div>
  )
}

export default NutricionMenu;