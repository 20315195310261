import React from 'react';

import { IonGrid, IonRow, IonCol } from '@ionic/react';

import './SvfSummary.scss';
import { SvfSummaryProps } from './types';

const SvfSummary: React.FC<SvfSummaryProps> = ({ type, SVF }) => {
  return (
    <IonGrid className='ion-no-padding ion-margin-bottom'>
      <div className="svf-summary">
        <IonRow className="svf-summary__border-bottom">
          <IonCol className="svf-summary__type">{type}</IonCol>
        </IonRow>
        {SVF.map((e, i) => (
          <IonRow
            key={i}
            className={`${
              e.Clave !== 'Costo' ? 'svf-summary__border-bottom' : null
            }`}
          >
            <IonCol className="svf-summary__key">{e.Clave}</IonCol>
            <IonCol className="svf-summary__value ion-text-end">{e.Valor}</IonCol>
          </IonRow>
        ))}
      </div>
    </IonGrid>
  );
};

export default SvfSummary;
