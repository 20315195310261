import React from 'react';
import { Redirect, Route } from 'react-router';
import { IonApp, setupConfig, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { CrearEventoFormProvider } from './context/CrearEventoContext';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import HandleBackButton from './components/HandleBackButton';
import notFoundPage from './pages/not-found/index';
import initialSlidesPage from './pages/initial-slides/index';
import passRecoverPage from './pages/pass-recover/index';
import registerPage from './pages/register/index';
import addPetPage from './pages/add-pet/index';
import LoginPage from './pages/login/index';
import TabsPage from './pages/tabs';
import CrearEvento from './pages/crear-evento';
import { TutorialProvider } from './context/TutorialContext';
import { UserContextProvider } from './context/UserContext';
import { MascotasContextProvider } from './context/MascotasContext';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

import './styles/global.scss';
import { AuthProvider } from './context/AuthContext';

setupConfig({ swipeBackEnabled: false });

const App: React.FC = () => (
  <IonApp>
    <CrearEventoFormProvider>
      <AuthProvider>
        <TutorialProvider>
          <UserContextProvider>
            <MascotasContextProvider>
              <IonReactRouter>
                <IonRouterOutlet ionPage id='appoutlet' key='appoutlet'  >
                  <Route exact path="/" render={() => <Redirect to="/tabs/home" />} />
                  <PrivateRoute path="/tabs" component={TabsPage} />
                  <Route exact path="/home" render={() => <Redirect to="/tabs/home" />}/>
                  <Route path="/initial-slides" component={initialSlidesPage} />
                  <Route path="/login" component={LoginPage} exact />
                  <Route path="/register" component={registerPage} />
                  <Route path="/pass-recover" component={passRecoverPage} />
                  <PrivateRoute exact path="/add-pet" component={addPetPage} />
                  <PrivateRoute path="/crear-evento" component={CrearEvento} />
                  <Route path="/not-found" component={notFoundPage} />
                  <Redirect to="/not-found" />
                </IonRouterOutlet>
                <HandleBackButton />
              </IonReactRouter>
            </MascotasContextProvider>
          </UserContextProvider>
        </TutorialProvider>
      </AuthProvider>
    </CrearEventoFormProvider>
  </IonApp>
);

export default App;
