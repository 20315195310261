import React from 'react';

import FormAddDocObligatoria from '../FormAddDocObligatoria';
import './DocumentacionObligatoria.scss';
import { DocumentacionObligatoriaProps } from './types';
import FormDocExtra from '../FormDocExtra';
const DocumentacionObligatoria: React.FC<DocumentacionObligatoriaProps> = ({
  nombre,
}) => {

  return (
    <div className="documentacion-obligatoria">
      <div className="documentacion-obligatoria__heading ion-padding-horizontal ion-padding-bottom">
        <h1>Documentación obligatoria</h1>
        <p>
          Subi tu <span className="bold">DNI</span>, la
          <span className="bold"> libreta de vacunacíon</span> de {nombre} y la
          <span className="bold"> constancia de CBU </span> donde queres recibir
          el reintegro.
        </p>
      </div>
      <FormAddDocObligatoria />
      <FormDocExtra/>
    </div>
  );
};

export default DocumentacionObligatoria;
