import React, { createContext, useContext, useEffect } from 'react';
import { useGetPets } from '../hooks/usePets';
import {AuthContext} from './AuthContext';

class MascotasContextType {
  constructor(){
    this.mascotas = useGetPets();
  };
  mascotas : ReturnType<typeof useGetPets>;

};
const MascotasContext = createContext<MascotasContextType | null>(null);

export const MascotasContextProvider: React.FC = ({children}) => {
  const mascotaContextData = new MascotasContextType();
  const {authStatus} = useContext(AuthContext);

  useEffect(()=>{
    mascotaContextData.mascotas.mutate();
  },[authStatus]);

  return (
    <MascotasContext.Provider value={mascotaContextData}>
      {children}
    </MascotasContext.Provider>
  );
};

export const useMascotas: () => MascotasContextType = () => {
  const mascotasContext = useContext(MascotasContext);
  if (!mascotasContext) throw Error('mascotas context unavailable');
  return mascotasContext;
};
