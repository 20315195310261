import { useState, useEffect } from 'react';

import { getCards as axiosGetCards } from '../axios/getCards';
import { Payment } from '../interfaces/Payment';

export const usePayments = () => {
  const [payments, setPayments] = useState<Payment[]>([]);

  const getPlans = async () => {
    const payments = await axiosGetCards();
    const okPayment = payments
      .map((p) => ({ ...p, tipo: p.tipo.toUpperCase() }))
      .filter((p) => p.is_active === true && p.verifica !== 'cbu');
    setPayments(okPayment);
  };

  useEffect(() => {
    getPlans();
  }, []);

  console.log("PAYMENTS:" , payments)
  return payments;
};
