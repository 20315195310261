export const isOdd = (n: number) => {
  return n % 2 === 1;
};

// https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const match = arr[0].match(/:(.*?);/);
  const mime = match === null ? undefined : match[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function capitalizeEveryWord(str?: string) {
  return str
    ? str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '';
}

export function capitalizeFirstLetter(str?: string) {
  return str
    ? str.replace(/(^|[.]\s+)(\w)/g, (match, p1, p2) => p1 + p2.toUpperCase())
    : '';
}

export function formatCreditCardNumber(e) {
  const inputVal = e.target.value;

  // Eliminar todos los caracteres no numéricos
  const formattedInput = inputVal.replace(/\D/g, '');

  // Agregar un espacio cada 4 dígitos
  let formattedCreditCardNumber = '';
  for (let i = 0; i < formattedInput.length; i++) {
    if (i > 0 && i % 4 === 0) {
      formattedCreditCardNumber += ' ';
    }
    formattedCreditCardNumber += formattedInput[i];
  }

  // setCreditCardNumber(formattedCreditCardNumber);
  return formattedCreditCardNumber.slice(0, 19);
}

export function formatCreditCardDate(e) {
  const inputVal = e.target.value;

  // Eliminar todos los caracteres no numéricos
  const formattedInput = inputVal.replace(/\D/g, '');

  let formattedCreditCardDate = '';

  // Agregar los primeros dos caracteres
  if (formattedInput.length > 2) {
    formattedCreditCardDate += formattedInput.substring(0, 2);
    formattedCreditCardDate += '/';
    formattedCreditCardDate += formattedInput.substring(2);
  } else {
    formattedCreditCardDate = formattedInput;
  }

  return formattedCreditCardDate.slice(0, 5);
}