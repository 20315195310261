import React, { createContext, useEffect, useRef, useState } from "react";
import { useStorage } from "../hooks/useStorage";

class VoidContext implements AuthContextType {
  get authStatus(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setAuthStatus(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

interface AuthContextType {
  authStatus: boolean;
  setAuthStatus: (value: boolean) => void;
}

export const AuthContext  = createContext<AuthContextType>(
  new VoidContext()
);

export const AuthProvider: React.FC = ({ children }) => {
  const { getValue } = useStorage();
  // El default es true porque si no en el tiempo en que tarda en obtenerse el
  // token de storage ya nos redirige al login
  const [isAuth, setAuth] = useState<boolean>(true);
  const isMountedRef = useRef(true)

  const updateAuth = (value : boolean) => {
    if (isMountedRef.current) {
      setAuth(value);
    }
  };

  useEffect(() => {
    getValue('token').then((value) => {
      updateAuth(!!value);
    });
  }, [getValue('token')]);

  return (
    <AuthContext.Provider
      value={{ authStatus: isAuth, setAuthStatus: updateAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};
