import React from 'react';
import { IonAlert, useIonRouter } from '@ionic/react';

import { HavePetAlertProps } from './types';

import './HavePetAlert.scss';

const HavePetAlert: React.FC<HavePetAlertProps> = ({ open, setOpen }) => {
  const history = useIonRouter();
  return (
    <IonAlert
      mode='ios'
      isOpen={open}
      onDidDismiss={() => {
        setOpen(false);
      }}
      backdropDismiss={false}
      header="Atención"
      message="Si usted ya posee alguna mascota asegurada, debe clickear en SINCRONIZAR para poder visibilizarlas."
      buttons={[
        {
          text: 'Cancelar',
        },
        {
          text: 'Sincronizar',
          handler: () => {
            history.push('/tabs/ajustes/asociar');
          },
        },
      ]}
    />
  );
};

export default HavePetAlert;
