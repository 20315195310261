import axiosInstance from '.';
import { REGISTER } from './constants';
import { RegisterReq, StatusRes } from '../interfaces/register';

export const register = async ({
  email,
  password,
  confirm,
}: RegisterReq): Promise<{ status: StatusRes }> => {
  try {
    const res = await axiosInstance.post(REGISTER, {
      email,
      password,
      confirm,
    });
    return { status: { status: res.status } };
  } catch (error) {
    console.error(error.response);
    return { status: { status: error.response.status } };
  }
};
