import axiosInstance from '.';
import { AddWeightReq } from '../interfaces/AddWeight';
import { ADD_WEIGHT } from './constants';

export const addWeight = async (body: AddWeightReq) => {
  try {
    await axiosInstance.post(ADD_WEIGHT, body);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
