import React, { useState, useEffect, useRef,useContext } from 'react';

import { RefresherEventDetail } from '@ionic/core';
import {
  IonPage,
  IonFab,
  IonSegment,
  IonContent,
  IonFabButton,
  IonIcon,
  IonSegmentButton,
  IonLabel,
  IonSlides,
  IonSlide,
  IonSpinner,
  useIonRouter,
} from '@ionic/react';
import { calendar } from 'ionicons/icons';
import {SetLoadingContext} from '../tabs';
import Filter from '../../components/Filter';
import NavigationBar from '../../components/NavigationBar';
import PageRefreseher from '../../components/PageRefresher';
import TimeHistorial from '../../components/TimeHistorial';
import { useGetEvents } from '../../hooks/useGetEvents';
import {useMascotas} from '../../context/MascotasContext';
import { Evento } from '../../interfaces/Evento';
import './TimelinePage.scss';
import moment from 'moment';

function doRefresh(event: CustomEvent<RefresherEventDetail>) {
  setTimeout(() => {
    event.detail.complete();
  }, 2000);
}

const TimelinePage: React.FC = () => {
  const { eventos, isLoading } = useGetEvents();
  const setIsLoading = useContext(SetLoadingContext);
  useEffect(()=>{setIsLoading(isLoading);},[isLoading]);
  const { mascotas: {pets} } = useMascotas();
  const history = useIonRouter();

  const [filterList, setFilterList] = useState(eventos);

  function handleUpcomingEvents(arr: Evento[] | undefined) {
    // Crear un objeto auxiliar para almacenar los eventos de cumpleaños por mascota
    const eventosCumpleanosPorMascota: Evento[] = [];

    // Crear un array para almacenar los eventos que no son de tipo Cumpleaños
    const eventosNoCumpleanos: Evento[] = [];

    // Recorrer el array y clasificar los eventos por tipo
    arr?.forEach((evento) => {
      if (evento.tipo === 'Cumpleaños') {
        const mascotaId = evento.mascotaId;
        // Si ya existe un evento de Cumpleaños para la misma mascota, verificar la fecha más cercana
        if (eventosCumpleanosPorMascota[mascotaId]) {
          if (evento.event_on < eventosCumpleanosPorMascota[mascotaId].event_on) {
            eventosCumpleanosPorMascota[mascotaId] = evento;
          }
        } else {
          eventosCumpleanosPorMascota[mascotaId] = evento;
        }
      } else {
        eventosNoCumpleanos.push(evento);
      }
    });

    // Convertir el objeto auxiliar en un array para obtener los eventos de Cumpleaños sin duplicados
    const eventosCumpleanosSinDuplicados = Object.values(eventosCumpleanosPorMascota);

    // Combinar los eventos de Cumpleaños sin duplicados con los eventos que no son de tipo Cumpleaños
    const resultado: Evento[] = [...eventosCumpleanosSinDuplicados, ...eventosNoCumpleanos];

    const eventosOrdenados = resultado.sort((a, b) => Date.parse(moment(a.event_on).format('YYYY-MM-DD')) - Date.parse(moment(b.event_on).format('YYYY-MM-DD')));

    return eventosOrdenados;
  }

  useEffect(() => {
    setFilterList(eventos);
  }, [eventos, setFilterList]);

  const [segmentValue, setSegmentValue] = useState(0);

  const onCreateEvent = () => {
    history.push('/crear-evento/1');
  };

  const mySlides = useRef(document.createElement('ion-slides'));

  const slideOpts = {
    initialSlide: '0',
    speed: 400,
    zoom: false,
    touchReleaseOnEdges: true,
    autoHeight: true,
  };

  const slideChange = async () => {
    const a = await mySlides.current.getActiveIndex();
    setSegmentValue(a);
  };

  return (
    <IonPage>
      <NavigationBar path={'home'}/>
      <IonContent className="timeline-page">
        <PageRefreseher doRefresh={doRefresh} />

        <IonSegment
          data-tut="reactour__historial-segments"
          mode="md"
          value={segmentValue.toString()}
          onIonChange={(e) => {
            mySlides.current.slideTo(Number(e.detail.value));
            setSegmentValue(Number(e.detail.value));
          }}
          disabled={isLoading}
        >
          <IonSegmentButton
            data-tut="reactour__historial-segments--hechos"
            className="timeline-page__segmentColor"
            value="0"
          >
            <IonLabel className="timeline-page__label">Historico</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            data-tut="reactour__historial-segments--atrasados"
            className="timeline-page__segmentColor"
            value="1"
          >
            <IonLabel className="timeline-page__label">Atrasados</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            data-tut="reactour__historial-segments--proximos"
            className="timeline-page__segmentColor"
            value="2"
          >
            <IonLabel className="timeline-page__label">Próximos</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        <Filter
          pets={pets ?? []}
          events={eventos}
          onFilter={setFilterList}
          isLoading={isLoading}
        />

        {isLoading ? (
          <div className="timeline-page__loading-state">
            <IonSpinner color="dark" />
          </div>
        ) : (
          <IonSlides
            pager={false}
            ref={mySlides}
            options={slideOpts}
            onIonSlideWillChange={slideChange}
          >
            <IonSlide>
              <TimeHistorial
                pets={pets ?? []}
                eventos={filterList?.['hechos']}
              />
            </IonSlide>
            <IonSlide>
              <TimeHistorial
                pets={pets ?? []}
                eventos={filterList?.['atrasados']}
              />
            </IonSlide>
            <IonSlide>
              <TimeHistorial
                pets={pets ?? []}
                eventos={handleUpcomingEvents(filterList?.['proximos'])}
              />
            </IonSlide>
          </IonSlides>
        )}

        {!isLoading && (
          <IonFab
            data-tut="reactour__add-event"
            vertical="bottom"
            horizontal="end"
            slot="fixed"
          >
            <IonFabButton disabled={!pets?.length} onClick={onCreateEvent}>
              <IonIcon icon={calendar} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
    </IonPage>
  );
};

export default TimelinePage;
