import axiosInstance from '.';
import { GET_PLANS_PORTAL } from './constants';
import { AxiosResponse } from "axios";
import { PlanPortal } from "../interfaces/PlanPortal";

export const getPlanesPortal = async (email: string): Promise<PlanPortal[] | void> => {

    try {
        const { data: planes }: AxiosResponse<PlanPortal[]> = await axiosInstance.get(GET_PLANS_PORTAL, {
            params: { email }
        });
        return planes;
    } catch (error) {
        console.error('Error fetching Planes Portal:', error);
    }
};