import axiosInstance from '.';
import { NOTIFICATIONS_SUBSCRIBE } from './constants';

export const postNotifications = async (token: string) => {
  try {
    await axiosInstance.post(NOTIFICATIONS_SUBSCRIBE, { tokens: [token] });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
