import React, { useState } from 'react';
import { useParams } from 'react-router';

import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSpinner,
  IonToast,
  useIonRouter,
} from '@ionic/react';
import {
  trash,
  checkmarkOutline,
  closeOutline,
  chevronBackOutline,
  trashOutline,
} from 'ionicons/icons';

import Birthday from '../../assets/balloons.png';
import CrearEventoConsultaForm from '../../components/CrearEventoForms/CrearEventoConsultaForm';
import CrearEventoCriaForm from '../../components/CrearEventoForms/CrearEventoCriaForm';
import CrearEventoDesparasitacionForm from '../../components/CrearEventoForms/CrearEventoDesparasitacionForm';
import CrearEventoVacunaForm from '../../components/CrearEventoForms/CrearEventoVacunaForm';
import DeleteAlert from '../../components/DeleteAlert';
import ExitComponent from '../../components/ExitComponent';
import NotFound from '../../components/NotFound';
import VideoLlamadaForm from '../../components/VideoLlamadaForm';
import { useEvento } from '../../hooks/useEvento';
import { useGetEvent } from '../../hooks/useGetEvent';
import {
  CrearEventoFormInterface,
  CrearEventoSecondFormInterface,
} from '../../interfaces/CrearEvento';
import { concatDateAndHours } from '../../utils/DateUtilities';
import { settingAge } from '../../utils/PetUtilities';
import { formatTitle } from '../../utils/eventosUtils';
import './EditEventPage.scss';

type ParamsType = {
  id: string;
};

const EditEventPage: React.FC = () => {
  const { id } = useParams<ParamsType>();
  const { data: event, error } = useGetEvent(id);
  const history = useIonRouter();
  const { loading, deleteEvent, updateEvent } = useEvento();
  const [isDelAlertOpen, setDelAlertOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [toast, setToast] = useState({
    visible: false,
    message: '',
    icon: '',
    error: false,
  });

  const goTimeline = () => {
    history.push('/tabs/historial');
  };

  const okToast = (type: string) =>
    setToast({
      visible: true,
      message: 'El evento ha sido ' + type + ' con éxito',
      icon: checkmarkOutline,
      error: false,
    });

  const failToast = () => {
    setToast({
      visible: true,
      message: 'Hubo un error, intente de nuevo más tarde.',
      icon: closeOutline,
      error: true,
    });
  };

  const eliminarEvento = async () => {
    try {
      await deleteEvent(id);
      okToast('eliminado');
      setTimeout(() => goTimeline(), 500);
    } catch (e) {
      failToast();
    }
  };

  const sendEditEvent = async (v: CrearEventoSecondFormInterface) => {
    const { fecha, hora, done, description, ...eventTypeForm } = v;

    const formToSend: CrearEventoFormInterface = {
      hora: hora ?? '',
      tipo: event ? event.tipo : '',
      event_on: fecha && hora ? concatDateAndHours(fecha, hora) : '',
      done: done ?? false,
      description: description === '' ? undefined : description,
      [`Evento${event?.tipo}`]: eventTypeForm,
    };
    try {
      await updateEvent(id, formToSend);
      okToast('editado');
      setTimeout(() => goTimeline(), 500);
    } catch (e) {
      failToast();
    }
  };

  return (
    <IonPage className="edit-event-page">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <ExitComponent
            icon={chevronBackOutline}
            // title={event ? event.Mascotum.nombre : ''}
            title={''}
            showAlert={
              event?.tipo !== 'Cumpleaños' && event?.tipo !== 'Videollamada'
            }
            exitTo="/tabs/historial"
            action={() => setReset}
          />

          {event?.tipo !== 'Videollamada' && (
            <IonIcon
              // disabled={loading}
              icon={trashOutline}
              className="delete"
              onClick={() => setDelAlertOpen(true)}
            />
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {event ? (
          <>
            <div className="edit-event-page__content ion-padding">
              {formatTitle(event.tipo) !== 'Cumpleaños' ? (
                <div className="edit-event-page__content__title ion-margin-bottom">
                  {formatTitle(event.tipo)}
                </div>
              ) : (
                <div className="edit-event-page__content__birthday-img ion-padding">
                  <img src={Birthday} alt="Birthday" />
                </div>
              )}

              {(() => {
                switch (event.tipo) {
                  case 'Cria':
                    return (
                      <CrearEventoCriaForm
                        changeReset={setReset}
                        doReset={reset}
                        onSubmit={sendEditEvent}
                        onEditMode={true}
                        defaultForm={{
                          description: event.description,
                          machos: event.eventoCria.machos,
                          hembras: event.eventoCria.hembras,
                          fallecidos: event.eventoCria.fallecidos,
                          fecha: event.event_on,
                          hora: event.event_on,
                          done: event.done,
                        }}
                      />
                    );
                  case 'Vacuna':
                    return (
                      <CrearEventoVacunaForm
                        changeReset={setReset}
                        doReset={reset}
                        onSubmit={sendEditEvent}
                        onEditMode={true}
                        defaultForm={{
                          description: event.description,
                          fecha: event.event_on,
                          hora: event.event_on,
                          done: event.done,
                          VacunaId: event.eventoVacuna.vacunaId,
                          proxima_aplicacion:
                            event.eventoVacuna.proxima_aplicacion,
                        }}
                        idPet={event.mascota.toString()}
                      />
                    );
                  case 'DespExterna':
                    return (
                      <CrearEventoDesparasitacionForm
                        changeReset={setReset}
                        doReset={reset}
                        onSubmit={sendEditEvent}
                        onEditMode={true}
                        defaultForm={{
                          description: event.description,
                          fecha: event.event_on,
                          hora: event.event_on,
                          done: event.done,
                          ProductoDesparacitacionId:
                            event.eventoDespExterna.productoDesparacitacionId,
                          refuerzo: event.eventoDespExterna.refuerzo,
                        }}
                        idPet={event.mascota.toString()}
                      />
                    );
                  case 'DespInterna':
                    return (
                      <CrearEventoDesparasitacionForm
                        changeReset={setReset}
                        doReset={reset}
                        onSubmit={sendEditEvent}
                        onEditMode={true}
                        defaultForm={{
                          description: event.description,
                          fecha: event.event_on,
                          hora: event.event_on,
                          done: event.done,
                          ProductoDesparacitacionId:
                            event.eventoDespInterna.productoDesparacitacionId,
                          refuerzo: event.eventoDespInterna.refuerzo,
                        }}
                        idPet={event.mascota.toString()}
                      />
                    );
                  case 'Cumpleaños':
                    return (
                      <p className="edit-event-page__cumpleaños">
                        {settingAge(event)}
                      </p>
                    );
                  case 'Videollamada':
                    return (
                      <VideoLlamadaForm
                        defaultForm={{
                          fecha: event.event_on,
                          hora: event.event_on,
                          done: event.done,
                          infoVeterinario:
                            event.eventoVideollamada.infoVeterinario,
                        }}
                      />
                    );
                  default:
                    return (
                      <CrearEventoConsultaForm
                        changeReset={setReset}
                        doReset={reset}
                        onSubmit={sendEditEvent}
                        onEditMode={true}
                        defaultForm={{
                          description: event.description,
                          fecha: event.event_on,
                          hora: event.event_on,
                          done: event.done,
                        }}
                      />
                    );
                }
              })()}
              <IonToast
                // position="top"
                isOpen={toast.visible}
                onDidDismiss={() => setToast({ ...toast, visible: false })}
                message={toast.message}
                cssClass={
                  'edit-event-page' +
                  (toast.error ? '__toast-error' : '__toast-success')
                }
                buttons={[
                  {
                    side: 'start',
                    icon: toast.icon as any,
                  },
                ]}
                duration={3000}
              />
            </div>
            <div>
              <DeleteAlert
                delText={' el evento'}
                isOpen={isDelAlertOpen}
                setOpen={setDelAlertOpen}
                action={() => eliminarEvento()}
              />
            </div>
          </>
        ) : error ? (
          <NotFound />
        ) : (
          <div style={{ textAlign: 'center' }}>
            <IonSpinner className="edit-event-page__spinner"></IonSpinner>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default EditEventPage;
