import React, { useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';

import { CameraSource } from '@capacitor/camera';
import {
  IonAlert,
  IonContent,
  IonIcon,
  IonPage,
  IonSpinner,
  useIonRouter,
} from '@ionic/react';

import {
  heart,
  location,
} from 'ionicons/icons';

import { useGetPet, useProfilePic } from '../../hooks';

import 'animate.css';
import './PetDetailsPage.scss';

import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import Shield from '../../assets/shield.png';
import Nutrition from '../../assets/pet-food3.png';
import Health from '../../assets/health-insurance.png';
import MedicalRecord from '../../assets/medical-record.png';

import NavigationBar from '../../components/NavigationBar';
import GestionSeguro from '../../components/GestionSeguro';
import NutricionMenu from '../../components/NutricionMenu';
import PetDetailsOption from '../../components/PetDetailsOption';

type ParamsType = {
  id: string;
};

const PetDetailsPage: React.FC = () => {
  const { push } = useIonRouter();
  const { id: petId } = useParams<ParamsType>();
  const { pet, mutate } = useGetPet(petId);

  const {
    animal,
    img_link,
    nombre,
    sexo,
    fecha_nacimiento,
    raza,
  } = pet || {};
  const { updateProfilePic, isUpdating } = useProfilePic();

  const [showCameraAlert, setShowCameraAlert] = useState<boolean>(false);

  const [gestionSeguroPopoverState, setGestionSeguroPopoverState] = useState({
    showPopover: false,
    event: undefined,
  });

  const [nutricionPopoverState, setNutricionPopoverState] = useState({
    showPopover: false,
    event: undefined,
  });

  const placeholder =
    animal === 'Perro'
      ? DogPlaceholder
      : animal === 'Gato'
        ? CatPlaceholder
        : undefined;

  let petProfilePic = img_link ? (
    <img src={img_link} alt={nombre} />
  ) : (
    <img src={placeholder} alt={nombre} />
  );

  function calculoEdad() {
    const fechaNacimiento = moment(fecha_nacimiento);
    const fechaActual = moment();

    const years = fechaActual.diff(fechaNacimiento, 'years');
    const months = fechaActual.diff(fechaNacimiento, 'months');

    if (years >= 1) {
      return `${years} años`;
    } else {
      return `${months} meses`;
    }
  }

  const formattedSexo = sexo === 'M' ? "Macho" : "Hembra";

  function goTo(path: string) {
    push(`/tabs/${path}/${petId}`, 'forward', 'push');
  }

  async function newProfilePicHandler(sourceType) {
    await updateProfilePic(sourceType, petId);
    mutate();
  }

  return (
    <IonPage className="pet-details-page">
      <NavigationBar goBack={true} uploadPetImg={true} setShowCameraAlert={setShowCameraAlert} />
      <IonContent fullscreen>
        <div className="pet-details-page__img-container ion-margin-top animate__animated animate__pulse">
          <div className="profile">{petProfilePic}</div>
        </div>

        {isUpdating && (
          <div className="pet-details-page__image-updating">
            <IonSpinner color="light" />
          </div>
        )}

        <div className="ion-padding-horizontal">
          <div className='pet-details-page__details ion-padding-horizontal'>

            <div className='top-data'>
              <div className='nombre'>
                <p>{nombre}</p>
                <span><IonIcon icon={location} /> Rosario, Santa Fe, Argentina.</span>
              </div>

              <IonIcon icon={heart} color='danger' />
            </div>

            <div className='data-boxes'>
              <div className='box'>
                <div className='title'>Edad</div>
                <div className='data'>{calculoEdad()}</div>
              </div>

              <div className='box'>
                <div className='title'>Raza</div>
                <div className='data'>{raza}</div>
              </div>

              <div className='box'>
                <div className='title'>Sexo</div>
                <div className='data'>{formattedSexo}</div>
              </div>
            </div>
          </div>

          <PetDetailsOption action={() => setGestionSeguroPopoverState({ showPopover: true, event: undefined })} img={Shield} title={`Gestionar seguro de ${pet?.nombre}`} description={`Accede al carnet de asociado de ${pet?.nombre}, amplia tu plan de cobertura, modifica medios de pago y más!`} />

          <PetDetailsOption action={() => goTo('siniestros/new')} img={Health} title={"Nuevo accidente o enfermedad"} description={"Denuncia un accidente o enfermedad."} />

          <PetDetailsOption action={() => goTo('siniestros/show-siniestros')} img={MedicalRecord} title={"Historial de siniestros"} description={`Gestioná las enfermedades y accidentes de ${pet?.nombre} que ya están cargadas, agregá gastos, y más!`} />

          <PetDetailsOption action={() => setNutricionPopoverState({ showPopover: true, event: undefined })} img={Nutrition} title={"Nutrición"} description={`Controla el peso y la alimentación de ${nombre}`} />
        </div>

        <GestionSeguro pet={pet} gestionSeguroPopoverState={gestionSeguroPopoverState} setGestionSeguroPopoverState={setGestionSeguroPopoverState} />

        <NutricionMenu pet={pet} mutate={mutate} nutricionPopoverState={nutricionPopoverState} setNutricionPopoverState={setNutricionPopoverState} />

        <IonAlert
          mode='ios'
          isOpen={showCameraAlert}
          onDidDismiss={() => setShowCameraAlert(false)}
          header="Indique desde donde subir la imagen"
          message="Por favor seleccione"
          buttons={[
            {
              text: 'Camara',
              handler: () => {
                newProfilePicHandler(CameraSource.Camera)
              },
            },
            {
              text: 'Galería',
              handler: () => {
                newProfilePicHandler(CameraSource.Photos)
              },
            },
          ]}
        />
      </IonContent>
    </IonPage >
  );
};

export default PetDetailsPage;
