import React from 'react';

import { IonCol, IonGrid, IonRow } from '@ionic/react';
import moment from 'moment';

import ButtonEvents from '../ButtonEvents';
import './EventContainer.scss';
import { EventContainerProps } from './types';

const EventContainer: React.FC<EventContainerProps> = ({
  done,
  tipo,
  date,
  icon,
  pet,
  ev,
  animal,
  id,
  idEvent,
}) => {
  return (
    <div className="event-container">
      <IonGrid className='ion-no-padding'>
        <IonRow className="event-container__rowTitle">
          <IonCol>
            {moment(date).format('MMMM YYYY').substring(0, 1).toUpperCase() +
              moment(date).format('MMMM YYYY').substring(1)}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <ButtonEvents
              done={done}
              tipo={tipo}
              label={ev}
              pet_img={pet}
              event_icon={icon}
              dia={date}
              numero={date}
              animal={animal}
              petId={id}
              eventoId={idEvent}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default EventContainer;
