import React, { useState } from 'react';
import { useParams } from 'react-router';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { useFoodBagsPet, usePet } from '../../hooks';
// import {
//   getCalorias,
//   getConsumoFromBag,
//   getLastWeight,
// } from '../../utils/PetUtilities';
import FoodInfoItem from '../../components/FoodInfoItem';
import WeightButtons from '../../components/WeightButtons';
import NewWeightAlert from '../../components/NewWeightAlert';
import WeightHistoryModal from '../../components/WeightHistoryModal';

import './NutricionalInfoPage.scss';
import { useMascotas } from '../../context/MascotasContext';

type ParamsType = {
  id: string;
};

const NutricionalInfoPage: React.FC = () => {
  const { id } = useParams<ParamsType>();
  const {mascotas:{pets}} = useMascotas();
  const pet = pets?.find((m)=> m.id.toString() === id);
  // const lastWeight = pet && getLastWeight(pet);
  const { foodBags: foodBagList } = useFoodBagsPet(id);
  // const cal = foodBagList && getCalorias(foodBagList);

  // const [isNewWeightOpen, setNewWeightOpen] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  // const [isWeightHistoryOpen, setWeightHistoryOpen] = useState(false);

  return (
    <></>
    // <IonPage>
    //   <IonHeader>
    //     <IonToolbar color="primary">
    //       <IonButtons slot="start">
    //         <IonBackButton defaultHref="/" />
    //       </IonButtons>
    //       {pet && <IonTitle>Plan nutricional de {pet.nombre}</IonTitle>}
    //     </IonToolbar>
    //   </IonHeader>

    //   <IonContent>
    //     <div className="nutricional-info-page">
    //       {/* Plan nutricional */}
    //       <p className="nutricional-info-page__title">Plan Nutricional</p>
    //       {foodBagList?.map((food) => (
    //         <FoodInfoItem
    //           key={food.id}
    //           food={food}
    //           detail={
    //             'Come ' +
    //             getConsumoFromBag(foodBagList, food.id) +
    //             ' gramos diarios.'
    //           }
    //         />
    //       ))}

    //       <hr className="nutricional-info-page__divider" />

    //       {/* Consumo diario y peso */}
    //       <div className="nutricional-info-page__block">
    //         {/* consumo diario */}
    //         <div>
    //           {pet && (
    //             <>
    //               <p className="nutricional-info-page__title">Consumo diario</p>
    //               <div className="nutricional-info-page__info">
    //                 <PetDetailCardItem
    //                   bottomText="kcal"
    //                   topText={`${cal}`}
    //                 ></PetDetailCardItem>
    //               </div>
    //             </>
    //           )}
    //         </div>
    //         {/* peso */}
    //         <div>
    //           {lastWeight && (
    //             <div>
    //               <p className="nutricional-info-page__title">Peso actual</p>
    //               <div className="nutricional-info-page__info">
    //                 <PetDetailCardItem
    //                   bottomText="kg"
    //                   topText={`${lastWeight}`}
    //                 ></PetDetailCardItem>
    //               </div>
    //             </div>
    //           )}

    //           {!lastWeight && pet && (
    //             <div>
    //               <p className="nutricional-info-page__title">Peso actual</p>
    //               <div className="nutricional-info-page__info">
    //                 <PetDetailCardItem
    //                   bottomText="kcal"
    //                   topText="?"
    //                 ></PetDetailCardItem>
    //               </div>
    //               <p className="nutricional-info-page__noInfo">
    //                 (?) No has cargado peso
    //               </p>
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //       <WeightButtons
    //         setWeightHistoryOpen={setWeightHistoryOpen}
    //         setNewWeightOpen={setNewWeightOpen}
    //         isLoading={isLoading}
    //       ></WeightButtons>
    //     </div>

    //     <NewWeightAlert
    //       petId={id}
    //       isOpen={isNewWeightOpen}
    //       setOpen={setNewWeightOpen}
    //       setLoading={setLoading}
    //     />
    //     <WeightHistoryModal
    //       isOpen={isWeightHistoryOpen}
    //       setOpen={setWeightHistoryOpen}
    //       weightHistory={pet?.historial_peso || []}
    //     />
    //   </IonContent>
    // </IonPage>
  );
};

export default NutricionalInfoPage;
