import React from 'react';

import { IonButton, IonPopover } from '@ionic/react';
import 'animate.css';

import FielPetLogo from '../../assets/FielPetLogo.png';
import Clock from '../../assets/clock.png';
import { capitalizeFirstLetter } from '../../utils/General';
import './EditDataPopover.scss';
import { EditDataPopoverProps } from './types';

const EditDataPopover: React.FC<EditDataPopoverProps> = ({
  editDataPopoverState,
  setEditDataPopoverState,
  callback,
  nonNullFields,
  message,
}) => {
  const clickHandler = () => {
    setEditDataPopoverState({ showPopover: false, event: undefined });
  };

  return (
    <div className="edit-data-popover">
      <IonPopover
        mode="ios"
        cssClass="edit-data-popover animate__animated animate__zoomIn"
        event={editDataPopoverState.event}
        isOpen={editDataPopoverState.showPopover}
        onDidDismiss={() =>
          setEditDataPopoverState({ showPopover: false, event: undefined })
        }
      >
        <img src={FielPetLogo} />
        <div className="edit-data-popover__title">
          Usted esta intentando modificar los siguientes datos:
        </div>
        <div className="edit-data-popover__itemsContainer">
          {nonNullFields?.map((field, index) => {
            // Exceptions
            let displayKey = field.key;
            let displayValue = field.value;

            if (field.key === 'genero' || field.key === 'sexo') {
              displayValue =
                field.value === 'H'
                  ? 'Hembra'
                  : field.value === 'M'
                  ? 'Macho'
                  : field.value;
            } else if (field.key === 'numero') {
              displayKey = 'Altura';
            } else if (field.key === 'fechaNac') {
              displayKey = 'Fecha Nacimiento';
            } else {
              displayValue = capitalizeFirstLetter(String(field.value));
            }

            return (
              <div className="item" key={index}>
                <span className="key">
                  {capitalizeFirstLetter(displayKey)}:{' '}
                  {/* Usar displayKey aquí */}
                </span>
                {displayValue}
              </div>
            );
          })}
        </div>

        <div className="edit-data-popover__messageContainer">
          <img src={Clock} />
          <div>{message}</div>
        </div>

        <IonButton
          mode="ios"
          expand="block"
          onClick={() => callback && callback()}
        >
          Enviar solicitud
        </IonButton>

        <IonButton
          onClick={clickHandler}
          mode="ios"
          expand="block"
          fill="outline"
          color="danger"
        >
          Cancelar
        </IonButton>
      </IonPopover>
    </div>
  );
};

export default EditDataPopover;
