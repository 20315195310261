import React, { useEffect }  from 'react';
import { RouteComponentProps } from 'react-router';

import { IonPage } from '@ionic/react';

import { SiniestroContextProvider } from '../../context/SiniestroContext';
import './MenuSiniestroPage.scss';
import MenuSiniestroWrapper  from '../../components/MenuSiniestroWrapper';
type ParamsType = RouteComponentProps<{ id: string }>;

const MenuSiniestroPage: React.FC<ParamsType> = ({ match,children }) => {

  const siniestroId = match.params.id;
  return siniestroId ? (
    <IonPage className="menu-siniestro-page">
      <SiniestroContextProvider siniestroId={siniestroId}>
      <MenuSiniestroWrapper />
      </SiniestroContextProvider>
    </IonPage>  
  ) : <IonPage><p>Esperando siniestro</p>  </IonPage>;
};
export default MenuSiniestroPage;
