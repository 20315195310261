import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import {
  IonPage,
  IonContent,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

import CrearEventoConsultaForm from '../../../components/CrearEventoForms/CrearEventoConsultaForm';
import CrearEventoCriaForm from '../../../components/CrearEventoForms/CrearEventoCriaForm';
import CrearEventoDesparasitacionForm from '../../../components/CrearEventoForms/CrearEventoDesparasitacionForm';
import CrearEventoVacunaForm from '../../../components/CrearEventoForms/CrearEventoVacunaForm';
import { CrearEventoFormContext } from '../../../context/CrearEventoContext';
import { useEvento } from '../../../hooks/useEvento';
import { CrearEventoSecondFormInterface } from '../../../interfaces/CrearEvento';
import { concatDateAndHours } from '../../../utils/DateUtilities';
import './CrearEvento2Page.scss';

const CrearEvento2Page: React.FC = () => {
  const { crearEventoFormValues, setCrearEventoFormValues } = useContext(
    CrearEventoFormContext
  );
  const { createEvent } = useEvento();
  const [toast, setToast] = useState({
    visible: false,
    message: '',
    icon: '',
    error: false,
  });
  const history = useHistory();

  const onSubmit = async (form: CrearEventoSecondFormInterface) => {
    if (crearEventoFormValues) {
      const { description, ...newForm } = form;
      const { done, event_on, hora, ...form1 } = crearEventoFormValues;
      concatDateAndHours(event_on, hora);
      /* This is made because we cannot have boolean value in a Controller */
      const doneToBoolean = (d: any) => d === 'si';
      const send = {
        done: doneToBoolean(done),
        event_on: concatDateAndHours(event_on, hora),
        description,
        hora,
        ...form1,
        ['Evento' + form1.tipo]: newForm,
      };
      try {
        await createEvent(send);
        setToast({
          visible: true,
          message: 'El evento ha sido creado con exito',
          icon: checkmarkOutline,
          error: false,
        });
        setTimeout(() => history.replace('/tabs/historial'), 500);
        setCrearEventoFormValues(undefined);
      } catch (e) {
        setToast({
          visible: true,
          message: 'Hubo un error, intente de nuevo mas tarde',
          icon: closeOutline,
          error: true,
        });
      }
    }
  };
  useEffect(() => {
    if (
      !crearEventoFormValues &&
      history.location.pathname === '/crear-evento/2'
    )
      // Outdated comment?
      /** This maybe will not be necesary with the 5.4 ionic release */
      history.replace('/crear-evento/1');
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeMd="6" offsetMd="3" sizeLg="4" offsetLg="4">
              {(() => {
                switch (crearEventoFormValues?.tipo) {
                  case 'Cria':
                    return <CrearEventoCriaForm onSubmit={onSubmit} />;
                  case 'Vacuna':
                    return (
                      <CrearEventoVacunaForm
                        dateEvent={crearEventoFormValues.event_on}
                        onSubmit={onSubmit}
                      />
                    );
                  case 'DespExterna':
                    return (
                      <CrearEventoDesparasitacionForm
                        dateEvent={crearEventoFormValues.event_on}
                        onSubmit={onSubmit}
                      />
                    );
                  case 'DespInterna':
                    return (
                      <CrearEventoDesparasitacionForm
                        dateEvent={crearEventoFormValues.event_on}
                        onSubmit={onSubmit}
                      />
                    );
                  default:
                    return <CrearEventoConsultaForm onSubmit={onSubmit} />;
                }
              })()}
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonToast
          // position="top"
          isOpen={toast.visible}
          onDidDismiss={() => setToast({ ...toast, visible: false })}
          message={toast.message}
          cssClass={
            'crear-evento-2-page' +
            (toast.error ? '__toast-error' : '__toast-success')
          }
          buttons={[
            {
              side: 'start',
              icon: toast.icon as any,
            },
          ]}
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default CrearEvento2Page;
