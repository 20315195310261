import React, { useState } from 'react';
import {  useParams } from 'react-router';

import { IonContent, IonPage, useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react';

import FormAddSiniestro from '../../components/FormAddSiniestro';
import NavigationBar from '../../components/NavigationBar';
import ToastAlert from '../../components/ToastAlert';
import './AddSiniestroPage.scss';

type ParamsType = {
  id: string;
};

const AddSiniestroPage: React.FC = () => {
  const { id } = useParams<ParamsType>();

  const [newSiniestroId, setNewSiniestroId] = useState<string>();
  const [showOkToast, setShowOkToast] = useState<boolean>(false);
  const [showFailToast, setShowFailToast] = useState<boolean>(false);
  const [errorMessage,setErrorMessage] = useState<string>('');

  /**
   * logica artificial para forzar el reseteo de este componente porque
   * ionic reutiliza las paginas y no siempre las re renderiza.
   */
  const [showForm,setShowForm] = useState<boolean>(true); 
  useIonViewWillEnter(()=>{ setShowForm(true); });
  useIonViewDidLeave(()=>{ setShowForm(false); });

  return (
    <IonPage className="add-siniestro-page">
      <NavigationBar goBack={true} />
      <IonContent fullscreen>
      {showForm && <FormAddSiniestro
          id={id}
          showOkToast={showOkToast}
          setShowOkToast={setShowOkToast}
          setShowFailToast={setShowFailToast}
          setErrorMessage={setErrorMessage}
          setNewSiniestroId={setNewSiniestroId}
        /> }

        <ToastAlert
          isOpen={showOkToast}
          setShowToast={setShowOkToast}
          message="El siniestro fue cargado correctamente!"
          color="success"
          duration={3500}
          path="siniestros/menu-siniestro"
          id={newSiniestroId}
        />

        <ToastAlert
          isOpen={showFailToast}
          setShowToast={setShowFailToast}
          message={`Lo sentimos, el siniestro no pudo ser cargado.`}
          color="danger"
          duration={4500}
          path="pet-details"
          id={id}
        />
      </IonContent>
    </IonPage>
  );
};

export default AddSiniestroPage;
