import { useState, useEffect } from 'react';

import { getLocalidades as axiosGetLocalidades } from '../axios/getLocalidades';
import { SearchbarOption } from '../components/Searchbar/types';
import { LocalidadRes } from '../interfaces/Localidad';

export const useGetLocalidades = (
  initialProvince?: string,
  idToRestore?: number
) => {
  const [localidades, setLocalidades] = useState<LocalidadRes[]>([]);
  const [initialLocalidad, setInitialLocalidad] = useState<SearchbarOption>();
  const [isLoading, setLoading] = useState(false);

  const getLocalidades = async (province: string) => {
    if (!province) {
      setLocalidades([]);
      setInitialLocalidad(undefined);
      return;
    }
    setLoading(true);
    const localidades = await axiosGetLocalidades(province);
    setLocalidades(localidades);
    if (idToRestore) {
      const foundLocalidad = localidades.find(
        (localidad) => localidad.id === idToRestore
      );
      if (foundLocalidad) {
        setInitialLocalidad({
          label: foundLocalidad.nombre,
          value: foundLocalidad.id,
        });
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (initialProvince) {
      getLocalidades(initialProvince);
    }
  }, [initialProvince, idToRestore]);

  const localidadesOptions : SearchbarOption[] = localidades.map((localidad) => ({
    value: String(localidad.id),
    label: localidad.nombre,
  }));

  return {
    isLoading,
    initialLocalidad,
    localidades: localidadesOptions,
    getLocalidades,
  };
};
