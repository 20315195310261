import React, { useState } from 'react';

import { IonHeader, IonItem, IonButton, IonIcon, IonAlert, IonToolbar, useIonRouter } from '@ionic/react';
import { cameraSharp, chevronBackOutline } from 'ionicons/icons';

import Logo from '../../assets/FielPetSinLogo.png';
import './NavigationBar.scss';
import { NavigationBarProps } from './types';

const NavigationBar: React.FC<NavigationBarProps> = ({
  path,
  goBack,
  id,
  step,
  setStep,
  hideBack,
  exit,
  uploadPetImg,
  setShowCameraAlert
}) => {
  const history = useIonRouter()
  const [showAlert, setShowAlert] = useState<boolean>(false);

  function switchAction() {
    if (!step && !exit) {
      if (goBack) { history.back(); }
      else {
        const _pushTo = `/tabs/${path}`;
        const pushTo = id ? `${_pushTo}/${id}` : _pushTo;
        history.push(pushTo, 'back', 'pop');
      }
    } else if (step && setStep && step > 0) {
      setStep(0);
    } else if (exit) {
      setShowAlert(true);
    } else {
      history.back();
    }
    exit && setShowAlert(true);
  }

  return (
    <div className="navigation-bar">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonItem lines="none">
            {!hideBack && (
              <IonButton
                slot="start"
                fill="clear"
                color="dark"
                onClick={() => switchAction()}
              >
                <IonIcon slot="icon-only" icon={chevronBackOutline} />
              </IonButton>
            )}
            {!uploadPetImg ? <IonButton slot="end" fill="clear">
              <img src={Logo} />
            </IonButton> :
              <IonButton slot="end" fill="clear" onClick={() => setShowCameraAlert && setShowCameraAlert(true)}>
                <IonIcon
                  className="upload-icon"
                  icon={cameraSharp}
                  color='dark'
                />
              </IonButton>
            }
          </IonItem>
        </IonToolbar>
      </IonHeader>

      <IonAlert
        mode="ios"
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="new-generic-alert"
        header={'Espere'}
        subHeader={'Se perderán los cambios'}
        message={'¿Está seguro que desea salir?'}
        buttons={[
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              setShowAlert(false);
            },
          },
          {
            text: 'Confirmar',
            handler: () => {
              history.back();
            },
          },
        ]}
      />
    </div>
  );
};

export default NavigationBar;
