import React, { useState } from 'react';
import 'animate.css';

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

import { FoodInfoItemProps } from './types';
import './FoodInfoItem.scss';
import FoodInfoItemModal from '../FoodInfoItemModal';
import PetFood from '../../assets/pet-food1.png';

const FoodInfoItem: React.FC<FoodInfoItemProps> = ({
  pets,
  food,
  openDelAlert,
  setId,
  isDeleting,
  idDeleting,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { nombre, marca } = food.alimento;

  const delBtnClicked = (id: number) => {
    if (openDelAlert && setId) {
      setId(id);
      openDelAlert(true);
    }
  };

  return (
    <div
      className="food-info-item ion-margin ion-padding"
    >
      <IonGrid className="ion-no-padding" onClick={() => setShowModal(true)}>
        <IonRow>
          <IonCol size="3.5">
            <img src={PetFood} />
          </IonCol>
          <IonCol>
            <div className="food-info-item__info-box">
              <div className='ion-padding-end'>
                <p className="food-info-item__nombre">{nombre}</p>
              </div>
              <p className="food-info-item__marca">{marca}</p>
              <p className="food-info-item__editar">Presione para editar</p>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonButton
        className="food-info-item__delete"
        fill="clear"
        onClick={(e) => {
          delBtnClicked(food.id);
          e.stopPropagation();
        }}
        hidden={!openDelAlert}
      >
        {!isDeleting || idDeleting !== food.id ? (
          <IonIcon icon={closeOutline} color="danger" />
        ) : null}
        {isDeleting && idDeleting === food.id ? <IonSpinner /> : null}
      </IonButton>

      <FoodInfoItemModal open={showModal} setShowModal={setShowModal} pets={pets} food={food} />
    </div>
  );
};

export default FoodInfoItem;
