import React from 'react';

import { differenceBetw } from '../../utils/DateUtilities';
import { formatTitle } from '../../utils/eventosUtils';
import { getEventIcon } from '../../utils/timelineUtils';
import ButtonEvents from '../ButtonEvents';
import EventContainer from '../EventContainer';
import './TimeHistorial.scss';
import { TimeHistorialProps } from './types';

const TimeHistorial: React.FC<TimeHistorialProps> = ({ eventos, pets }) => {
  const monthDidChange = (index: number) => {
    if (!index || index === 0) {
      return true;
    }
    if (eventos) {
      return differenceBetw(
        eventos[index - 1].event_on,
        eventos[index].event_on
      );
    }
  };

  const getImgLink = (id: number) => {
    const filt = pets.find((pet) => pet.id === id);
    if (filt) {
      return filt.img_link;
    } else {
      return '';
    }
  };

  return (
    <div className="time-historial">
      {eventos
        ? eventos.map(({ tipo, id, event_on, Mascotum, done }, index) =>
          monthDidChange(index) ? (
            <EventContainer
              done={done}
              tipo={tipo}
              key={id}
              date={event_on}
              icon={getEventIcon(tipo)}
              pet={getImgLink(Mascotum.id)}
              ev={formatTitle(tipo)}
              animal={Mascotum.animal}
              id={Mascotum.id}
              idEvent={id}
            />
          ) : (
            <ButtonEvents
              done={done}
              tipo={tipo}
              key={id}
              label={formatTitle(tipo)}
              event_icon={getEventIcon(tipo)}
              pet_img={getImgLink(Mascotum.id)}
              dia={event_on}
              numero={event_on}
              animal={Mascotum.animal}
              petId={Mascotum.id}
              eventoId={id}
            />
          )
        )
        : null}
    </div>
  );
};

export default TimeHistorial;
