import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonDatetime,
  IonToggle,
} from '@ionic/react';

import { CrearEventoFormContext } from '../../../context/CrearEventoContext';
import { useGetProductosDesparasitacion } from '../../../hooks/useGetProductosDesparasitacion';
import { CrearEventoConsultaFormInterface } from '../../../interfaces/CrearEvento';
import {
  maxDateEvent,
  minDateEvent,
  today,
  sortDates,
} from '../../../utils/DateUtilities';
import FormItem from '../../FormItem';
import TextBox from '../../TextBox';
import CrearEventoFooter from '../CrearEventoFooter';
import './CrearEventoDesparasitacionForm.scss';
import { CrearEventoDesparasitacionFormProps } from './types';

const CrearEventoDesparasitacionForm: React.FC<CrearEventoDesparasitacionFormProps> = ({
  onSubmit,
  defaultForm,
  onEditMode = false,
  changeReset,
  doReset,
  idPet,
  dateEvent,
}) => {
  const { control, errors, handleSubmit, reset } = useForm<
    CrearEventoConsultaFormInterface
  >({ defaultValues: defaultForm });

  const [realizado, setRealizado] = useState(
    defaultForm ? defaultForm.done : true
  );

  useEffect(() => {
    reset();
    !changeReset || changeReset(false);
  }, [doReset]);

  const { crearEventoFormValues } = useContext(CrearEventoFormContext);

  const [minDateRefuerzo, setMinDateRefuerzo] = useState(
    dateEvent ? dateEvent : onEditMode ? defaultForm?.fecha : minDateEvent(true)
  );

  const { productosDesparasitacion } = useGetProductosDesparasitacion(
    idPet ?? crearEventoFormValues?.mascota?.toString() ?? ''
  );

  useEffect(() => {
    control.setValue('ProductoDesparacitacionId', -1);
    setTimeout(() =>
      control.setValue(
        'ProductoDesparacitacionId',
        defaultForm?.ProductoDesparacitacionId
      )
    );
  }, [productosDesparasitacion]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="crear-evento-desparasitacion-form ion-padding">
        {onEditMode ? (
          <>
            <div className="ion-margin-bottom">
              <FormItem
                render={({ onChange, value }) => (
                  <div>
                    <IonLabel>Fecha</IonLabel>
                    <IonItem style={{ flexGrow: 1 }} className="ion-no-padding">
                      <IonDatetime
                        value={value}
                        displayFormat="DD/MM/YYYY"
                        cancelText="Cancelar"
                        doneText="Ok"
                        onIonChange={(e) => {
                          onChange(e.detail.value);
                          setMinDateRefuerzo(control.getValues('fecha'));
                          if (e.detail.value) {
                            if (
                              sortDates(
                                e.detail.value,
                                control.getValues('refuerzo')
                              )
                            ) {
                              control.setValue('refuerzo', undefined);
                            }
                          }
                        }}
                        max={maxDateEvent(realizado ?? false)}
                        min={minDateEvent(realizado ?? false)}
                        className="ion-no-padding"
                      ></IonDatetime>
                    </IonItem>
                  </div>
                )}
                control={control}
                name="fecha"
                errors={errors}
              />
            </div>

            <FormItem
              render={({ onChange, value }) => (
                <div>
                  <IonLabel>Hora</IonLabel>
                  <IonItem style={{ flexGrow: 1 }} className='ion-no-padding'>
                    <IonDatetime
                      value={value}
                      minuteValues="0,15,30,45"
                      displayFormat="HH:mm"
                      pickerFormat="HH:mm"
                      cancelText="Cancelar"
                      doneText="Ok"
                      onIonChange={onChange}
                      className="ion-no-padding"
                    ></IonDatetime>
                  </IonItem>
                </div>
              )}
              control={control}
              name="hora"
              errors={errors}
            />

            <FormItem
              render={() => (
                <IonItem
                  lines="none"
                  hidden={defaultForm ? defaultForm.done : true}
                  className="ion-no-padding"
                >
                  <IonLabel color='primary'>¿Realizado?</IonLabel>
                  <IonToggle
                    mode='ios'
                    checked={realizado}
                    onIonChange={(e) => {
                      setRealizado(e.detail.checked);
                      control.setValue('done', e.detail.checked);
                      control.setValue('fecha', today);
                      control.setValue('hora', today);
                    }}
                  />
                </IonItem>
              )}
              name="done"
              control={control}
            />
          </>
        ) : null}
        <div className="ion-margin-bottom">
          <FormItem
            name="ProductoDesparacitacionId"
            control={control}
            errors={errors}
            render={({ onChange, name, value }) => (
              <div>
                <IonLabel className="ion-no-padding">Producto</IonLabel>
                <IonSelect
                  name={name}
                  value={value}
                  onIonChange={onChange}
                  placeholder="Seleccione el producto"
                  interface="popover"
                  className="ion-no-padding ion-padding-top"
                // style={{ textAlign: 'right' }}
                >
                  {productosDesparasitacion?.map((event) => (
                    <IonSelectOption key={event.id} value={event.id}>
                      {event.nombre}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: 'Por favor, complete este campo.',
              },
            }}
          />
        </div>
        {onEditMode && !defaultForm?.refuerzo ? null : (
          <div className="ion-margin-bottom">
            <FormItem
              name="refuerzo"
              control={control}
              errors={errors}
              render={({ onChange, value, name }) => (
                <div>
                  <IonLabel>Refuerzo</IonLabel>
                  <IonItem style={{ flexGrow: 1 }} className="ion-no-padding">
                    <IonDatetime
                      doneText="Ok"
                      cancelText="Cancelar"
                      value={value}
                      name={name}
                      className="ion-no-padding"
                      picker-format="DD MM YYYY"
                      displayFormat="DD/MM/YYYY"
                      placeholder="Seleccione fecha"
                      onIonChange={onChange}
                      min={minDateRefuerzo}
                      max={maxDateEvent(false)}
                    />
                  </IonItem>
                </div>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, complete este campo.',
                },
              }}
            />
          </div>
        )}
        <FormItem
          name="description"
          control={control}
          errors={errors}
          render={({ onChange, value, name }) => (
            <>
              <IonLabel>Descripción</IonLabel>
              <TextBox onIonChange={onChange} value={value} name={name} />
            </>
          )}
        />
      </div>
      {onEditMode ? <CrearEventoFooter onEdit={true} /> : <CrearEventoFooter />}
    </form>
  );
};

export default CrearEventoDesparasitacionForm;
