import { useState } from 'react';
import { postSiniestro as axiosPostSiniestro } from '../axios/siniestros';
import { SiniestroAddFormToSendType } from '../interfaces/forms/SiniestroForm';

export const useAddSiniestros = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const addSiniestro = async (
    form_siniestro: SiniestroAddFormToSendType,
  ) => {
    if (isLoading){
      return Promise.reject('awaiting prior reply');
    }
    const datos_siniestro = {
      fecha_carga: form_siniestro.fechaEnfermedad,
      denuncia_administrativa: form_siniestro.denunciaAdministrativa,
      mascotaId : form_siniestro.mascotaId
    };

    setIsLoading(true);
    const rta = await axiosPostSiniestro({
      datos_siniestro,
    });
    setIsLoading(false);
    return rta;
  };

  return { addSiniestro, isLoading};
};
