import { useState, useEffect } from 'react';
import { getRazas as axiosGetRazas } from '../axios/getRazas';
import { SearchbarOption } from '../components/Searchbar/types';

export const useRazas = () => {
  const [razas,setRazas] = useState<{razasPerro: SearchbarOption[], razasGato: SearchbarOption[]}>({razasPerro:[],razasGato:[]});
  const getRazas = async () => {
    const razas = await axiosGetRazas();
    const search_razas = razas.map(({ id, nombre, animal }) => ({
      id,
      nombre,
      animal,
    }));
    setRazas({razasPerro:
      search_razas
        .filter((r) => r.animal === 'Perro')
        .map(({ nombre }) => ({ value: nombre, label: nombre }))
              ,
              razasGato:
      search_razas
        .filter((r) => r.animal === 'Gato')
        .map(({ nombre }) => ({ value: nombre, label: nombre }))
    });
  };
  useEffect(() => {
    getRazas();
  }, []);

  return { razasPerro: razas.razasPerro, razasGato:razas.razasGato };
};
