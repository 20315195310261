import { postPet as axiosPostPet } from '../axios/pets';
import { PetAddForm1ToSendType } from '../interfaces/forms/PetForm1';
import { PetAddForm2Type } from '../interfaces/forms/PetForm2';
import { useState } from 'react';

export const useAddPets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const addPet = async (
    form_mascota: PetAddForm1ToSendType,
    form_pago: PetAddForm2Type,
    codVen: string
  ) => {
    const datos_mascota = {
      nombre: form_mascota.nombre,
      color: form_mascota.color,
      peso: form_mascota.peso !== undefined ? +form_mascota.peso : null,
      fecha_pesaje: form_mascota.fechaPesaje,
      sexo: form_mascota.sexo,
      fecha_nacimiento: form_mascota.fecha_nacimiento,
      animal: form_mascota.animal,
      raza: form_mascota.raza,
    };
    const datos_pago = {
      id: form_pago.medioDePago,
      plan: form_pago.plan,
    };

    let codigo_vendedor: string | null = codVen;
    if (codigo_vendedor === '') {
      codigo_vendedor = null;
    }
    setIsLoading(true);
    const rta = await axiosPostPet({
      datos_mascota,
      datos_pago,
      codigo_vendedor,
    });
    setIsLoading(false);
    return rta;
  };

  return { isLoading, addPet };
};
