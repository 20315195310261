import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { IonContent, IonPage, useIonRouter } from '@ionic/react';

import AdviceAlert from '../../components/AdviceAlert';
import CodVendAlert from '../../components/CodVendAlert';
import EditDataPopover from '../../components/EditDataPopover';
import FormAddPet_1 from '../../components/FormAddPet_1';
import FormAddPet_2 from '../../components/FormAddPet_2';
import NavigationBar from '../../components/NavigationBar';
import { useMascotas } from '../../context/MascotasContext';
import { useGetPet, usePlans, useRazas } from '../../hooks';
import { useAddPetForm } from '../../hooks/useAddPetForm';
import { useAddPets } from '../../hooks/useAddPets';
import { useAddSolicitud } from '../../hooks/useAddSolicitud';
import { useGetDatosPago } from '../../hooks/useDatosPago';
import { useGetPlanesPortal } from '../../hooks/usePlanesPortal';
import {
  emptyPetForm1,
  PetAddForm1ToSendType,
  PetDataModificationRequestToSend,
} from '../../interfaces/forms/PetForm1';
import {
  emptyPetForm2,
  PetAddForm2Type,
} from '../../interfaces/forms/PetForm2';
import { formatDateValue, nonNullFields } from '../../utils/Form';
import './AddPetPage.scss';

type ParamsType = {
  id: string;
};

const AddPetPage: React.FC = () => {
  const history = useIonRouter();
  const plans = usePlans();
  const { datosPago, isValidating } = useGetDatosPago();
  const {
    mascotas: { mutate: mutateMascotas },
  } = useMascotas();
  const { addPet, isLoading } = useAddPets();
  const { addSolicitud, isLoading: isSolicitudLoading } = useAddSolicitud();
  const { razasPerro, razasGato } = useRazas();
  const [isCondVendAlertOpen, setCondVendAlertOpen] = useState<boolean>(false);
  const [isOkAlertOpen, setOkAlertOpen] = useState<boolean>(false);
  const [isFailAlertOpen, setFailAlertOpen] = useState<boolean>(false);
  const [addPetForm_1, setAddPetForm_1] = useState(emptyPetForm1);
  const [addPetForm_2, setAddPetForm_2] = useState(emptyPetForm2);
  const [nonNullFields, setNonNullFields] = useState<nonNullFields[]>([]);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const { current, nextForm, prevForm, setCurrent } = useAddPetForm();

  //WIP edit
  const { id: petId } = useParams<ParamsType>();
  const location = useLocation();
  // Edit cobertura
  const { state: editingCobertura } = location;
  const { pet } = useGetPet(petId);
  const { planes } = useGetPlanesPortal('mariana-monaco@hotmail.com.ar');
  // End wip edit

  const goHome = () => {
    setOkAlertOpen(false);
    mutateMascotas();
    history.push('/tabs/home', 'root', 'push');
  };

  const preSubmit = (v: PetAddForm2Type) => {
    setAddPetForm_2(v);
    setCondVendAlertOpen(true);
  };

  const submit = async (codVen: string) => {
    const addPetForm_1_toSend: PetAddForm1ToSendType = {
      nombre: addPetForm_1.nombre,
      color: addPetForm_1.color,
      peso: addPetForm_1.peso,
      sexo: addPetForm_1.sexo,
      fechaPesaje: addPetForm_1.fechaPesaje,
      fecha_nacimiento: addPetForm_1.fecha_nacimiento,
      animal: addPetForm_1.animal,
      raza: addPetForm_1.raza?.label,
    };
    const ok = await addPet(addPetForm_1_toSend, addPetForm_2, codVen);
    if (ok) {
      setOkAlertOpen(true);
    } else {
      setFailAlertOpen(true);
    }
  };

  const submitSolicitud = async () => {
    try {
      const petSolicitud: PetDataModificationRequestToSend = {
        mascotaId: Number(petId),
        mascotaNombre: null,
        mascotaColor: null,
        mascotaSexo: null,
        mascotaFechaNac: null,
        mascotaEspecie: null,
        mascotaRaza: null,
      };

      nonNullFields.forEach((field: { key: string; value: any }) => {
        switch (field.key) {
          case 'nombre':
            petSolicitud.mascotaNombre = field.value;
            break;
          case 'color':
            petSolicitud.mascotaColor = field.value;
            break;
          case 'sexo':
            petSolicitud.mascotaSexo = field.value;
            break;
          case 'fecha nacimiento':
            petSolicitud.mascotaFechaNac = formatDateValue(field.value);
            break;
          case 'animal':
            petSolicitud.mascotaEspecie = field.value;
            break;
          case 'raza':
            petSolicitud.mascotaRaza = field.value;
            break;
          default:
            break;
        }
      });

      console.log('petSolicitud: ', petSolicitud);

      const ok = await addSolicitud(petSolicitud);
      ok && setOkAlertOpen(true);
    } catch (error) {
      console.error('Error submitting solicitud:', error);
      setFailAlertOpen(true);
    }
  };

  const displayCurrentForm = (currentOk: number) => {
    switch (currentOk) {
      case 0:
        return (
          <FormAddPet_1
            nextForm={nextForm}
            form1={addPetForm_1}
            setAddPetForm1={(addPetForm_1) => setAddPetForm_1(addPetForm_1)}
            razasPerro={razasPerro}
            razasGato={razasGato}
            //Edit
            pet={petId ? pet : undefined}
            setNonNullFields={setNonNullFields}
            setShowPopover={setShowPopover}
          />
        );
      case 1:
        return (
          <FormAddPet_2
            prevForm={prevForm}
            setAddPetForm2={(addPetForm_2) => setAddPetForm_2(addPetForm_2)}
            preSubmit={(addPetForm_2) => preSubmit(addPetForm_2)}
            form2={addPetForm_2}
            plans={plans}
            datosPago={datosPago}
            // planes?.find((plan) => plan.nombre === petName.toUpperCase());
            currentPlan={planes?.find((plan) => plan.nombre === 'MATEO')}
            loading={isLoading && isValidating}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    console.log('datosPago', datosPago);
  }, [datosPago]);

  return (
    <IonPage>
      {current === 0 ? (
        <NavigationBar goBack />
      ) : (
        <NavigationBar step={current} setStep={setCurrent} />
      )}
      <IonContent>
        <div className="add-pet-page__content ion-padding">
          <div className="add-pet-page__header">
            <h3>
              {editingCobertura !== undefined
                ? 'Editar cobertura'
                : !petId
                ? 'Agregar mascota'
                : 'Editar mascota'}
            </h3>
          </div>

          {displayCurrentForm(!editingCobertura ? current : current + 1)}
        </div>
        <div>
          <CodVendAlert
            isOpen={isCondVendAlertOpen}
            setOpen={setCondVendAlertOpen}
            send={(codVen) => submit(codVen)}
          />
        </div>
        <div>
          <AdviceAlert
            isOpen={isOkAlertOpen}
            setOpen={setOkAlertOpen}
            header="¡ Éxito !"
            subHeader=""
            message="Los datos de su mascota se cargaron correctamente!"
            action={goHome}
            onDidDismiss={goHome}
          />
        </div>
        <div>
          <AdviceAlert
            isOpen={isFailAlertOpen}
            setOpen={setFailAlertOpen}
            header="¡ Ups !"
            subHeader=""
            message="Su mascota no pudo ser cargada, intente más tarde."
            action={goHome}
          />
        </div>

        <EditDataPopover
          editDataPopoverState={popoverState}
          setEditDataPopoverState={setShowPopover}
          nonNullFields={nonNullFields}
          callback={submitSolicitud}
          message="Una vez aprobado, el cambio se hará efectivo en un plazo de hasta 72 horas hábiles."
        />
      </IonContent>
    </IonPage>
  );
};

export default AddPetPage;
