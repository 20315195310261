import React, { useEffect, useContext } from 'react';
import { SetLoadingContext } from '../tabs';
import { IonContent, IonPage, IonSpinner, useIonViewWillEnter } from '@ionic/react';

import HomeCards from '../../components/HomeCards';
import HomeNavbar from '../../components/HomeNavbar';
import Pets from '../../components/Pets';
import './HomePage.scss';

import { useMascotas } from '../../context/MascotasContext';
import PageRefreseher from '../../components/PageRefresher';
import { RefresherEventDetail } from '@ionic/core';

const Home: React.FC = () => {
  const setIsLoading = useContext(SetLoadingContext);
  const { mascotas: { pets, isValidating, mutate } } = useMascotas();
  useEffect(() => { setIsLoading(isValidating); }, [isValidating]);
  useEffect(() => { if (!isValidating) mutate() }, []);
  useIonViewWillEnter(() => {
    if (!isValidating) {
      mutate()
    }
  }, [isValidating]);
  if (!pets && isValidating) {
    return (
      <div className="home-page__loading-state">
        <IonSpinner color="dark" />
      </div>
    );
  }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  return (
    <IonPage className="home-page">
      <HomeNavbar />
      <IonContent>
        <PageRefreseher doRefresh={doRefresh} />
        <div className="home-page__pet-container">
          <Pets pets={pets} />
        </div>
        <HomeCards />
      </IonContent>
    </IonPage>
  );
};

export default Home;
