import { useRef, useState } from 'react';

import {
  addComprobanteCliente,
} from '../axios/addComprobanteCliente';
import { useCamera } from './useCamera';

interface InternalValues {
  file: any;
}

export const useFile = () => {
  
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { takePicture } = useCamera();

  const values = useRef<InternalValues>({
    file: false,
  });

  const onFileChange = async (vetFormId: string, cameraSource: boolean) => {
    function dataURLtoBlob(dataUrl: string) {
      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)![1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
    values.current.file = await takePicture(cameraSource);
    values.current.file = dataURLtoBlob(values.current.file);
    // setFileName(values.current.file.name);
    const formData = new FormData();
    formData.append('vetFormId', vetFormId);
    formData.append('file', values.current.file);

    try {
      setIsLoading(true);
      const res = await addComprobanteCliente(formData);
      setIsLoading(false);
      setIsUpdating(true);
    } catch (error) {
      console.log(error);
    }
  };
  return { onFileChange, isUpdating, isLoading };
};
