import { useState, useEffect } from 'react';
import { useStorage } from './useStorage';

export const useUserSession = () => {
  const { getValue } = useStorage();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    let isMounted = true;
    getValue('USER_MAIL').then((rta) => {
      if (isMounted && rta) {
        setEmail(rta);
      }
    });
  getValue('USER_NAME').then((rta) => {
    if (isMounted && rta) {
      setName(rta);
    }
  });
    return (()=> {isMounted = false;});
  },[]);
  if (email !== '' && name !== '' && email !== null && name !== null) {
    return { hasPersonalData: true, mail: email, name: name };
  }
  return { hasPersonalData: false, mail: '', name: '' };
};
