import React from 'react';

import { IonToast, useIonRouter } from '@ionic/react';

import './ToastAlert.scss';
import { ToastAlertProps } from './types';

const ToastAlert: React.FC<ToastAlertProps> = ({
  isOpen,
  setShowToast,
  message,
  duration,
  color,
  path,
  id,
}) => {
  const history = useIonRouter();

  function goTo() {
    history.push(`/tabs/${path}/${id}`,'forward','push');
  }

  return (
    <div className="toast-alert">
      <IonToast
        mode="ios"
        isOpen={isOpen}
        onDidDismiss={() => {
          setShowToast(false);
          if (path || id) {
            goTo();
          }
        }}
        message={message}
        duration={duration}
        color={color}
        cssClass={'toast-alert__message'}
      />
    </div>
  );
};

export default ToastAlert;
