import { useState } from 'react';
import { mutate } from 'swr';
import { useCamera } from './useCamera';

import { PETS } from '../axios/constants';
import { updateProfilePic as axiosUpdateProfilePic } from '../axios/updateProfilePic';

export const useProfilePic = () => {
  const { takePicture } = useCamera();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const updateProfilePic = async (sourceType, mascotaId: string ) => {
    const pic = await takePicture(sourceType);

    if (pic) {
      setIsUpdating(true);
      await axiosUpdateProfilePic({ mascotaId, pic });
      mutate(PETS);
      setIsUpdating(false);
    }
  };

  return { updateProfilePic, isUpdating };
};
