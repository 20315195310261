import useSWR from 'swr';

import { GET_SINTOMAS_MASCOTA } from '../axios/constants';
import { getSintomasMascota } from '../axios/getSintomasMascota';

export const useGetSintomasMascota = (siniestroId: string) => {
  const { data, error, mutate } = useSWR(
    [GET_SINTOMAS_MASCOTA, siniestroId],
    (url) => getSintomasMascota(url, siniestroId),
    {revalidateOnFocus:false,dedupingInterval:1500}
  );

  return { data, mutate, error };
};
