import React, { useRef, useState, useEffect } from 'react';
import { IonPopover, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { toPng } from 'html-to-image';
import { cloudDownload } from 'ionicons/icons';
import moment from 'moment';

import Logo from '../../assets/FielPetSinLogo.png';
import Check from '../../assets/check.png';
import PawPrint from '../../assets/pawPrints.png';
import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import { capitalizeFirstLetter } from '../../utils/General';
import './CarnetAsociado.scss';
import { CarnetAsociadoProps } from './types';

const CarnetAsociado: React.FC<CarnetAsociadoProps> = ({
  pet,
  plan,
  carnetAsociadoPopoverState,
  setCarnetAsociadoPopoverState,
}) => {
  const elementRef = useRef<any>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [profilePic, setProfilePic] = useState<string>();
  const { animal, raza, img_link, nombre, fecha_nacimiento } = pet || {};
  const { plan: petPlan, poliza: poliza, date } = plan || {};

  const placeholder =
    animal === 'Perro'
      ? DogPlaceholder
      : animal === 'Gato'
      ? CatPlaceholder
      : undefined;

  useEffect(() => {
    if (img_link) {
      setProfilePic(img_link);
    } else {
      setProfilePic(placeholder);
    }
  }, [img_link, placeholder]);

  const vigencia = (fechaString) => {
    const fecha = moment(fechaString, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
    const formatoFecha = fecha.format('MM/YY');
    const fechaHasta = fecha.add(1, 'year').format('MM/YY');

    return {
      desde: formatoFecha,
      hasta: fechaHasta,
    };
  };

  const vigenciaPlan = vigencia(date);
  const today = new Date();

  const convertToImage = () => {
    setDownloading(true);
    setTimeout(() => {
      toPng(elementRef.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = `carnet-${pet?.nombre}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setDownloading(false);
        });
    }, 100);
  };

  return (
    <IonPopover
      mode="ios"
      cssClass="carnet-asociado-popover"
      event={carnetAsociadoPopoverState.event}
      isOpen={carnetAsociadoPopoverState.showPopover}
      onDidDismiss={() =>
        setCarnetAsociadoPopoverState({ showPopover: false, event: undefined })
      }
    >
      <IonGrid ref={elementRef} className="ion-padding-horizontal">
        <IonRow>
          <IonCol>
            <div className="top-box">
              <img src={Logo} />
              <p>Carnet de asegurado</p>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="5">
            <div className="profile-pic">
              {profilePic && <img src={profilePic} alt={nombre}/>}
            </div>
            <img className="pawPrint" src={PawPrint} />
          </IonCol>
          <IonCol>
            <div className="pet_topInfo-box">
              <div className="data">
                <div className="title">Nombre</div>
                <div className="info">{nombre}</div>
              </div>

              <div className="data">
                <div className="title">Raza</div>
                <div className="info">{raza}</div>
              </div>
            </div>

            <div className="data">
              <div className="title">Fecha de nacimiento</div>
              <div className="info">
                {moment(fecha_nacimiento).format('DD/MM/YYYY')}
              </div>
            </div>

            <div className="data">
              <div className="title">Plan</div>
              <div className="info">
                {capitalizeFirstLetter(petPlan?.toLowerCase())}
              </div>
            </div>

            <div className="data">
              <div className="title">Fecha de vigencia</div>
              <div className="info">
                {vigenciaPlan.desde} - {vigenciaPlan.hasta}
              </div>
            </div>

            <div className="data">
              <div className="title">Poliza</div>
              <div className="info">{poliza}</div>
            </div>
          </IonCol>
        </IonRow>

        {!downloading ? (
          <div onClick={convertToImage} className="downloadIcon">
            <IonIcon icon={cloudDownload} color={'dark'} />
          </div>
        ) : (
          <img className="downloadedImg" src={Check} />
        )}

        <div className="emision">
          Emisión: {moment(today).format('DD/MM/YYYY')}
        </div>
      </IonGrid>
    </IonPopover>
  );
};

export default CarnetAsociado;
