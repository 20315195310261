import axios from '.';
import { AxiosResponse } from 'axios';
import { Movimiento } from '../interfaces/Movimiento';

export const getMovimientos = async (url: string, siniestroId: string) => {
  try {
    const { data }: AxiosResponse<Movimiento[]> =
      await axios.get(url, {
        params: {
          siniestroId,
        },
      });

    return data;
  } catch (error) {
    console.error(error);
  }
};
