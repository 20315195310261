import React, { useState } from 'react';

import { IonAlert, IonContent, IonToast } from '@ionic/react';
import moment from 'moment';

import { useWeights } from '../../hooks/useWeights';
import './NewWeightAlert.scss';
import { NewWeightAlertProps } from './types';

const NewWeightAlert: React.FC<NewWeightAlertProps> = ({
  petId,
  isOpen,
  setOpen,
  setWeightHistoryOpen,
  mutate,
  nombre,
}) => {
  const { addWeightEntry } = useWeights();
  const today = moment().format('YYYY-MM-DD');
  const [showToast1, setShowToast1] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  return (
    <IonContent>
      <IonAlert
        mode="ios"
        isOpen={isOpen}
        onDidDismiss={() => {
          setOpen(false);
        }}
        cssClass="new-weight-alert"
        header="Nuevo pesaje"
        inputs={[
          {
            name: 'weightNumber',
            type: 'number',
            label: 'Peso (en kg)',
            placeholder: 'Peso (en kg)',
            attributes: {
              // No me estaría dando bola este attribute
              required: true,
            },
            handler: (data) => {
              console.log('input', data);
            },
          },
          {
            name: 'weightDate',
            type: 'date',
            label: 'Fecha de pesaje',
            value: today,
          },
        ]}
        buttons={[
          {
            text: 'Cancelar',
          },
          {
            text: 'Confirmar',
            handler: async ({ weightNumber, weightDate }) => {
              setLoading(true);
              if (weightNumber > 0 && weightNumber < 201) {
                await addWeightEntry(petId, weightNumber, weightDate);
                mutate && mutate();
                setLoading(false);
                setWeightHistoryOpen && setWeightHistoryOpen(true);
                return true;
              } else {
                setShowToast1(true);
                setLoading(false);
                return false;
              }
            },
          },
        ]}
      />

      <IonToast
        mode="ios"
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={`Por favor ingresa un peso válido para ${nombre}`}
        duration={2500}
        cssClass={'new-weight-alert__message'}
        color="warning"
      />
    </IonContent>
  );
};

export default NewWeightAlert;
