import { postNotifications } from '../axios/notifications';
import { useStorage } from './useStorage';

export const useNotification = () => {
  const { setValue, getValue, clearValue } = useStorage();

  const setNotificationToken = (token: string) => {
    return setValue('NOTIFICATION_TOKEN', token);
  };
  const getNotificationToken = () => {
    return getValue('NOTIFICATION_TOKEN');
  };
  const clearNotificationToken = () => {
    return clearValue('NOTIFICATION_TOKEN');
  };

  return {
    setNotificationToken,
    getNotificationToken,
    clearNotificationToken,
    submitNotificationToken: postNotifications,
  };
};
