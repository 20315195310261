import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from '@ionic/react';
import { alertCircle } from 'ionicons/icons';
import moment from 'moment';

import AddVetContact from '../AddVetContact';
import Veterinarios from '../Veterinarios';
import './IndicacionPendiente.scss';
import { IndicacionPendienteProps } from './types';
import { capitalizeFirstLetter } from '../../utils/General';

const IndicacionPendiente: React.FC<IndicacionPendienteProps> = ({
  indicacion,
  indicacionesSeleccionadas,
  setIndicacionesSeleccionadas,
  setOkAlertOpen,
  setFailAlertOpen,
  nombre,
}) => {
  const { id, tipo, created_at, descripcionInicial } = indicacion;

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const [checked, setChecked] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  function typeHandler() {
    switch (tipo) {
      case 'consulta':
        return 'Consulta pendiente';

      case 'estudioMascota':
        return 'Estudio pendiente';

      case 'internacionMascota':
        return 'Internación pendiente';

      case 'cirugiaMascota':
        return 'Cirugía pendiente';

      case 'practicaMascota':
        return 'Practica pendiente';

      default:
        console.log(`error`);
    }
  }

  const date = moment(created_at).format('DD/MM/YYYY');

  // const lowercasedDescription = descripcionInicial.toLowerCase();
  // const capitalizedDescription =
  //   lowercasedDescription.charAt(0).toUpperCase() +
  //   lowercasedDescription.slice(1);

  function handleCheck() {
    return !checked ? 'Añadir consulta' : 'Consulta añadida';
  }

  function addPendiente() {
    setIndicacionesSeleccionadas((prevState) => [...prevState, indicacion]);
  }

  function removePendiente() {
    setIndicacionesSeleccionadas((current) =>
      current.filter((e) => e.id !== id)
    );
  }

  useEffect(() => {
    if (checked) {
      setDisabled(true);
      addPendiente();
    } else {
      setDisabled(false);
      removePendiente();
    }
  }, [checked]);
  

  return (
    <div className="indicacion-pendiente ion-margin-top">
      <Collapsible
        transitionTime={200}
        triggerDisabled={disabled}
        open={!disabled}
        trigger={
          <>
            <IonGrid>
              <IonRow>
                <IonCol size="3" className="indicacion-pendiente__state-icon">
                  <IonIcon icon={alertCircle} color="danger" />
                </IonCol>
                <IonCol>
                  <div>
                    <div className="indicacion-pendiente__type">
                      {typeHandler()}
                    </div>
                    <div className="indicacion-pendiente__date ">{date}</div>
                    <div className="indicacion-pendiente__description">
                      {capitalizeFirstLetter(descripcionInicial)}
                    </div>
                  </div>
                </IonCol>
              </IonRow>
              {/* <IonRow>
                <IonCol offset="3">
                  <div>
                    <IonItem lines="none" className="ion-no-padding">
                      <IonCheckbox
                        mode="ios"
                        checked={checked}
                        onIonChange={(e) => {
                          setChecked(e.detail.checked);
                        }}
                      />
                      <IonLabel>{handleCheck()}</IonLabel>
                    </IonItem>
                  </div>
                </IonCol>
              </IonRow> */}
            </IonGrid>
          </>
        }
      >
        <div className="ion-padding-horizontal ion-margin-bottom">
          <AddVetContact
            indicacion={indicacion}
            popoverState={popoverState}
            setShowPopover={setShowPopover}
            indicacionesSeleccionadas={indicacionesSeleccionadas}
            setIndicacionesSeleccionadas={setIndicacionesSeleccionadas}
            setOkAlertOpen={setOkAlertOpen}
            setFailAlertOpen={setFailAlertOpen}
            nombre={nombre}
            checked={checked}
          />

          <Veterinarios
            indicacion={indicacion}
            indicacionesSeleccionadas={indicacionesSeleccionadas}
            setIndicacionesSeleccionadas={setIndicacionesSeleccionadas}
            setOkAlertOpen={setOkAlertOpen}
            setFailAlertOpen={setFailAlertOpen}
            checked={checked}
          />

          <IonButton
            disabled={checked}
            mode='ios'
            expand="block"
            size="small"
            className="ion-margin-top ion-text-wrap"
            onClick={(e: any) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e });
            }}
          >
            Agregar Veterinario
          </IonButton>
        </div>
      </Collapsible>
    </div>
  );
};

export default IndicacionPendiente;
