import React, { createContext, useState } from 'react';
import { CrearEventoFirstFormInterface } from '../interfaces/CrearEvento';

interface crearEventoFormContext {
  crearEventoFormValues: CrearEventoFirstFormInterface | undefined;
  setCrearEventoFormValues: React.Dispatch<
    React.SetStateAction<CrearEventoFirstFormInterface | undefined>
  >;
}
export const CrearEventoFormContext = createContext<crearEventoFormContext>({
  crearEventoFormValues: undefined,
  setCrearEventoFormValues: () => null,
});

export const CrearEventoFormProvider: React.FC = ({ children }) => {
  const [crearEventoFormValues, setCrearEventoFormValues] = useState<
    CrearEventoFirstFormInterface
  >();
  return (
    <CrearEventoFormContext.Provider
      value={{ crearEventoFormValues, setCrearEventoFormValues }}
    >
      {children}
    </CrearEventoFormContext.Provider>
  );
};
