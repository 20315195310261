import moment from 'moment';
import axiosInstance from '.';
//CAMBIAR CONSTANTE
import { UPDATE_PROFILE_PIC } from './constants';
import { PicReq } from '../interfaces/PicReq';
import { dataURLtoFile } from '../utils/General';

export const updateLibVacunacion = async ({ pic, mascotaId }: PicReq) => {
  const formData = new FormData();

  const file = dataURLtoFile(pic, `profile-pic-pet-${mascotaId}-${moment()}`);
  formData.append('mascotaId', mascotaId);
  formData.append('pic', file);
  await axiosInstance.post(UPDATE_PROFILE_PIC, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
