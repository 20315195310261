import useSWR from 'swr';
import { PERSONAL_DATA } from '../axios/constants';
import { getUser } from '../axios/getPersonalData';

export const useUserData = () => {
  const { data, error, mutate } = useSWR(PERSONAL_DATA, (url) => getUser(url), {
    revalidateOnFocus: false,
    dedupingInterval: 1000,
  });

  return { data, mutate, error };
};