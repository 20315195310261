import { PushNotifications, Token } from '@capacitor/push-notifications';
import React, { useEffect } from 'react';

import { useStorage } from '../hooks';
import { useNotification } from '../hooks/useNotification';

export const withNotification = (WrappedComponent: React.FC) => (
  props: any
) => {
  const { getValue } = useStorage();
  const {
    setNotificationToken,
    getNotificationToken,
    submitNotificationToken,
  } = useNotification();

  const enableNotifications = async () => {
    const notification_token = await getNotificationToken();
    const isAuth = await getValue('token');

    if (!notification_token && isAuth) {
      try {
        const permission = await PushNotifications.requestPermissions();
        if (permission) {
          await PushNotifications.register();
          PushNotifications.addListener(
            'registration',
            async (token: Token) => {
              await submitNotificationToken(token.value);
              setNotificationToken(token.value);
            }
          );
          /** TODO: local notification */
          /* PushNotifications.addListener(
            'pushNotificationReceived',
            (not: PushNotification) => alert('noti' + not)
          ); */
        }
      } catch (e) {
        console.error('ERROR', e);
      }
    }
  };
  useEffect(() => {
    enableNotifications();
  }, []);
  return <WrappedComponent {...props} />;
};

export default withNotification;
