import moment from 'moment';
import { Pet } from '../interfaces/Pet';
import { Pesaje } from '../interfaces/Pesaje';
import { Evento } from '../interfaces/Evento';
import { FoodRes } from '../interfaces/Food';

export const getAge = (pet: Pet) => {
  const now = moment();
  const birthDate = moment(pet?.fecha_nacimiento);
  const ageYears = now.diff(birthDate, 'years');
  const ageMonths = now.diff(birthDate, 'months');
  const age =
    ageYears === 0
      ? `${ageMonths} ${ageMonths === 1 ? 'mes' : 'meses'}`
      : `${ageYears} ${ageYears === 1 ? 'año' : 'años'}`;
  return age;
};

export const sortPesajesByDate = (weightA: Pesaje, weightB: Pesaje) => {
  const dateA = moment(weightA.fecha_pesaje);
  const dateB = moment(weightB.fecha_pesaje);
  if (dateA < dateB) {
    return 1;
  } else {
    return -1;
  }
};

export const settingAge = (ev: Evento) => {
  const num = ev.title.replace(/[^0-9]/g, '');
  if (+num === 1) {
    return `¡ ${ev.Mascotum.nombre} cumple ${num} año !`;
  } else {
    return ev.title;
  }
};

// /** Returns the last weight of the pet, if there is no weight loaded returns undefined */
// export const getLastWeight = (pet: Pet) => {
//   return pet && pet.historial_peso.length > 0
//     ? pet.historial_peso.sort(sortPesajesByDate)[0].peso
//     : undefined;
// };

// /** Returns the daily calories that a pet consumes */
// export const getCalorias = (foods: FoodRes[]) => {
//   return foods
//     .map(
//       (x) => (x.cargaEnergetica * x.stocksAlimentoMascotas[0].consume) / 1000
//     )
//     .reduce((a, b) => a + b)
//     .toFixed(0);
// };

// /** Returns the daily weigth that a pet consumes of some foodbag, if there isnt a bag with that id returns 0*/
// export const getConsumoFromBag = (foods: FoodRes[], id: number) => {
//   for (const f of foods) {
//     if (f.stocksAlimentoMascotas[0].stockAlimentoId === id)
//       return f.stocksAlimentoMascotas[0].consume;
//   }
// };
// /** Toma el peso de una mascota y la cargaEnergetica de la bolsa de alimento y calcula el consumo estimado EN GRAMOS
//  * diario para la mascota.
//  */
// export const formulaConsumoEstimado = (
//   peso: number | undefined,
//   cargaEnergetica: number
// ) => {
//   if (peso && cargaEnergetica != 0) {
//     return (145 * Math.pow(peso, 0.67) * 1000) / cargaEnergetica;
//   }
//   return 0;
// };

// const getConsumo = (
//   arr: { mascotaId: number; consumoDiario: number }[],
//   mascotaId: number
// ) => {
//   const filtrado = arr.filter((a) => a.mascotaId === mascotaId);
//   if (filtrado.length > 0) {
//     return filtrado[0].consumoDiario;
//   }
//   return 0;
// };

// /** Given the list of all pets of a user and a foodBag, return two elements:
//  * consumers: list of pets with consumos of this foodbag
//  * noConsumer: list of pets with consumos that have the posibility of eating from this foodbag
//  */
// export const filterByFoodBag = (
//   pets: Pet[] | undefined,
//   foodBag: FoodRes | undefined
// ) => {
//   if (pets && foodBag) {
//     // Elimina de pets todas las mascotas que no pueden comer de esta bolsa (perros/gatos)
//     pets = pets.filter((p) => p.animal == foodBag.alimento.animal);

//     // idConsumers contiene los ids de las mascotas que comen de esta bolsa
//     const idsConsumers = foodBag.stocksAlimentoMascotas.map((m) => m.mascotaId);

//     // A partir de pets formo un arreglo de objetos que contienen a la mascota y su consumo recomendado para esta bolsa
//     const consumosEstimados = pets.map((p) => ({
//       mascota: p,
//       consumoEstimado: formulaConsumoEstimado(
//         getLastWeight(p),
//         foodBag.cargaEnergetica
//       ),
//     }));

//     // A partir de las mascotas que comen de esta bolsa obtengo un arreglo de objetos que consisten en el id de la mascota y cuando consumen de esta bolsa diariamente
//     const consumosDiarios = foodBag.stocksAlimentoMascotas.map((m) => ({
//       mascotaId: m.mascotaId,
//       consumoDiario: m.consume,
//     }));

//     // junto los arreglos consumosEstimados y consumosDiarios
//     const allPets = consumosEstimados.map((m) => ({
//       ...m,
//       consumoDiario: getConsumo(consumosDiarios, m.mascota.id),
//     }));

//     // separo a todas las mascotas entre cosumidores y posilbles consumidores
//     const consumers = allPets.filter((p) =>
//       idsConsumers.includes(p.mascota.id)
//     );
//     const noConsumers = allPets.filter(
//       (p) => !idsConsumers.includes(p.mascota.id)
//     );
//     return { consumers: consumers, noConsumers: noConsumers };
//   } else {
//     return { consumers: [], noConsumers: [] };
//   }
// };
