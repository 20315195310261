import useSWR from 'swr';
import { GET_FOOD_BAGS } from '../axios/constants';
import { getFoodBags, getFoodOfPet } from '../axios/food';

export const useFoodBags = () => {
  const { data: foodBags, error, mutate, isValidating } = useSWR(GET_FOOD_BAGS, getFoodBags, {
    shouldRetryOnError: false,
    revalidateOnFocus:false
  });

  //Funcion para eliminar duplicados
  const cleanList = () => {
    if (foodBags) {
      const cleanMarca: string[] = [];
      foodBags.map((food) => {
        if (!cleanMarca.includes(food.marca)) {
          cleanMarca.push(food.marca);
        }
      });
      return cleanMarca;
    }
  };

  const foodListFormat = foodBags?.map((food) => {
    return { value: food.id, label: food.nombre };
  });

  const marcasList = cleanList()?.map((marca) => {
    return { value: marca, label: marca };
  });

  return { foodBags, error, mutate, marcasList, foodListFormat,isValidating };
};

export const useFoodBagsPet = (id: string | undefined) => {
  const { data: foodBags, error, mutate,isValidating } = useSWR(
    [GET_FOOD_BAGS, id],
    () => getFoodOfPet(id),
    {
      shouldRetryOnError: false,
      revalidateOnFocus:false
    }
  );

  return { foodBags, error, mutate, isValidating };
};
