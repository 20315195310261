import axiosInstance from '.';
import { GET_PLANS } from './constants';
import { AxiosResponse } from 'axios';
import { Plan } from '../interfaces/Plan';

export const getPlans = async () => {
  try {
    const { data: plans }: AxiosResponse<Plan[]> = await axiosInstance.get(
      GET_PLANS
    );
    return plans;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
