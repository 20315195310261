import React from 'react';
import { IonButton, IonContent, IonPage } from '@ionic/react';

import './BajaCoberturaPage.scss';
import NavigationBar from '../../components/NavigationBar';
import Email from "../../assets/email.png"

const BajaCoberturaPage: React.FC = () => {
  return (
    <IonPage>
      <NavigationBar goBack />
      <IonContent className='baja-cobertura-page ion-padding'>
        <div>
          <div className='ion-padding-bottom'>
            <img src={Email} />
          </div>
          <p className='baja-cobertura-page__title'>Solicitar la baja de mi cobertura</p>
          <div className="baja-cobertura-page__text">
            <p>
              Gestionar baja del seguro
              1. Pedir la anulación a BBVA Seguros al email bajapolizaseguros-arg@bbva.com
              2. Notificar a Brenda/Modificar directamente el estado de la solicitud en PortalDB, pasa a ANULADA. Motivos pueden ser:
            </p>
          </div>
          <div className='ion-padding-top'>
            <IonButton expand="block"
              // href={'tel:' + "01143299106"} 
              mode='ios'>
              Enviar solicitud
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default BajaCoberturaPage;