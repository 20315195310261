export const formatTitle = (label: string) => {
  switch (label) {
    case 'DespExterna':
      return 'Desparasitación externa';
    case 'DespInterna':
      return 'Desparasitación interna';
    default:
      return label;
  }
};
