import React, { useState } from 'react';

import { IonGrid, IonRow, IonCol } from '@ionic/react';
import moment from 'moment';

import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import SiniestroPopover from '../SiniestroPopover';
import './SiniestroInfo.scss';
import { SiniestroInfoProps } from './types';
import { useSiniestro } from '../../context/SiniestroContext';

const SiniestroInfo: React.FC<SiniestroInfoProps> = ({pet}) => {
  const { siniestro } = useSiniestro();
  const { denuncia_administrativa: DA, fecha_carga } = siniestro.data || {};
  const { animal, img_link, nombre } = pet || {};

  const length = 65;
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const placeholder =
    animal === 'Perro'
      ? DogPlaceholder
      : animal === 'Gato'
      ? CatPlaceholder
      : undefined;

  const image = img_link ? (
    <img src={img_link} alt={nombre} />
  ) : (
    <img src={placeholder} alt={nombre} />
  );

  const date = moment(fecha_carga).format('DD/MM/YYYY');

  const denuncia =
    DA && DA.length > length ? DA.substring(0, length - 3) + `...` : DA;

  return (
    <div className="siniestro-info ion-margin-vertical ion-margin-end">
      <div>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="4" sizeSm='3' sizeMd='2' className="siniestro-info__avatar">
              {image}
            </IonCol>
            <IonCol>
              <div className="siniestro-info__info-container">
                <div className="siniestro-info__nombre-date-box">
                  <span className="siniestro-info__nombre">{nombre}</span>
                  <span className="siniestro-info__date">{date}</span>
                </div>
                <div
                  className="siniestro-info__denuncia"
                  onClick={(e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e });
                  }}
                >
                  {denuncia}.
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        {DA && DA.length > length && (
          <SiniestroPopover
            popoverState={popoverState}
            setShowPopover={setShowPopover}
            denunciaAdministrativa={DA}
          />
        )}
      </div>
    </div>
  );
};

export default SiniestroInfo;
