import axios from 'axios';

import { API_URL } from './constants';
import { useStorage } from '../hooks';
import { refresh } from './refresh';

const instance = axios.create({
  baseURL: API_URL[process.env.REACT_APP_ENV ?? 'dev'],
});

// Interceptor de las requests que les agrega el token
instance.interceptors.request.use(async (config) => {
  const { getValue } = useStorage();
  const token = await getValue('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// Custom mutex
let isRefreshing = false;

// Lista de las requests que están esperando el refresh para volver a lanzarse
let refreshQueue: Array<(token?: string) => void> = [];

// Interfaz para empujar a la refreshQueue
// Lo que ponemos en espera es uan función que reciba el token nuevo y ejecuta
// la promise con el token ya actualizado
const putOnWait = (request: (token?: string) => void) => {
  refreshQueue.push(request);
};

const triggerRequestsOnWait = (newToken?: string) => {
  refreshQueue.map((req) => req(newToken));
};

// Interceptor de las responses que revisa si el fallo se debe a que el token
// está expirado
instance.interceptors.response.use(
  // Si la request no falló, no intervenimos
  (response) => response,

  async (error) => {
    const status = error?.response?.status;
    const message = error?.response?.data;
    const originalRequest = error?.config;
    const { setValue, getValue } = useStorage();

    if ( status=== 401) {
      if (
        window.location.href.indexOf('/login') === (window.location.href.length -6) ||
        window.location.href.indexOf('/register') === (window.location.href.length -9)
      ){
        return Promise.resolve();
      }
      if ( message?.message === 'jwt expired') {
        if (isRefreshing) {
          return new Promise((resolve, _reject) => {
            putOnWait((token?: string) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        } else {
          // 0) Tomar el lock
          isRefreshing = true;
          const refreshToken = await getValue('refreshToken');
          // 1) Pedir el refresco del token
          const { ok, data } = await refresh({ refreshToken });
          // 2) Actualizar tokens en el storage
          if (ok) {
            await setValue('token', data?.token);
            await setValue('refreshToken', data?.refreshToken);
            // De acá para abajo ya no necesito la sincronicidad que nos da el await
            // 3) Vuelvo a llamar a la request original con el token actualizado
            originalRequest.headers.Authorization = `Bearer ${data?.token}`;
            const response = await axios(originalRequest);
            // 4) Llamamos a todas las requests pendientes
            triggerRequestsOnWait(data?.token);
            // 5) Limpiamos el "estado"
            isRefreshing = false;
            refreshQueue = [];
            return response;
          }
          else {
            window.location.href = "/login";
          }
        }
      } else {
        window.location.href = "/login";
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
