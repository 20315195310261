import useSWR from 'swr';
import { useState, useEffect } from 'react';

import { GET_PRODUCTOS_DESPARASITACION } from '../axios/constants';
import { getProductosDesparasitacion } from '../axios/getProductosDesparasitacion';
import { ProductoDesparasitacion } from '../interfaces/Desparasitacion';
import { Animal } from '../interfaces/Pet';
import {useMascotas} from '../context/MascotasContext';

export const useGetProductosDesparasitacion = (id: string) => {
  const [productoFiltrado, setProductoFiltrado] = useState<
    ProductoDesparasitacion[]
  >();
  const {mascotas:{pets}} = useMascotas();
  const pet = pets?.find((m)=> m.id.toString() === id);
  const { data, mutate, error } = useSWR(
    GET_PRODUCTOS_DESPARASITACION,
    getProductosDesparasitacion,
    {
      shouldRetryOnError: false,
    }
  );

  const changeProductosDesparasitacion = (tipo?: Animal) => {
    const filterData = data?.filter((producto) => producto.animal === tipo);
    setProductoFiltrado(filterData);
  };

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
  }, [data, mutate, error]);

  useEffect(() => {
    changeProductosDesparasitacion(pet?.animal);
  }, [pet, data]);

  return {
    productosDesparasitacion: productoFiltrado,
    changeProductosDesparasitacion,
    mutate,
    error,
  };
};
