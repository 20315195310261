import React from 'react';
import { IonPage } from '@ionic/react';
import './InitialSlidesPage.scss';
import InitialSlides from '../../components/InitialSlides';

const InitialSlidesPage: React.FC = () => {
  return (
    <IonPage>
      <InitialSlides></InitialSlides>;
    </IonPage>
  );
};

export default InitialSlidesPage;
