import React, { useEffect, useState } from 'react';
import { CameraSource } from '@capacitor/camera';

import {
  IonIcon,
  IonSpinner,
  IonAlert,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { cloudUpload } from 'ionicons/icons';

import { useSiniestro } from '../../context/SiniestroContext';
import { useFile } from '../../hooks/useDocumentacionFile';
import './FormDocObligatoriaInput.scss';
import { FormDocObligatoriaInputProps } from './types';

const FormDocObligatoriaInput: React.FC<FormDocObligatoriaInputProps> = ({
  type,
  doc,
}) => {
  const { siniestroId, documentacion } = useSiniestro();
  const [label, setLabel] = useState<string>();
  const [showCameraAlert, setShowCameraAlert] = useState<boolean>(false);

  const { onFileChange, isUpdating, isLoading } = useFile();
  function handler() {
    switch (type) {
      case 'dni_frente':
        setLabel('DNI Frente');
        break;
      case 'dni_dorso':
        setLabel('DNI Dorso');
        break;
      case 'libreta_vacunacion':
        setLabel('Libreta de vacunación');
        break;
      case 'cbu':
        setLabel('CBU propio');
        break;
      case 'tercero_cbu':
        setLabel('CBU del tercero');
        break;
      case 'constancia':
        setLabel('Nota firmada por asegurado');
        break;
      default:
        console.log('DocObligatoriaInput handler error');
    }
  }

  useEffect(() => {
    handler();
  }, []);

  async function newDocumentacionHandler(sourceType) {
    await onFileChange(siniestroId, type!, sourceType);
    documentacion.mutate(undefined, true);
  }

  return (
    <div className="form-doc-obligatoria-input">
      <IonGrid className="ion-no-padding ion-padding-vertical">
        <IonRow>
          <IonCol size="4" className="form-doc-obligatoria-input__label">
            {label}
          </IonCol>
          <IonCol size="2" onClick={() => setShowCameraAlert(true)}>
            <IonIcon icon={cloudUpload} color="primary" />
          </IonCol>
          {!isUpdating && !doc ? (
            <IonCol className="form-doc-obligatoria-input__pendiente">
              Pendiente de carga
            </IonCol>
          ) : (
            <IonCol className="form-doc-obligatoria-input__listo">
              Documento cargado
            </IonCol>
          )}
        </IonRow>
      </IonGrid>

      {isLoading && (
        <div className="form-doc-obligatoria-input__image-updating">
          <IonSpinner color="light" />
        </div>
      )}

      <IonAlert
        mode='ios'
        isOpen={showCameraAlert}
        onDidDismiss={() => setShowCameraAlert(false)}
        header="Indique desde donde subir la imagen"
        message="Por favor seleccione"
        buttons={[
          {
            text: 'Camara',
            handler: () => {
              newDocumentacionHandler(CameraSource.Camera);
            },
          },
          {
            text: 'Galería',
            handler: () => {
              newDocumentacionHandler(CameraSource.Photos);
            },
          },
        ]}
      />
    </div>
  );
};

export default FormDocObligatoriaInput;
