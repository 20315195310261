import useSWR from 'swr';

import { GET_SINIESTROS } from '../axios/constants';
import { getSiniestros } from '../axios/siniestros';

export const useGetSiniestros = (id: string) => {
  if (id.length===0){}
  const { data, isValidating, error, mutate } = useSWR([GET_SINIESTROS, id], (url) =>
    getSiniestros(url, id),{
      revalidateOnFocus:false,
      dedupingInterval:2000,
    }
  );

  return { siniestros: data, isValidating, error, mutate };
};
