import React, { useState, useEffect } from 'react';
import {
  IonSpinner,
  IonAlert,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/react';
import './VetFormComprobanteInput.scss';
import { useSiniestro } from '../../context/SiniestroContext';
import { useFile } from '../../hooks/useComprobanteCliente';
import Cash from "../../assets/cash.png";

const VetFormComprobanteInput: React.FC<any> = ({ vetForm }) => {
  const [showCameraAlert, setShowCameraAlert] = useState<boolean>(false);
  const [cameraSource, setCameraSource] = useState<boolean | undefined>(
    undefined
  );
  const { siniestro: { data: siniestro, mutate }, documentacion } = useSiniestro();
  const fullVetForm = siniestro ? siniestro.vet_forms.find((vf) => vf.id === vetForm.id) : null;

  const comprobantes = fullVetForm ?
    fullVetForm.comprobantesCliente.length
    : 0;
  const { onFileChange, isUpdating, isLoading } = useFile(); //@TODO capaz haya que hacer otro especifico
  useEffect(() => {
    if (cameraSource !== undefined) {
      const updateData = async () => {
        await onFileChange(vetForm.id, cameraSource);
        setCameraSource(undefined);
        mutate();
      };
      updateData();
    }
  }, [cameraSource]);

  return (
    <div className="vet-form-comprobante-input" onClick={(e) => { e.stopPropagation(); setShowCameraAlert(true); }}>
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol size="2">
            <img src={Cash} />
          </IonCol>
          <IonCol offset='1' >
            <div className='comprobante-box'>
              <p>Comprobante de pago</p>
              <IonText color='medium'>({comprobantes} cargado{comprobantes !== 1 ? "s" : ""})</IonText>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      {isLoading && (
        <div className="vet-form-comprobante-input__extra-image-updating">
          <IonSpinner color="light" />
        </div>
      )}

      <IonAlert
        mode='ios'
        isOpen={showCameraAlert}
        onDidDismiss={() => setShowCameraAlert(false)}
        header="Por favor seleccione"
        message="Indique desde donde subir la imagen"
        buttons={[
          {
            text: 'Camara',
            handler: () => {
              setCameraSource(true);
            },
          },
          {
            text: 'Galería',
            handler: () => {
              setCameraSource(false);
            },
          },
        ]}
      />
    </div>);
};

export default VetFormComprobanteInput;
