import React from 'react';
import { Link } from 'react-router-dom';
import { IonFabButton, IonIcon, IonFab, IonPage } from '@ionic/react';
import { albums } from 'ionicons/icons';

import { useInitialSlides } from '../../hooks/useInitialSlides';
import FormLogin from '../../components/FormLogin';
import AuthView from '../../components/AuthView';

import './LoginPage.scss';

const LoginPage: React.FC = () => {
  useInitialSlides();
  return (
    <IonPage>
      <AuthView src="assets/img/background/Gato.png">
        <FormLogin />
        <div className="login-page-links">
          <p className="login-page-links--reg">
            No tienes cuenta? <Link to="/register">Registrate</Link>
          </p>
          <Link to="/pass-recover"><p className='login-page-links--forget'>Olvidaste tu contraseña?</p></Link>
        </div>
      </AuthView>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton routerLink="/initial-slides" color="primary">
          <IonIcon icon={albums} />
        </IonFabButton>
      </IonFab>
    </IonPage>
  );
};

export default LoginPage;
