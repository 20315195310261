import axiosInstance from '.';
import { AddVetFormLinkDataReq } from "../interfaces/AddVetFormLinkData";
import { ADD_VETFORM_LINK_DATA} from "./constants";


export const postVetFormLinkData = async ({
    vetFormLinkData
  }: AddVetFormLinkDataReq): Promise<boolean> => {
    try {
      await axiosInstance.post(ADD_VETFORM_LINK_DATA, vetFormLinkData);
      return true;
    } catch (e) {
      console.error(e.response);
      return false;
    }
  };