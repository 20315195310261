import { useState } from 'react';

import { Sexo } from '../interfaces/Pet';

export const useEventsType = () => {
  const eventosM = [
    { nombre: 'Consulta', value: 'Consulta' },
    { nombre: 'Vacuna', value: 'Vacuna' },
    { nombre: 'Desparasitacion externa', value: 'DespExterna' },
    { nombre: 'Desparasitacion interna', value: 'DespInterna' },
  ];
  const eventosH = [
    { nombre: 'Consulta', value: 'Consulta' },
    { nombre: 'Vacuna', value: 'Vacuna' },
    { nombre: 'Desparasitacion externa', value: 'DespExterna' },
    { nombre: 'Desparasitacion interna', value: 'DespInterna' },
    { nombre: 'Cría', value: 'Cria' },
    { nombre: 'Celo', value: 'Celo' },
  ];
  const [eventsType, setEventsType] = useState(eventosM);

  const changeEventType = (a: Sexo | undefined) => {
    a === 'M' ? setEventsType(eventosM) : setEventsType(eventosH);
  };
  return { eventsType, changeEventType };
};
