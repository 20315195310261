import axiosInstance from '.';
import { Vacunas } from '../interfaces/Vacuna';
import { AxiosResponse } from 'axios';
import { GET_VACUNA } from './constants';

export const getVacuna = async () => {
  try {
    const { data: vacunas }: AxiosResponse<Vacunas> = await axiosInstance.get(
      GET_VACUNA
    );
    return vacunas;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
