import useSWR from 'swr';

import { PETS } from '../axios/constants';
import { getPets as axiosGetPets } from '../axios/pets';

export const useGetPets = () => {
  const { data, isValidating, error, mutate } = useSWR(PETS, (url) =>
    axiosGetPets(url),{revalidateOnFocus:false,dedupingInterval:1000}
  );

  return { pets: data, isValidating, error, mutate };
};
