import React, { useContext, useState } from 'react';

import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  useIonRouter,
  IonAlert,
} from '@ionic/react';
import {
  eyeOutline,
  logOutOutline,
  pawOutline,
  personCircle,
} from 'ionicons/icons';

// import LogoutToast from '../../components/LogoutToast';
import NavigationBar from '../../components/NavigationBar';
import { TutorialContext } from '../../context/TutorialContext';
import { useAuth, useUserSession } from '../../hooks';
import './SettingsPage.scss';

const SettingsPage: React.FC = () => {
  const { push } = useIonRouter();
  const { logout } = useAuth();
  const { setTutorialDoneStatus } = useContext(TutorialContext);
  const { hasPersonalData, mail, name } = useUserSession();
  // const [logoutVisible, setLogoutVisible] = useState<boolean>(false);
  const [showLogOut, setShowLogOut] = useState<boolean>(false);

  return (
    <IonPage>
      <NavigationBar path='home' />
      <IonContent className="settings-page">
        {hasPersonalData ? (
          <div className="settings-page__infoDiv ion-margin-vertical">
            <IonIcon icon={personCircle} />
            <IonLabel className="settings-page__name">{name}</IonLabel>
            <IonLabel className="settings-page__mail">{mail}</IonLabel>
          </div>
        ) : null}

        <IonList lines="none">
          <div>
            <IonItem
              data-tut="reactour__sincronizar-mascotas"
              onClick={() => push("/tabs/ajustes/asociar", 'forward', 'push')}
            >
              <IonIcon icon={pawOutline} />
              Sincronizar mascotas
            </IonItem>
            <IonItem
              data-tut="reactour__ver-tutorial"
              button
              onClick={() => setTutorialDoneStatus(false)}
            >
              <IonIcon icon={eyeOutline} />
              Ver tutorial
            </IonItem>
          </div>
          <IonItem
            data-tut="reactour__cerrar-sesion"
            button
            onClick={() => setShowLogOut(true)}
          >
            <IonIcon icon={logOutOutline} />
            Cerrar sesión
          </IonItem>
        </IonList>
        {/* <LogoutToast isVisible={logoutVisible} setVisible={setLogoutVisible} /> */}
        <IonAlert
          mode='ios'
          isOpen={showLogOut}
          onDidDismiss={() => setShowLogOut(false)}
          header="¿Deseas cerrar sesión?"
          subHeader="Mensaje importante"
          // message="El titular de la póliza debe firmar una nota autorizando al tercero (nombre, apellido y CUIL/DNI) a recibir el reintegro."
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                setShowLogOut(false);
              },
            },
            {
              text: 'Confirmar',
              handler: async () => {
                await logout();
                push('/login');
              },
            },
          ]}
        />
      </IonContent>
    </IonPage >
  );
};

export default SettingsPage;
