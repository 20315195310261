import { AxiosResponse } from 'axios';

import axiosInstance from '.';
import { GET_LOCALIDADES } from './constants';
import { LocalidadRes } from '../interfaces/Localidad';

export const getLocalidades = async (provincia: string) => {
  try {
    const {
      data: localidades,
    }: AxiosResponse<LocalidadRes[]> = await axiosInstance.post(
      GET_LOCALIDADES,
      {
        provincia,
      }
    );
    return localidades;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
