import React from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import NavigationBar from '../../components/NavigationBar';
import './PetsPage.scss';

import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import {useMascotas} from '../../context/MascotasContext';
const PetsPage: React.FC = () => {
  const { push } = useIonRouter();
  const { mascotas:{pets} } = useMascotas();

  function handleImgPlaceholder(animal: string) {
    if (animal === 'Perro') {
      return DogPlaceholder
    } else {
      return CatPlaceholder;
    }
  }

  return (
    <IonPage className="pets-page">
      <NavigationBar goBack={false} path='siniestros' />
      <IonContent>
        <div className="pets-page__title">
          Seleccione una de sus mascotas para denunciar un accidente o enfermedad
        </div>

        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          pagination
        >
          {pets &&
            pets.map((pet, index) => (
              <SwiperSlide
                key={index}
                onClick={() => {
                  push(`/tabs/siniestros/new/${pet.id}`);
                }}
              >
                <div className="pets-page__pet-container">
                  {pet.img_link ? <img src={pet.img_link!} /> : <img src={handleImgPlaceholder(pet.animal)} />}

                  <p className="label">{pet.nombre}</p>
                  <div className="raza">{pet.raza}</div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default PetsPage;
