import React from 'react';
import { Link } from 'react-router-dom';
import { IonPage } from '@ionic/react';

import AuthView from '../../components/AuthView';
import PasswordRecoverForm from '../../components/PasswordRecoverForm';

import './PassRecoverPage.scss';

const PassRecoverPage: React.FC = () => {
  return (
    <IonPage>
      <AuthView src="assets/img/background/Perro2.png">
        <PasswordRecoverForm />
        <Link to="/login" className="register-page-link">
          Página de inicio
        </Link>
      </AuthView>
    </IonPage>
  );
};

export default PassRecoverPage;
