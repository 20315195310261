import { useState, useEffect } from 'react';
import { getPlans as axiosGetPlans } from '../axios/getPlans';
import { Plan } from '../interfaces/Plan';

export const usePlans = () => {
  const [plans, setPlans] = useState<Plan[]>([]);

  const getPlans = async () => {
    const plans = await axiosGetPlans();
    setPlans(plans);
  };

  useEffect(() => {
    getPlans();
  }, []);

  return plans;
};
