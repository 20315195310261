import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { IonPopover, IonIcon, useIonRouter } from '@ionic/react';
import 'animate.css';
import {
  eyeSharp,
  shieldSharp,
  card,
  person,
  paw,
  personSharp,
} from 'ionicons/icons';

import Logo from '../../assets/FielPetSinLogo.png';
import { useUser } from '../../context/UserContext';
import { useGetPlanesPortal } from '../../hooks/usePlanesPortal';
import { PlanPortal } from '../../interfaces/PlanPortal';
import CarnetAsociado from '../CarnetAsociado';
import LibretaVacunacion from '../LibretaVacunacion';
import ToastAlert from '../ToastAlert';
import './GestionSeguro.scss';
import { GestionSeguroProps } from './types';

const GestionSeguro: React.FC<GestionSeguroProps> = ({
  pet,
  gestionSeguroPopoverState,
  setGestionSeguroPopoverState,
}) => {
  const { push } = useIonRouter();
  const history = useHistory();
  const { user } = useUser();
  const email = user.data?.email || "Email no disponible";
  console.log('email:', email );
  const { planes } = useGetPlanesPortal(email);

  const filteredPlan: PlanPortal | undefined = planes?.find(
    (plan) => plan.nombre === pet?.nombre
  );

  const [emptyPlan, setEmptyPlan] = useState<boolean>(false);
  const [emptyPlanAlertOpen, setEmptyPlanAlertOpen] = useState<boolean>(false);
  const [carnetAsociadoPopoverState, setCarnetAsociadoPopoverState] = useState({
    showPopover: false,
    event: undefined,
  });

  const [
    libretaVacunacionPopoverState,
    setLibretaVacunacionPopoverState,
  ] = useState({
    showPopover: false,
    event: undefined,
  });

  useEffect(() => {
    if (!planes?.length) {
      setEmptyPlan(true);
    } else {
      setEmptyPlan(false);
    }
  }, [planes]);

  useEffect(() => {
    // Esta función se ejecutará cada vez que la ruta cambie
    const handleRouteChange = () => {
      setGestionSeguroPopoverState({ showPopover: false, event: undefined });
    };

    // Suscribir la función al evento de cambio de ruta
    const unlisten = history.listen(handleRouteChange);

    // Retornar una función de limpieza para eliminar el listener cuando el componente se desmonte
    return () => {
      unlisten();
    };
  }, [history, setGestionSeguroPopoverState]);

  return (
    <>
      <IonPopover
        mode="ios"
        cssClass="gestion-seguro animate__animated animate__zoomIn"
        event={gestionSeguroPopoverState.event}
        isOpen={gestionSeguroPopoverState.showPopover}
        onDidDismiss={() =>
          setGestionSeguroPopoverState({ showPopover: false, event: undefined })
        }
      >
        <div className="logo-container">
          <img src={Logo} />
        </div>
        <div className="options">
          <div
            className="option"
            onClick={() => {
              if (emptyPlan) {
                setEmptyPlanAlertOpen(true);
              } else {
                setGestionSeguroPopoverState({
                  showPopover: false,
                  event: undefined,
                });
                setCarnetAsociadoPopoverState({
                  showPopover: true,
                  event: undefined,
                });
              }
            }}
          >
            <IonIcon icon={eyeSharp} />
            <div>Carnet de asegurado</div>
          </div>

          {/* <div className='option' onClick={() => {
            setGestionSeguroPopoverState({ showPopover: false, event: undefined });
            setLibretaVacunacionPopoverState({ showPopover: true, event: undefined });
          }}>
            <IonIcon icon={paw} />
            <div>
              Libreta de vacunación
            </div>
          </div> */}

          <div
            className="option"
            onClick={() => {
              history.push(`/tabs/personal-data/${pet?.id}`, true);
            }}
          >
            <IonIcon icon={personSharp} />
            <div>Editar mis datos</div>
          </div>

          <div
            className="option"
            onClick={() => {
              push(`/tabs/edit-pet/${pet?.id}`);
            }}
          >
            <IonIcon icon={paw} />
            <div>Editar datos de {pet?.nombre}</div>
          </div>

          {/* Edit Cobertura */}
          {/* <div
            className="option"
            onClick={() => {
              history.push('/tabs/edit-pet', true);
            }}
          >
            <IonIcon icon={shieldSharp} />
            <div>Editar cobertura o medio de pago</div>
          </div> */}

          {/* Edit medio de pago */}
          {/* <div
            className="option"
            onClick={() => {
              history.push('/tabs/edit-pet', true);
            }}
          >
            <IonIcon icon={card} />
            <div>Editar medio de pago</div>
          </div> */}

          {/* <div
            className="option"
            onClick={() => {
              push('/tabs/baja-cobertura');
            }}
          >
            <IonIcon icon={person} />
            <div>Baja del seguro</div>
          </div> */}
        </div>
      </IonPopover>

      <CarnetAsociado
        pet={pet}
        plan={filteredPlan}
        carnetAsociadoPopoverState={carnetAsociadoPopoverState}
        setCarnetAsociadoPopoverState={setCarnetAsociadoPopoverState}
      />

      <LibretaVacunacion
        libretaVacunacionPopoverState={libretaVacunacionPopoverState}
        setLibretaVacunacionPopoverState={setLibretaVacunacionPopoverState}
      />

      <ToastAlert
        isOpen={emptyPlanAlertOpen}
        setShowToast={setEmptyPlanAlertOpen}
        message={
          'No hemos podido generar el carnet, esto puede deberse a una irregularidad en la póliza. Puede dejarnos su consulta en <a href="mailto:atencion@fielpet.com.ar">atencion@fielpet.com.ar</a>'
        }
        color="warning"
        duration={8000}
      />
    </>
  );
};

export default GestionSeguro;
