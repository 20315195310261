import { useEffect, useState } from 'react';

import useSWR from 'swr';

import { PETS } from '../axios/constants';
import { getPet } from '../axios/pets';
import { Pet } from '../interfaces/Pet';
import {useMascotas} from '../context/MascotasContext';

export const usePet = (id: string) => {
  const { mascotas: {pets} } = useMascotas();
  const [pet, setPet] = useState<Pet>();

  const handleCurrentPet = () => {
    const currentPet = pets && pets.find((pet) => pet.id.toString() === id);
    setPet(currentPet);
  };

  useEffect(() => {
    handleCurrentPet();
  }, [id]);

  return pet;
};

export const useGetPet = (id: string) => {
  const petsUrl = PETS + '/' + id;
  const { data, isValidating, error, mutate} = useSWR([petsUrl, id], (url) =>
    getPet(url, id)
  );

  return { pet: data, isValidating, error, mutate };
};
