import { Veterinario } from '../Veterinario';
import { Derivacion } from '../Derivacion';

export type VetFormLinkDataAddFormType = {
  veterinario?: Veterinario;
  derivaciones?: Derivacion[];
  siniestroId?: string;
  fechaAtencion?: Date;
};

export const emptyVetFormLinkData: VetFormLinkDataAddFormType = {
  veterinario: undefined,
  derivaciones: undefined,
  siniestroId: undefined,
  fechaAtencion: undefined,
};
