import moment from 'moment';

import { SearchbarOption } from '../../components/Searchbar/types';

export type PetAddForm1Type = {
  nombre?: string;
  color?: string;
  peso?: number;
  fechaPesaje?: string;
  sexo?: string;
  fecha_nacimiento?: string;
  animal?: string;
  raza?: SearchbarOption;
};

export type PetAddForm1ToSendType = {
  nombre?: string;
  color?: string;
  peso?: number;
  fechaPesaje?: string;
  sexo?: string;
  fecha_nacimiento?: string;
  animal?: string;
  raza?: string;
};

export const emptyPetForm1: PetAddForm1Type = {
  nombre: undefined,
  color: undefined,
  peso: undefined,
  fechaPesaje: moment().toISOString(),
  sexo: undefined,
  fecha_nacimiento: undefined,
  animal: undefined,
  raza: undefined,
};

export type PetDataModificationRequest = {
  nombre: string | null;
  color: string | null;
  sexo: string | null;
  fecha_nacimiento: Date | null;
  animal: string | null;
  raza: string | null;
};

export function getEmptyPetDataModificationRequest(): PetDataModificationRequest {
  return {
    nombre: null,
    color: null,
    sexo: null,
    fecha_nacimiento: null,
    animal: null,
    raza: null,
  };
}

export type PetDataModificationRequestToSend = {
  mascotaId: number;
  mascotaNombre: string | null;
  mascotaColor: string | null;
  mascotaSexo: string | null;
  mascotaFechaNac: Date | null;
  mascotaEspecie: string | null;
  mascotaRaza: string | null;
}
