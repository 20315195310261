import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';

import { AuthContext } from '../../context/AuthContext';
import { PrivateRouteProps } from './types';

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  children,
  ...rest
}) => {
  const { authStatus } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        authStatus && Component ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
      >
      {children}
      </Route>
  );
};

export default PrivateRoute;
