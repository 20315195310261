import React from 'react';
import { useParams } from 'react-router';

import { IonSpinner, IonText } from '@ionic/react';

import { useGetMovimientos } from '../../hooks/useMovimientosSiniestro';
import { Movimiento as MovimientoInferface } from '../../interfaces/Movimiento';
import Movimiento from '../Movimiento';
import './Movimientos.scss';
import { MovimientosProps } from './types';

type ParamsType = {
  id: string;
};

const Movimientos: React.FC<MovimientosProps> = ({ nombre }) => {
  const { id: siniestroId } = useParams<ParamsType>();
  const { movimientos, isValidating, error } = useGetMovimientos(siniestroId);

  if (isValidating)
    return (
      <div className="movimientos__loading-state">
        <IonSpinner color="light" />
      </div>
    );

  if (error) return <div>error</div>;

  return (
    <div className="movimientos">
      <div className="movimientos__heading ion-padding-horizontal ion-padding-bottom">
        <h1>Movimientos</h1>
        <p>
          Aquí podes visualizar el <IonText color="primary"><b>estado del siniestro</b></IonText> de {''}
          {nombre} y todos los movimientos que se realizaron.
        </p>
      </div>
      <div className="ion-padding">
        {movimientos &&
          movimientos.map((movimiento: MovimientoInferface, index: number) => (
            <Movimiento key={index} movimiento={movimiento} />
          ))}
      </div>
    </div>
  );
};

export default Movimientos;
