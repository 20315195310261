import React, { useContext } from 'react';

import { IonPage, IonContent, IonGrid, IonRow, IonCol, useIonRouter } from '@ionic/react';

import CrearEventoFirstForm from '../../../components/CrearEventoForms/CrearEventoFirstForm';
import { CrearEventoFormContext } from '../../../context/CrearEventoContext';
import { CrearEventoFirstFormInterface } from '../../../interfaces/CrearEvento';
import './CrearEventoPage.scss';

const CrearEventoPage: React.FC = () => {
  const history = useIonRouter();
  const { setCrearEventoFormValues } = useContext(CrearEventoFormContext);
  const onSubmit = (form: CrearEventoFirstFormInterface) => {
    setCrearEventoFormValues(form);
    history.push('/crear-evento/2','forward','push');
  };
  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            {/* <IonCol sizeMd="6" offsetMd="3" sizeLg="4" offsetLg="4"> */}
            <IonCol>
              <CrearEventoFirstForm onSubmit={onSubmit} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CrearEventoPage;
