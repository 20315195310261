import React, { useState } from 'react';

import { IonHeader, IonToolbar } from '@ionic/react';

import Logo from '../../assets/FielPetSinLogo.png';
import HomeMenuModal from '../HomeMenuModal';
import './HomeNavbar.scss';
import { useMascotas } from '../../context/MascotasContext';
const HomeNavbar: React.FC = () => {
  const { mascotas: { pets, mutate } } = useMascotas();
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <div className="home-navbar">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <div slot="start" className='home-navbar__logo-container'>
            <img src={Logo} onClick={() => mutate()} />
          </div>
          <div slot="end" className="home-navbar__bordered" data-tut="reactour__home-modal" onClick={() => { if (!showModal) setShowModal(true) }}>
            <HomeMenuModal pets={pets} showModal={showModal} setShowModal={setShowModal} />
          </div>
        </IonToolbar>
      </IonHeader>
    </div>
  );
};

export default HomeNavbar;
