import { useState } from 'react';

import { axiosPostSolicitud } from '../axios/solicitud';

export const useAddSolicitud = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const addSolicitud = async (data: any) => {
    setIsLoading(true);
    const response = await axiosPostSolicitud(data);
    setIsLoading(false);
    return response;
  };
  return { addSolicitud, isLoading };
};
