import React from "react";
import { PetDetailsOptionProps } from './types';

import './PetDetailsOption.scss';
import { IonFab, IonFabButton } from "@ionic/react";


const PetDetailsOption: React.FC<PetDetailsOptionProps> = ({ action, img, title, description }) => {
  return (
    <div className="pet-details-option">
      <div className="pet-details-option__btn ion-margin" onClick={action}>
        <IonFab >
          <IonFabButton color="dark" mode='ios'>
            <img src={img} />
          </IonFabButton>
        </IonFab>
        <div>
          <div>{title}</div>
          <div className="description">{description}</div>
        </div>
      </div>
    </div>
  )
}

export default PetDetailsOption;