import React, { useState } from 'react';
import { useSiniestro } from '../../context/SiniestroContext';
import { SubVetForm } from '../../interfaces/SubVetForm';
import Indicaciones from '../Indicaciones';
import ToastAlert from '../ToastAlert';
import './ConsultasIndicaciones.scss';
import { ConsultasIndicacionesProps } from './types';

const ConsultasIndicaciones: React.FC<ConsultasIndicacionesProps> = ({
  pet,
}) => {
  const {
    indicaciones: { data: indicaciones },
  } = useSiniestro();
  const [indicacionesSeleccionadas, setIndicacionesSeleccionadas] = useState<
    SubVetForm[]
  >([]);

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [isOkAlertOpen, setOkAlertOpen] = useState<boolean>(false);
  const [isFailAlertOpen, setFailAlertOpen] = useState<boolean>(false);
  
  const { nombre } = pet || {};

  return (
    <div className="consultas-indicaciones">
      <Indicaciones
        indicacionesSeleccionadas={indicacionesSeleccionadas}
        setIndicacionesSeleccionadas={setIndicacionesSeleccionadas}
        setOkAlertOpen={setOkAlertOpen}
        setFailAlertOpen={setFailAlertOpen}
        nombre={nombre}
        setShowPopover={setShowPopover}
      />

      <ToastAlert
        isOpen={isOkAlertOpen}
        setShowToast={setOkAlertOpen}
        message="Formulario enviado correctamente!"
        color="success"
        duration={3500}
      />

      <ToastAlert
        isOpen={isFailAlertOpen}
        setShowToast={setFailAlertOpen}
        message="El formulario no se pudo enviar. Por favor intentelo nuevamente más tarde."
        color="danger"
        duration={3500}
      />
    </div>
  );
};

export default ConsultasIndicaciones;
