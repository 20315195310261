import { useState } from 'react';
import useSWR, { mutate } from 'swr';

import { postEvent, putEvent, delEvent } from '../axios/events';
import { CrearEventoFormInterface } from '../interfaces/CrearEvento';
import { EVENTO, GET_EVENTS } from '../axios/constants';

export const useEvento = (): {
  errors: any;
  loading: boolean;
  createEvent: (form: CrearEventoFormInterface) => Promise<void>;
  deleteEvent: (id: string) => Promise<void>;
  updateEvent: (id: string, data: CrearEventoFormInterface) => Promise<void>;
} => {
  const [errors, setError] = useState();

  const { mutate } = useSWR(
    GET_EVENTS,
    axiosGetEvents
  );

  const [loading, setLoading] = useState(false);

  const createEvent = async (form: CrearEventoFormInterface) => {
    setLoading(true);
    try {
      await postEvent(form);
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
      throw e;
    }
  };

  const deleteEvent = async (id: string) => {
    setLoading(true);
    try {
      await delEvent(id);
      mutate();
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
      throw e;
    }
  };

  const updateEvent = async (id: string, data: CrearEventoFormInterface) => {
    setLoading(true);
    try {
      await putEvent(id, data);
      mutate();
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
      throw e;
    }
  };

  return { errors, loading, createEvent, deleteEvent, updateEvent };
};
function axiosGetEvents(GET_EVENTS: string, axiosGetEvents: any): { mutate: any; } {
  throw new Error('Function not implemented.');
}

