import React from 'react';
import { RouteComponentProps } from 'react-router';

import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';

import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import NavigationBar from '../../components/NavigationBar';
import Siniestros from '../../components/Siniestros';
import { useGetPet } from '../../hooks';
import './ShowSiniestrosPage.scss';

type ParamsType = RouteComponentProps<{
  id: string
}>;

const ShowSiniestrosPage: React.FC<ParamsType> = ({match}) => {
  const petId = match.params.id;
  const { pet } = useGetPet(petId);
  const { animal, img_link, nombre, raza, sexo } = pet || {};

  const placeholder =
    animal === 'Perro'
      ? DogPlaceholder
      : animal === 'Gato'
      ? CatPlaceholder
      : undefined;

  const image = img_link ? (
    <img src={img_link} alt={nombre} />
  ) : (
    <img src={placeholder} alt={nombre} />
  );

  return (
    <IonPage className="show-siniestros-page">
      <NavigationBar goBack  />
      <IonContent fullscreen>
        <IonGrid className="ion-no-padding ion-margin">
          <IonRow>
            <IonCol sizeXs='3.66' sizeSm='3' sizeMd='2' className="show-siniestros-page__pic">
              {image}
            </IonCol>
            <IonCol className="show-siniestros-page__info">
              <div>
                <div className="show-siniestros-page__nombre">{nombre}</div>
                <span>{raza}</span>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <div className="show-siniestros-page__title ion-padding-top ion-padding-start">
          Enfermedades o accidentes de {nombre}
        </div>
        <Siniestros petId={petId} sexo={sexo}/>
      </IonContent>
    </IonPage>
  );
};

export default ShowSiniestrosPage;
