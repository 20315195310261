import React from 'react';
import { IonButton, useIonRouter } from '@ionic/react';

import { NotFoundProps } from './types';
import Pic_404 from '../../assets/404.png';

import './NotFound.scss';

const NotFound: React.FC<NotFoundProps> = () => {
  const history = useIonRouter();
  const goHome = () => {
    history.push('/tabs/home','none','replace');
  };
  return (
    <>
      <div className="not-found ion-padding">
        <p className="not-found__ups">¡Ups!</p>
        <p>
          Lo sentimos, no encontramos la página a la que intentó acceder
        </p>
        <IonButton onClick={goHome} fill="outline" mode='ios'>
          Regresar al inicio
        </IonButton>
      </div>
      <div className="not-found__footer">
        <img className="not-found__footer__pic" src={Pic_404}></img>
      </div>
    </>
  );
};

export default NotFound;
