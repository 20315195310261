import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonItem,
  IonLabel,
  IonButton,
  IonDatetime,
  IonTextarea,
} from '@ionic/react';
import Health from '../../assets/hospital.png';

import { useAddSiniestros } from '../../hooks/useAddSiniestros';
import { useGetSiniestros } from '../../hooks/useSiniestros';
import { useEnter } from '../../hooks/useEnter';
import {
  SiniestroAddFormType,
  SiniestroAddFormToSendType,
  getEmptySiniestroForm,
} from '../../interfaces/forms/SiniestroForm';
import { today } from '../../utils/DateUtilities';
import FormItem from '../FormItem';
import './FormAddSiniestro.scss';
import { FormAddSiniestroProps } from './types';
import { useMascotas } from '../../context/MascotasContext';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../utils/General';

const FormAddSiniestro: React.FC<FormAddSiniestroProps> = ({
  id,
  showOkToast,
  setShowOkToast,
  setShowFailToast,
  setNewSiniestroId,
  setErrorMessage,
}) => {
  const { mascotas: { pets } } = useMascotas();
  const pet = pets?.find((m) => m.id.toString() === id);
  const { mutate } = useGetSiniestros(pet?.id.toString() ?? ''); //@TODO parche, hacer bien
  const { handleEnter } = useEnter();
  const { addSiniestro } = useAddSiniestros();

  const { control, handleSubmit, errors, reset } = useForm<
    SiniestroAddFormType
  >({
    defaultValues: getEmptySiniestroForm(),
  });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!showOkToast) {
      setLoading(false);
      reset();
    }
  }, [showOkToast]);

  const onSubmit = async (values: SiniestroAddFormType) => {
    if (loading) return;
    setLoading(true);

    //newDate porque el servidor de BBVA esta atradaso 6 minutos aprox.
    const originalDate = moment(values.fechaEnfermedad);
    const newDate = originalDate.subtract(1, 'hours');
    const formattedDate = newDate.format();
    //

    const addSiniestroForm_toSend: SiniestroAddFormToSendType = {
      fechaEnfermedad: formattedDate,
      denunciaAdministrativa: values.denunciaAdministrativa,
      mascotaId: id,
    };
    const ok = await addSiniestro(addSiniestroForm_toSend);
    if (ok && ok.data && ok.data.id) {
      setNewSiniestroId(ok.data.id);
      setShowOkToast(true);
      mutate();
    } else {
      if(ok && ok.data && ok.data.message && setErrorMessage) {
        setErrorMessage(ok.data.message);
      }
      setShowFailToast(true);
    }
  };

  return (
    <div className="form-add-siniestro ion-padding-horizontal">
      <div className="form-add-siniestro__heading ion-padding">
        <div className="title ion-margin-vertical">
          <h3>
            Nuevo accidente o enfermedad
          </h3>
          <img src={Health} />
        </div>
        <span>Completa los datos del siniestro de {pet?.nombre}</span>
      </div>
      <div className="form-add-siniestro__form ion-padding-horizontal ion-margin-top">
        <form onSubmit={handleSubmit(onSubmit)} onKeyUp={handleEnter}>
          <FormItem
            render={({ onChange, name, value }) => (
              <IonItem mode="ios" className='ion-no-padding'>
                <IonLabel position="floating">
                  Fecha de inicio de síntomas / accidente
                </IonLabel>
                <IonDatetime
                  name={name}
                  value={value}
                  displayFormat="DD/MM/YYYY"
                  cancelText="Cancelar"
                  doneText="Ok"
                  onIonChange={onChange}
                  max={today}
                />
              </IonItem>
            )}
            control={control}
            name="fechaEnfermedad"
            errors={errors}
            rules={{
              required: {
                value: true,
                message: 'Por favor, ingrese la fecha de enfermedad',
              },
            }}
          />

          <div className="ion-margin-top">
            <FormItem
              errors={errors}
              render={({ onChange, value }) => (
                <IonItem mode="ios" className='ion-no-padding'>
                  <IonLabel position="floating">
                    Breve descripción de lo sucedido
                  </IonLabel>
                  <IonTextarea
                    value={capitalizeFirstLetter(value)}
                    rows={6}
                    placeholder={`Ejemplo: el Lunes 06/03/2023 ${pet?.nombre} empezó a vomitar cada vez que comía y hasta el día de la fecha sigue con el mismo problema.`}
                    onIonChange={onChange}
                  ></IonTextarea>
                </IonItem>
              )}
              control={control}
              name="denunciaAdministrativa"
              rules={{
                required: {
                  value: true,
                  message:
                    'Por favor, ingrese una breve descripción de lo sucedido',
                },
                pattern: {
                  value: /^[a-zA-Z0-9_]+[a-zA-Z0-9_. ]*/,
                  message: 'Regex',
                },
              }}
            />
          </div>

          <div className="ion-padding-top">
            <IonButton
              type="submit"
              disabled={loading}
              expand="block"
              size="large"
            >
             {loading ? 'Procesando' : 'Continuar'}
           </IonButton>
      {loading && <p style={{color:'#555'}}>Por favor aguarde, el proceso puede tomar hasta un minuto.</p>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAddSiniestro;
