import React, { useState, useRef } from 'react';
import { IonSlides, IonSlide, IonButton, IonIcon, useIonRouter } from '@ionic/react';
import { arrowForward, arrowBack, closeSharp } from 'ionicons/icons';
import FielPetLogo from '../../assets/FielPetLogo.png';
import { useMobile } from '../../hooks/useMobile';
import styles from './InitialSlides.module.scss';
import { InitialSlidesProps } from './types';

const InitialSlides: React.FC<InitialSlidesProps> = () => {
  const history = useIonRouter();
  const [showNext, setNext] = useState(false);
  const [showPrev, setPrev] = useState(true);
  const isMobileWeb = useMobile();
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    zoom: false,
    touchReleaseOnEdges: true,
  };

  const mySlides = useRef(document.createElement('ion-slides'));

  const slideChange = (ev: any) => {
    const target: any = ev.target;
    setNext(target.swiper.isEnd);
    setPrev(target.swiper.isBeginning);
  };

  const prev = () => {
    mySlides.current.slidePrev();
  };

  const next = () => {
    mySlides.current.slideNext();
  };

  return (
    <div>
      <IonSlides
        pager={true}
        ref={mySlides}
        options={slideOpts}
        onIonSlideDidChange={slideChange}
      >
        <IonSlide>
          <div className={`${styles.bg_image} ${styles.img1}`}>
            <div className={`${styles.text_wrapper}`}>
              <img src={FielPetLogo} />
              <p>
                El <b>cuidado diario</b> es vital para nuestras mascotas. Pero
                hay cosas que escapan de nuestro control, y ocasionalmente
                pueden sufrir por <b>enfermedades</b> o <b>accidentes </b>
                que pongan en riesgo su salud.{' '}
                <b>FielPet</b> protege a tu
                mascota, cubriendo los gastos que necesite incluyendo:
              </p>
              <ul>
                <li>Consultas veterinarias</li>
                <li className={styles.text}>Medicamentos</li>
                <li className={styles.text}>Intervenciones quirúrgicas</li>
                <li className={styles.text}>Estudios</li>
                <li className={styles.text}>Cirugías</li>
                <li className={styles.text}>Internaciones</li>
                <li className={styles.text}>Y más!</li>
              </ul>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div className={`${styles.bg_image} ${styles.img2} `}>
            <div className={`${styles.text_wrapper}`}>
              <img src={FielPetLogo} />
              <p>
                ¡Bienvenido a <span className={styles.green}>FielPet</span>!
              </p>
              <p>
                <b>FielPet</b> te da la herramienta perfecta para hacer un seguimiento
                de los eventos importantes en la vida de nuestros mejores
                amigos.
              </p>
              <p>
                Nunca más olvides qué vacunas tiene, cuáles tiene pendientes y
                cuándo debés aplicar un refuerzo.
              </p>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div className={`${styles.bg_image} ${styles.img3}`}>
            <div className={`${styles.text_wrapper}`}>
              <img src={FielPetLogo} />
              <p>
                Gestioná el <b>reintegro</b> de los gastos en los que hayas tenido que
                incurrir desde que aparece el primer síntoma hasta que tu
                mascota recibe el alta.
              </p>
              <p>
                Subí la <b>documentación</b>, hace el <b>seguimiento</b> y comunícate con tu
                veterinario todo a través de nuestra app.
              </p>

              <div className='ion-padding-top'>
                <IonButton
                  mode='ios'
                  expand='block'
                  onClick={() => {
                    history.push('/login');
                  }}
                >
                  Comenzar
                </IonButton>
              </div>
            </div>
          </div>
        </IonSlide>
      </IonSlides>
      <div>
        {/* {!isMobileWeb && (
          <IonButton
            className={`${styles.prev}`}
            hidden={showPrev}
            onClick={prev}
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
        )}
        {!isMobileWeb && (
          <IonButton
            className={`${styles.next}`}
            hidden={showNext}
            onClick={next}
          >
            <IonIcon icon={arrowForward}></IonIcon>
          </IonButton>
        )} */}

        <IonButton
          onClick={() => {
            history.push('/login');
          }}
          className={`${styles.omit}`}
          fill="clear"
        >
          <IonIcon icon={closeSharp} />
        </IonButton>
      </div>
    </div>
  );
};

export default InitialSlides;
