import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IonItem, IonInput, IonButton, IonSpinner, useIonRouter } from '@ionic/react';

import { useAuth } from '../../hooks';
import { FormLoginProps, FormInputs } from './types';
import FormItem from '../FormItem';
import { useEnter } from '../../hooks/useEnter';

import './FormLogin.scss';

const FormLogin: React.FC<FormLoginProps> = () => {
  const history = useIonRouter();
  const { ref, handleEnter } = useEnter();
  const { control, handleSubmit, errors } = useForm<FormInputs>();

  const { login } = useAuth();

  // Estados mínimos para mostrar loading y error de login
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);

  const onSubmit = async ({ email, password }: FormInputs) => {
    setLoading(true);
    const ok = await login(email, password);
    setLoading(false);
    if (ok) {
      setFailed(false);
      history.push('/tabs/home');
    } else {
      setFailed(true);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form-login"
      onKeyUp={handleEnter}
    >
      <FormItem
        errors={errors}
        render={({ onChange }) => (
          <IonItem className="form-login__item">
            <IonInput type="email" placeholder="Email" onIonChange={onChange} />{' '}
          </IonItem>
        )}
        control={control}
        name="email"
        rules={{
          required: {
            value: true,
            message: 'Por favor, ingrese su email',
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Por favor, ingrese un email valido',
          },
        }}
      />

      <FormItem
        errors={errors}
        render={({ onChange }) => (
          <IonItem className="form-login__item">
            <IonInput
              type="password"
              clearOnEdit={false}
              placeholder="Contraseña"
              onIonChange={onChange}
            />
          </IonItem>
        )}
        control={control}
        name="password"
        rules={{
          required: {
            value: true,
            message: 'Por favor, ingrese su contraseña',
          },
        }}
      />

      {failed && <p className="error-msg">Email o contraseña incorrecto/a</p>}

      <div className='ion-margin-top'> <IonButton ref={ref} className="form-login__button" type="submit" mode='ios'>
        {loading && (
          <IonSpinner
            name="crescent"
            className="form-login__loading-spinner"
            slot="end"
          />
        )}
        Iniciar sesión
      </IonButton></div>

    </form>
  );
};

export default FormLogin;
