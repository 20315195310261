import React from 'react';

import { IonRow, IonCol, IonGrid, useIonRouter } from '@ionic/react';
import moment from 'moment';

import { getAnimalImage, getClassColor } from '../../utils/timelineUtils';
import './ButtonEvents.scss';
import { EventsProps } from './types';

const ButtonEvents: React.FC<EventsProps> = ({
  done,
  tipo,
  label,
  event_icon,
  pet_img,
  dia,
  numero,
  animal,
  petId,
  eventoId,
}) => {
  const history = useIonRouter();

  const onEventClick = (id: number) => {
    history.push(`/tabs/historial/edit-event/${id.toString()}`);
  };

  function isSubvetform(tipo: string) {
    //indexOf devuelve -1 si la variable no se encuentra en el array dado
    if (
      ['Consulta', 'Internación', 'Cirugía', 'Estudio', 'Práctica'].indexOf(
        tipo
      ) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  
  return (
    <div className="eventButton">
      <IonGrid
        className={`ion-no-padding ion-padding-horizontal ${getClassColor(petId)}`}
        onClick={() => onEventClick(eventoId)}
      >
        <IonRow>
          <IonCol size="4" className="eventButton__title">
            {label}
          </IonCol>
          <IonCol size="2">
            <img src={event_icon} />
          </IonCol>
          <IonCol size="6">
            <div className="eventButton__date">
              {isSubvetform(tipo) && done === false
                ? 'Fecha a'
                : moment(dia)
                  .locale('es')
                  .format('ddd')
                  .substring(0, 1)
                  .toUpperCase() +
                moment(dia).locale('es').format('ddd').substring(1)}{' '}
              {isSubvetform(tipo) && done == false
                ? 'Pactar'
                : numero.substring(8, 10)}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ButtonEvents;
