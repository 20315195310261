import React from 'react';
import { IonContent, IonAlert, useIonRouter } from '@ionic/react';
import { PersonalDataAlertProps } from './types';

const PersonalDataAlert: React.FC<PersonalDataAlertProps> = ({
  isOpen,
  setOpen,
}) => {
  const history = useIonRouter();
  return (
    <IonContent>
      <IonAlert
        mode='ios'
        isOpen={isOpen}
        header="Datos personales"
        message="Para asegurar una mascota se necesitan sus datos personales. Los mismos serán cargados por única vez."
        buttons={[
          { text: 'Cancelar', role: 'cancel' },
          { text: 'Continuar', role: 'confirm' },
        ]}
        onDidDismiss={(e) => {
          const { role } = e.detail;
          if (role === 'confirm') {
            setOpen(false);
            history.push('/datos-personales','forward','push');
          }
        }}
      />
    </IonContent>
  );
};

export default PersonalDataAlert;
