import axios from 'axios';
import { SOLICITUD } from './constants';
import axiosInstance from '.';

export const axiosPostSolicitud = async (data: any): Promise<boolean> => {
  try {
    console.log('dataSolicitud: ', data);
    await axiosInstance.post(SOLICITUD, data);
    return true;
  } catch (error) {
    console.error(error.response);
    return false;
  }
};
