import { useIonRouter } from '@ionic/react';
import React, { useEffect } from 'react';

const CustomIonBackButtonWrapper : React.FC<{onBackButtonRef: () => void, condition?: () => boolean  , checkCurrentPath?: RegExp, component: React.ReactNode}> = ({onBackButtonRef,checkCurrentPath ,condition,component}) => {
  const history = useIonRouter();
  const handler = (e)=>{
    if (condition && !condition()) {
      console.log('___ not registering because condition');
      return;
    }
    else if (checkCurrentPath && !checkCurrentPath.test(history.routeInfo.pathname)  ){
      console.log('___ not registering because path');
      return;
    }
    else {
      console.log('___ registering');
      e.detail.register(10,onBackButtonRef) // 1 por encima del componente handlebackbutton
    }
  };
  
  useEffect (()=>{
    const registerAndSaveHandle = async () => {
      document.addEventListener('ionBackButton', handler);
    }
    registerAndSaveHandle()
    return () => {
        console.log('___ unregistering');
      document.removeEventListener('ionBackButton',handler);
    };
  },[onBackButtonRef]); //@TODO quiza amerite probar funcionamiento con distintas dependencias y con los hooks de lifecycle de ionic

  return <>{component}</>
}

export default CustomIonBackButtonWrapper;
